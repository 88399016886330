import React, { FC } from 'react';
import { Menu } from 'antd';

export interface ParticipantContextMenuProps {
  isSelf: boolean;
  isAdmin: boolean;
  isMuted: boolean;
  isPinned: boolean;
  isFavorite: boolean;
  hasChatToken: () => boolean;
  onMuteClick: () => void;
  onChatClick: () => void;
  onSpotlightClick: () => void;
  onAddFavorite: () => void;
  onRemoveFavorite: () => void;
  onRemoveClick: () => void;
}

/**
 * ParticipantContextMenu Component
 *
 * @param isSelf - isSelf
 * @param isMuted - isMuted
 * @param isPinned - isPinned
 * @param isFavorite - isFavorite
 * @param onMuteClick - handler
 * @param onChatClick - handler
 * @param onSpotlightClick - handler
 * @param onAddFavorite - handler
 * @param onRemoveFavorite - handler
 * @param onRemoveClick - handler
 * @param hasChatToken - handler
 * @constructor
 */
export const ParticipantContextMenu: FC<ParticipantContextMenuProps> = ({
  isSelf,
  isMuted,
  isPinned,
  isFavorite,
  isAdmin,
  onMuteClick,
  onChatClick,
  onSpotlightClick,
  onAddFavorite,
  onRemoveFavorite,
  onRemoveClick,
  hasChatToken,
}) => (
  <Menu className="vl-header-menu">
    {isAdmin && (
      <Menu.Item key="0" onClick={onMuteClick}>
        {isMuted ? 'Unmute Audio' : 'Mute Audio'}
      </Menu.Item>
    )}
    {!isSelf && hasChatToken() && (
      <Menu.Item key="1" onClick={onChatClick}>
        Chat
      </Menu.Item>
    )}
    <Menu.Item key="2" onClick={onSpotlightClick}>
      {isPinned ? 'Remove Spotlight' : 'Set Spotlight'}
    </Menu.Item>
    {!isFavorite && !isSelf && (
    <Menu.Item key="3" onClick={onAddFavorite}>
      Add to Favorites
    </Menu.Item>
    )}
    {isFavorite && !isSelf && (
    <Menu.Item key="4" onClick={onRemoveFavorite}>
      Remove from Favorites
    </Menu.Item>
    )}
    {isAdmin && (
      <Menu.Item key="6" onClick={onRemoveClick}>
        Remove Participant
      </Menu.Item>
    )}
  </Menu>
);
