import React, { useEffect, FC } from 'react';
import { Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { isElementInViewport } from '@utils/helpers';
import { useMovable } from '@hooks';
import ExpandIcon from '@assets/images/panels/expand.svg';
import CollapseIcon from '@assets/images/panels/collapse.svg';
import ReloadIcon from '@assets/images/panels/screenshare_retake.svg';
import XcloseIcon from '@assets/images/panels/X_close.svg';
import '@assets/styles/movablepanel.scss';

export interface MovablePanelProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  hidden?: boolean;
  title: string;
  id?: string;
  width: number;
  height: number;
  top?: number;
  left?: number;
  downloadable?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  reloadable?: boolean;
  hasDivider?: boolean;
  isClosable?: boolean;
  isMobile?: boolean;
  onReload?: () => void;
  onClose: () => void;
  downloadImage?: () => void;
}

/**
 * MovablePanel Component
 *
 * @param props - MovablePanelProps
 * @constructor
 */
export const MovablePanel: FC<MovablePanelProps> = (props) => {
  const {
    top, left, expanded, hidden, expandable, id,
    isMobile, width, height, title, downloadable, downloadImage, reloadable,
    onReload, isClosable, hasDivider, onClose, children,
  } = props;
  const {
    oldY, oldX, draggable, container, state, setState,
  } = useMovable({
    top: top || 0,
    left: left || 0,
    fullscreen: false,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      fullscreen: !!expanded,
    }));
  }, [expanded]);

  /**
   * Executes on mouse move, calculates the position of the element
   *
   * @param event mouse event
   */
  const onMouseMove = (event: React.MouseEvent): void => {
    if (!state.fullscreen) {
      event.preventDefault();

      if (draggable.current) {
        const deltaX = oldX.current - event.pageX;
        const deltaY = oldY.current - event.pageY;

        oldX.current = event.pageX;
        oldY.current = event.pageY;
        const newTop = state.top - deltaY;
        const newLeft = state.left - deltaX;

        if (
          !isElementInViewport(
            container.current as Element,
            -deltaX,
            -deltaY,
            true,
          )
        ) {
          draggable.current = false;
          return;
        }

        setState({
          ...state,
          top: newTop,
          left: newLeft,
        });
      }
    }
  };

  /**
   * Executes on mouse down
   *
   * @param event - mouse event
   */
  const onMouseDown = (event: React.MouseEvent): void => {
    if (!state.fullscreen) {
      event.preventDefault();
      oldX.current = event.pageX;
      oldY.current = event.pageY;
      draggable.current = true;
    }
  };

  /**
   * Executes on mouse up event
   *
   * @param event - mouse event
   */
  const onMouseUp = (event: React.MouseEvent): void => {
    if (!state.fullscreen) {
      event.preventDefault();
      draggable.current = false;
    }
  };

  /**
   * Executes on double click
   *
   * @param event - mouse event
   */
  const onDoubleClick = (event: React.MouseEvent): void => {
    if (!expandable) {
      return;
    }

    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      fullscreen: !prevState.fullscreen,
    }));
    event.stopPropagation();
  };

  /**
   * Gets the style for the container
   */
  const getContainerStyle = (): object => {
    if (isMobile) {
      return {};
    }

    return {
      width: state.fullscreen
        ? '100%'
        : width,
      height: state.fullscreen
        ? '100%'
        : height,
      top: state.fullscreen
        ? 0
        : state.top,
      left: state.fullscreen
        ? 0
        : state.left,
      zIndex: 1000,
    };
  };

  if (hidden) {
    return null;
  }

  return (
    <div
      id={id}
      className="vl-movableMod"
      ref={container}
      style={{ ...getContainerStyle() }}
    >
      <div
        className="vl-movablePanelHeader"
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onDoubleClick={onDoubleClick}
      >
        <div className="vl-modalTitle">
          {title}
        </div>
        {downloadable && (
          <div className="vl-modalDownload ">
            <DownloadOutlined
              onClick={downloadImage}
            />
          </div>
        )}
        {reloadable && (
          <div className="vl-modalReload">
            <span onClick={onReload}>
              <img src={ReloadIcon} alt="reloadIcon" />
            </span>
          </div>
        )}
        {expandable && (
          <div className="vl-modalExpend">
            {!state.fullscreen && (
              <span onClick={onDoubleClick}>
                <img src={ExpandIcon} alt="expandIcon" />
              </span>
            )}
            {state.fullscreen && (
              <span onClick={onDoubleClick}>
                <img src={CollapseIcon} alt="collapseIcon" />
              </span>
            )}
          </div>
        )}
        {isClosable && (
          <div className="vl-modalClose">
            <span
              id={id ? `${id}_close` : undefined}
              onClick={onClose}
            >
              <img src={XcloseIcon} alt="xCloseIcon" />
            </span>
          </div>
        )}
      </div>
      {hasDivider && (
        <div className="vl-modalDivider">
          <Divider />
        </div>
      )}
      {children}
    </div>
  );
};

MovablePanel.defaultProps = {
  hidden: false,
  id: '',
  top: 0,
  left: 0,
  expandable: false,
  expanded: false,
  downloadable: false,
  reloadable: false,
  hasDivider: false,
  isClosable: true,
  isMobile: false,
  onReload: () => {},
  downloadImage: () => {},
};
