import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  Badge, Checkbox, Col, Collapse, Form, Input, List, Pagination, Row, Spin, Tooltip,
} from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { useRecoilValue } from 'recoil';
import {
  AssociatedProfile, CliniciansData, FavoriteData, FavoriteModel, PresenceType, ProfileData,
} from '@types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  f18, filledStar, spin, starOutlined,
} from '@assets/styles/antd-custom-styles';
import { debounce } from '@utils/helpers';
import { FavoriteStar } from './FavoriteStar';
import { CallButton } from './CallButton';

const { Panel } = Collapse;

const SEARCH_LIMIT = 1;

/**
 * Clinician - component for inviting a Clinician
 *
 */
export const Clinician: FC = () => {
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const favoritesManager = adapter?.getFavoritesManager();

  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<FavoriteData | undefined>(undefined);
  const [clinicians, setClinicians] = useState<CliniciansData | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>('');

  const DEFAULT_PAGE_SIZE = 20;

  useEffect(() => {
    favoritesManager?.fetchFavoriteClinicians();

    const favoriteCliniciansListener = () => {
      setFavorites(adapter?.favorites.clinicians);
    };
    const searchClinitiansListener = () => {
      setClinicians(adapter?.clinicians);
    };
    adapter?.on(EventNames.onFavoriteCliniciansChange, favoriteCliniciansListener);
    adapter?.on(EventNames.onCliniciansChange, searchClinitiansListener);

    return () => {
      adapter?.off(EventNames.onFavoriteCliniciansChange, favoriteCliniciansListener);
      adapter?.off(EventNames.onCliniciansChange, searchClinitiansListener);
      favoritesManager?.cleanup();
    };
  }, [adapter, favoritesManager]);

  /**
   * Triggers a search for clinicians with a given search term
   * @param {string} term - the term to search for
   * @param {number} [page=0] - the page number to retrieve
   * @param {number} [size=DEFAULT_PAGE_SIZE] - the number of results to retrieve per page
   */
  const onPagedProfilesSearch = (term: string, page = 0, size = DEFAULT_PAGE_SIZE) => {
    adapter?.getSearchManager().fetchClinicians(term, size, page);
  };

  /**
   * Returns a debounced version of the `onPagedProfilesSearch` function that delays calling the original function
   * by the specified time (in milliseconds). The debounced function will only be called after the delay time
   * has passed and no further calls to the function have been made during that time.
   * @param searchTerm - the search term to use when fetching devices
  */
  const delaySaveToDb: (searchTerm: string) => void = useMemo(() => debounce(onPagedProfilesSearch, 500, false), []);

  /**
   * Handles changes in the search input field
   * @param e - event
   */
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (showFavorites) {
      setShowFavorites(false);
    }

    const { value } = e.currentTarget;
    setInputValue(value);

    if (value.length >= SEARCH_LIMIT) {
      delaySaveToDb(value);
    }
  };

  /**
   * Handles keyboard events
   * If the key pressed is `Enter`, `onPagedProfilesSearch` is called
   * @param e - event
   */
  const keyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (!showFavorites) {
        onPagedProfilesSearch(inputValue);
      }
    }
  };

  const cliniciansPagination = adapter?.clinicians.pagination;

  /**
   * Handles changes in the current page number
   * @param pageNo - the new page number
   */
  const handlePageChange = (pageNo: number) => {
    if (!showFavorites && cliniciansPagination?.totalPages && pageNo <= cliniciansPagination?.totalPages) {
      onPagedProfilesSearch(inputValue, pageNo - 1, cliniciansPagination?.size);
    }
  };

  const currentPage = cliniciansPagination?.number !== undefined ? cliniciansPagination.number + 1 : 1;
  const isMoreUsersAvailable = cliniciansPagination?.number
    && cliniciansPagination.number + 1 < cliniciansPagination.totalPages;

  /**
   * Sorts the array of favorites in ascending non case-sensitive order
   * @param array array of favorites data objects
   * @param prop object key used for sorting
   * @returns {Array} sorted favorites data
   */
  const sortBy = <T extends FavoriteModel | ProfileData>(array:T[] | undefined, prop: keyof T)
    : T[] | undefined => array?.sort((a, b) => (a[prop] as string).localeCompare(b[prop] as string));

  const sortedFavorites = favorites && sortBy(Object.values(favorites?.list), 'name');
  const sortedClinicians = clinicians && sortBy(Object.values(clinicians.list), 'firstName');

  /**
   * Updates the screen value when the checkbox is triggered
   * @param e event triggered by the checkbox
   */
  const toggleFavorites = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setShowFavorites(true);
    } else {
      setShowFavorites(false);
    }
    if (!showFavorites) {
      favoritesManager?.fetchFavoriteClinicians();
      setInputValue('');
      setClinicians(undefined);
    }
  };

  /**
   * Updates the favorite status of a clinician
   */
  const updateFavoriteHandler = () => {
    onPagedProfilesSearch(inputValue);
  };

  /**
   * Gets the clinician's initials for the picture
   * @param item AssociatedProfile
   */
  const getInitials = (item: AssociatedProfile | ProfileData | undefined) => (item?.firstName && item.lastName ? item.firstName[0] + item.lastName[0] : '');

  /**
   * Gets the correct classname based on the presence type
   * @param presence PresenceType
   */
  const getPresenceClassName = (presence: PresenceType | undefined) => {
    switch (presence) {
      case 'AVAILABLE':
        return 'profile-available';
      case 'ON_CALL':
        return 'profile-oncall';
      case 'DO_NOT_DISTURB':
        return 'profile-dnd';
      case 'NOT_AVAILABLE':
        return 'profile-offline';
      default:
        return '';
    }
  };

  /**
   * Gets the correct presence label based on the presence type
   * @param presence
   */
  const getPresenceLabel = (presence: PresenceType | undefined) => {
    switch (presence) {
      case 'AVAILABLE':
        return 'Online';
      case 'ON_CALL':
        return 'On call';
      case 'DO_NOT_DISTURB':
        return 'Do not disturb';
      case 'NOT_AVAILABLE':
        return 'Offline';
      default:
        return '';
    }
  };

  return (
    <>
      <Form>
        <>
          <Row className="vl-h-60">
            <Col span={24}>
              <div className="vl-select-field-lgr">
                <div>
                  <Input
                    className="vl-search-field"
                    prefix={<SearchOutlined />}
                    size="large"
                    placeholder="Search"
                    value={showFavorites ? '' : inputValue}
                    onChange={handleChange}
                    onKeyDown={keyDown}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className="vl-cb-right">
                <Tooltip title={showFavorites ? 'Hide favorites' : 'Show favorites'} placement="bottom">
                  <Checkbox className="vl-cb" checked={showFavorites} onChange={(e) => toggleFavorites(e)}>
                    <span className={showFavorites ? 'cb-label-active vl-f-16' : 'vl-cb-label vl-f-16'}>My Favorites</span>
                  </Checkbox>
                </Tooltip>
              </div>
            </Col>
          </Row>
          <hr className="vl-hr-invite" />
        </>
      </Form>

      <div className="vl-invite-scroll" style={{ height: '480px' }}>
        {((showFavorites && adapter?.favorites.clinicians.loading) || (!showFavorites && adapter?.clinicians.loading))
          && (
          <div>
            <br />
            <Spin indicator={<LoadingOutlined style={spin} spin />} />
          </div>
          )}

        {(showFavorites && !adapter?.favorites.clinicians.loading && favorites?.list)
          && (
          <List
            className="vl-profiles-list"
            dataSource={sortedFavorites}
            renderItem={(item: FavoriteModel) => (item
            && (
            <List.Item className="vl-list-item">

              <div className="vl-profiles-list-item">
                <Badge
                  status={item?.associatedProfile?.presence === 'DO_NOT_DISTURB' ? 'processing' : 'default'}
                  className={`${getPresenceClassName(item?.associatedProfile?.presence)} search-profile`}
                />

                <div className="vl-avatar">
                  {item?.associatedProfile?.pictureUrl != null ? (
                    <img
                      alt="avatar"
                      className="vl-circle-inner1"
                      src={`data:image/png;base64,${item.associatedProfile.pictureUrl}`}
                    />
                  ) : (
                    <div className="vl-circle-inner1">
                      {getInitials(item?.associatedProfile)}
                    </div>
                  )}
                </div>

                <Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    className="vl-profile-collapse-panel"
                    header={(
                      <Tooltip title={getPresenceLabel(item?.associatedProfile?.presence)} placement="bottom">
                        <span style={f18}>
                          {showFavorites && (item?.name ?? `${item?.associatedProfile?.firstName} ${item?.associatedProfile?.lastName}`)}
                        </span>
                      </Tooltip>
                    )}
                    key="1"
                  >
                    <div className="vl-collapse vl-f-14">
                      {item?.associatedProfile?.publicMessage && (
                      <>
                        <div className="vl-property">Message:</div>
                        <div className="vl-value-text">{item?.associatedProfile?.publicMessage}</div>
                      </>
                      )}
                      <div className="vl-property">Phone:</div>
                      <div className="vl-value">{item?.associatedProfile?.phoneNumber}</div>
                      <div className="vl-property">Email:</div>
                      <div className="vl-value">{item?.associatedProfile?.email}</div>
                      <div className="vl-property">Organization:</div>
                      <div className="vl-value">{item?.associatedProfile?.organization}</div>
                      <div className="vl-property">Facility:</div>
                      <div className="vl-value">{item?.associatedProfile?.facility}</div>
                    </div>
                  </Panel>
                </Collapse>
                {!adapter?.favorites.clinicians.loading && (
                  <>
                    <div className="favorite-star-wrapper">
                      <FavoriteStar
                        isFavorite
                        favorite={item}
                        filledStyle={filledStar}
                        outlinedStyle={starOutlined}
                      />
                    </div>
                    <div className="call-button-wrapper">
                      <CallButton
                        clinician={item.associatedProfile}
                      />
                    </div>
                  </>
                )}
              </div>
            </List.Item>
            )
            )}
          />
          )}

        {(!showFavorites && !adapter?.clinicians.loading && clinicians?.list && clinicians.list.length > 0)
          && (
          <List
            className="vl-profiles-list"
            dataSource={sortedClinicians}
            renderItem={(item: ProfileData) => (item
            && (
            <List.Item className="vl-list-item">

              <div className="vl-profiles-list-item">
                <Badge
                  status={item?.presence === 'DO_NOT_DISTURB' ? 'processing' : 'default'}
                  className={`${getPresenceClassName(item?.presence)} search-profile`}
                />

                <div className="vl-avatar">
                  {item?.pictureUrl != null ? (
                    <img
                      alt="avatar"
                      className="vl-circle-inner1"
                      src={`data:image/png;base64,${item.pictureUrl}`}
                    />
                  ) : (
                    <div className="vl-circle-inner1">
                      {getInitials(item)}
                    </div>
                  )}
                </div>

                <Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    className="vl-profile-collapse-panel"
                    header={(
                      <Tooltip title={getPresenceLabel(item?.presence)} placement="bottom">
                        <span style={f18}>
                          {(`${item?.firstName} ${item?.lastName}`)}
                        </span>
                      </Tooltip>
                    )}
                    key="1"
                  >
                    <div className="vl-collapse vl-f-14">
                      {item?.publicMessage && (
                      <>
                        <div className="vl-property">Message:</div>
                        <div className="vl-value-text">{item?.publicMessage}</div>
                      </>
                      )}
                      <div className="vl-property">Phone:</div>
                      <div className="vl-value">{item?.phoneNumber}</div>
                      <div className="vl-property">Email:</div>
                      <div className="vl-value">{item?.email}</div>
                      <div className="vl-property">Organization:</div>
                      <div className="vl-value">{item?.organization}</div>
                      <div className="vl-property">Facility:</div>
                      <div className="vl-value">{item?.facility}</div>
                    </div>
                  </Panel>
                </Collapse>

                {!adapter?.clinicians.loading && (
                  <>
                    <div className="favorite-star-wrapper">
                      <FavoriteStar
                        isFavorite={!!item.favorite}
                        clinician={item}
                        onUpdateFavorite={updateFavoriteHandler}
                        filledStyle={filledStar}
                        outlinedStyle={starOutlined}
                      />
                    </div>
                    <div className="call-button-wrapper">
                      <CallButton
                        clinician={item}
                      />
                    </div>
                  </>
                )}

              </div>
            </List.Item>
            )
            )}
          />
          )}

        {!showFavorites && !adapter?.clinicians.loading && clinicians?.list && (
          <>
            {isMoreUsersAvailable && (
            <div className="caption f-22">
              There are more users available.
              <br />
              Please refine the search
              criteria.
            </div>
            )}
            <div className="ant-list-pagination">
              <Pagination
                showSizeChanger={false}
                hideOnSinglePage
                pageSize={adapter?.clinicians.pagination.size}
                current={currentPage}
                total={adapter?.clinicians.pagination.totalElements}
                onChange={handlePageChange}
              />
            </div>
          </>
        )}

        {(showFavorites && !adapter?.favorites.clinicians.loading && !favorites?.list)
          && (
          <div className="noFavorites">
            You have no saved favorites.
          </div>
          )}
      </div>
    </>
  );
};
