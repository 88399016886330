import React, { FC } from 'react';
import { Button } from 'antd/lib/radio';
import { useRecoilValue } from 'recoil';
import { CaregilityServiceAdapter } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

/**
 * LeaveSessionButton - component for leaving the session for anonymous participant
 *
 */
export const LeaveSessionButton: FC = () => {
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);

  /**
  * Invokes the 'endCall' method on the adapter and then closes the current browser tab
  */
  const endCall = () => {
    adapter?.endCall();
    window.close();
  };

  /**
  * Renders a confirmation modal dialog to ask the user if they want to leave the session
  * When the user clicks on "Yes", it will call the endCall() function
  */
  const renderConfirmLeaveSession = () => {
    Modal.confirm({
      title: 'This is a notification message',
      content: 'Are you sure you would like to leave the session?',
      icon: <QuestionCircleOutlined />,
      centered: true,
      okText: 'Yes',
      onOk: () => {
        endCall();
      },
      cancelText: 'No',
    });
  };

  return (
    <Button
      className="vl-btn vl-btn-red leave-session-btn"
      style={{ position: 'absolute' }}
      onClick={() => renderConfirmLeaveSession()}
    >
      Leave Session
    </Button>
  );
};
