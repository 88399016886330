import React, { FC } from 'react';

export interface MessageProps {
  message: string,
  avatar?: string,
  initials?: string,
  name: string,
}
/**
 * A Component to visualize a single message
 * @param message - the message to be displaied
 * @param avatar - src to avatar's image default undefined
 * @param initials - initials to be displayed instead of avatar default G for 'Guest'
 * @param name - name
 */
const Message : FC<MessageProps> = ({
  message, avatar, initials, name,
}) => (
  <>
    <div className="vl-avatar">
      {avatar != null ? (
        <img
          alt=""
          className="vl-circle-inner1"
          src={`data:image/png;base64,${avatar}`}
        />
      ) : (
        <div className="vl-circle-inner1">{initials}</div>
      )}
    </div>
    <div className="vl-chat-list-item">
      <div className="vl-chat-name">
        {name}
      </div>
      <div className="vl-chat-message">
        {message}
      </div>
    </div>
  </>
);

Message.defaultProps = {
  avatar: undefined,
  initials: 'G',
};

export default Message;
