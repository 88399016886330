import React, { FC, useState } from 'react';
import { CaregilityServiceAdapter } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { useRecoilValue } from 'recoil';
import { Input, Popconfirm, Tooltip } from 'antd';
import { StarOutlined, StarFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { blue, red } from '@assets/styles/antd-custom-styles';
import {
  DeviceData, FavoriteModel, ListData, ProfileData,
} from '@types';

export interface FavoriteStarProps {
  isFavorite: boolean;
  favorite?: FavoriteModel | null | undefined;
  clinician?: ProfileData;
  onUpdateFavorite?: (deviceId: number | undefined) => void;
  roomData?: ListData;
  searchRoom?: DeviceData;
  metadata?: string;
  filledStyle: React.CSSProperties;
  outlinedStyle: React.CSSProperties;
}

/**
 * FavoriteStar Component
 *
 * @param props - props
 */
export const FavoriteStar: FC<FavoriteStarProps> = (props) => {
  const {
    isFavorite,
    favorite,
    clinician,
    onUpdateFavorite,
    roomData,
    searchRoom,
    metadata,
    filledStyle,
    outlinedStyle,
  } = props;

  const [favoriteValue, setFavoriteValue] = useState('');
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const favoritesManager = adapter?.getFavoritesManager();

  /**
   * Sets the favorite value in state when the value of the input element changes
   * @param event event
   */
  const onFavoriteValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFavoriteValue(event.target.value);
  };

  /**
   * Adds a room or patient to the favorites list
   */
  const addRoomToFavorites = () => {
    try {
      if (roomData?.device_type === 'CARE_DEVICE') {
        const name = (favoriteValue && favoriteValue.length > 0) ? favoriteValue : roomData?.name;
        favoritesManager?.addFavorite({ type: 'ROOM', name, metadata }).then(() => {
          favoritesManager.fetchFavoriteDevices();
          if (onUpdateFavorite) {
            onUpdateFavorite(roomData.id);
          }
          favoritesManager?.getCurrentDeviceFavoriteStatus();
        });
      } else if (roomData?.device_type === 'MOBILE_DEVICE') {
        const name = (favoriteValue && favoriteValue.length > 0) ? favoriteValue : roomData?.name;
        favoritesManager?.addFavorite({ type: 'PATIENT', name, metadata }).then(() => {
          favoritesManager.fetchFavoriteDevices();
          if (onUpdateFavorite) {
            onUpdateFavorite(roomData.id);
          }
          favoritesManager?.getCurrentDeviceFavoriteStatus();
        });
      } else if (searchRoom?.device_category === 'CARE_DEVICE' || searchRoom?.device_category === 'STANDARD') {
        const name = (favoriteValue && favoriteValue.length > 0) ? favoriteValue : searchRoom.device_name;
        const data = encodeURI(`${searchRoom.customer}/${searchRoom.facility}/${searchRoom.unit}/${searchRoom.unique_id}`);
        favoritesManager?.addFavorite({ type: 'ROOM', name, metadata: data }).then(() => {
          favoritesManager.fetchFavoriteDevices();
          if (onUpdateFavorite) {
            onUpdateFavorite(searchRoom.device_id);
          }
          favoritesManager?.getCurrentDeviceFavoriteStatus();
        });
      }
    } catch (error) {
      console.error('addRoomToFavorites', error);
    }
    setFavoriteValue('');
  };

  /**
   * Adds a clinician to the favorites list
   */
  const addClinitianToFavorites = () => {
    const name = favoriteValue?.length > 0 ? favoriteValue : `${clinician?.firstName} ${clinician?.lastName}`;
    favoritesManager?.addFavorite({ type: 'PROFILE', name, metadata: `${clinician?.id}` }).then(() => {
      favoritesManager.fetchFavoriteClinicians();
      if (onUpdateFavorite) {
        onUpdateFavorite(clinician?.id);
      }
    });
    setFavoriteValue('');
  };

  /**
   * Determines whether to add a clinician or a room/patient to the favorites list
   */
  const addToFavorites = () => {
    if (clinician) {
      addClinitianToFavorites();
    } else {
      addRoomToFavorites();
    }
  };

  /**
   * Removes a favorite item from the favorites list
   */
  const removeFromFavorites = () => {
    favoritesManager?.removeFavorite(
      favorite?.id.toString() || searchRoom?.unique_id.toString() || clinician?.favorite.id.toString(),
    )
      .then(() => {
        favoritesManager?.fetchFavoriteDevices();
        favoritesManager?.fetchFavoriteClinicians();
        if (onUpdateFavorite) {
          onUpdateFavorite(roomData?.id || searchRoom?.device_id || favorite?.id);
        }
        favoritesManager?.getCurrentDeviceFavoriteStatus();
      });
  };

  /**
   * Gets the correct input
   */
  const getFavoriteInput = () => (
    <>
      <div style={{ width: 400 }}>
        {`Do you want to add ${(roomData?.name || searchRoom?.device_name || `${clinician?.firstName} ${clinician?.lastName}`) ?? ''} to your favorites?`}
      </div>
      <Input
        onMouseDown={(e) => e.stopPropagation()}
        value={favoriteValue}
        placeholder={(roomData?.name || searchRoom?.device_name || `${clinician?.firstName} ${clinician?.lastName}`) ?? 'input favorite name'}
        onChange={onFavoriteValueChange}
      />
    </>
  );

  return (
    <>
      {isFavorite
            && (
            <span onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                onConfirm={() => removeFromFavorites()}
                cancelText="Cancel"
                okText="Delete"
                title={`Are you sure you want to remove ${roomData?.name || favorite?.name || `${clinician?.firstName} ${clinician?.lastName}`} from favorites?`}
                icon={<QuestionCircleOutlined style={red} />}
              >
                <Tooltip title="Remove from favorites" placement="bottom">
                  <StarFilled className="filled" style={filledStyle} />
                </Tooltip>
              </Popconfirm>
            </span>
            )}

      {!isFavorite
            && (
            <span onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                title={getFavoriteInput()}
                icon={<QuestionCircleOutlined style={blue} />}
                okText="Save"
                cancelText="Cancel"
                onConfirm={() => addToFavorites()}
              >
                <Tooltip title="Add to favorites" placement="bottom">
                  <StarOutlined className="outlined" style={outlinedStyle} />
                </Tooltip>
              </Popconfirm>
            </span>
            )}
    </>
  );
};

FavoriteStar.defaultProps = {
  favorite: undefined,
  clinician: undefined,
  onUpdateFavorite: () => {},
  roomData: {
    id: 0,
    name: '',
  },
  searchRoom: undefined,
  metadata: '',
};
