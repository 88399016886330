import React, { FC } from 'react';
import { CaregilityServiceAdapter } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { useRecoilValue } from 'recoil';
import {
  AssociatedProfile, AssociatedRoom, DeviceData, FavoriteModel, InvitationType,
} from '@types';
import { Button, Popconfirm, Tooltip } from 'antd';
import {
  blue, grey, red, transparentBtn,
} from '@assets/styles/antd-custom-styles';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import videoOnSvg from '@assets/images/panels/video_invite_on.svg';
import videoOffSvg from '@assets/images/panels/video_invite_dis.svg';

export interface CallButtonProps {
  careDevice?: FavoriteModel;
  clinician?: AssociatedProfile;
  searchRoom?: DeviceData;
  isCameraOnline?: (item: AssociatedRoom | undefined) => boolean | undefined;
  isAPDOnline?: (item: AssociatedProfile | undefined) => boolean | undefined;
  isRoomOnline?: (item: DeviceData | undefined) => boolean | undefined;
}

/**
 * Call Button Component
 *
 * @param props - props
 */
export const CallButton: FC<CallButtonProps> = (props) => {
  const {
    careDevice, clinician, searchRoom, isCameraOnline, isAPDOnline, isRoomOnline,
  } = props;

  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);

  const isAssociatedRoomOnline = isCameraOnline && isCameraOnline(careDevice?.associatedRoom);
  const isAssociatedProfileOnline = isAPDOnline && isAPDOnline(careDevice?.associatedProfile) && careDevice?.associatedRoom?.status_of_room !== 'NOT_CONFIGURED';
  const isStandardRoomOnline = isRoomOnline && isRoomOnline(searchRoom) && searchRoom?.status !== 'NOT_CONFIGURED' && searchRoom?.status !== 'OFFLINE';

  const isOnline = isAssociatedRoomOnline || isAssociatedProfileOnline || isStandardRoomOnline;

  /**
   * Invokes the 'onInviteGuest' method of the session control manager to initiate a clinician call
   * @param item clinician associated profile
   * @param e event
   */
  const onCallClinicianClick = (item: AssociatedProfile, e:React.MouseEvent | undefined) => {
    if (e?.detail && e.detail > 1) {
      return;
    }

    const { email } = item;

    adapter?.getSessionControlManager().onInviteGuest({
      type: InvitationType.clinician,
      value: email,
    });

    e?.preventDefault();
  };

  /**
   * Invokes the 'startNewSession' method
   */
  const onStartNewSessionClick = (): void => {
    const deviceId = careDevice?.associatedRoom?.device_id || searchRoom?.device_id;
    const deviceType = careDevice?.associatedRoom?.device_type || searchRoom?.device_category;

    adapter?.startNewSession(deviceId, deviceType);
  };

  return (
    <>
      {((careDevice || searchRoom) && isOnline) && (
        <Tooltip title="Start New Session" placement="bottom">
          <Button
            style={transparentBtn}
            htmlType="button"
          >
            <img
              alt="video on"
              id={`callImg_${careDevice?.id || searchRoom?.device_id}`}
              src={videoOnSvg}
              onClick={onStartNewSessionClick}
            />
          </Button>
        </Tooltip>
      )}
      {((careDevice || searchRoom) && !isOnline) && (
      <Tooltip title="Offline" placement="bottom">
        <Button
          style={transparentBtn}
          htmlType="button"
        >
          <img
            alt="video off"
            src={videoOffSvg}
          />
        </Button>
      </Tooltip>
      )}

      {(clinician && clinician.presence === 'AVAILABLE') && (
        <Tooltip title="Make a call" placement="bottom">
          <Button
            disabled={!!clinician.isInvited}
            style={transparentBtn}
            htmlType="button"
          >
            <img
              alt="video on"
              src={videoOnSvg}
              onClick={(e) => onCallClinicianClick(clinician, e)}
            />
          </Button>
        </Tooltip>
      )}
      {(clinician && clinician.presence === 'NOT_AVAILABLE') && (
        <Popconfirm
          title={`${clinician.firstName} ${clinician.lastName} is currently not available. Please try again later.`}
          icon={<ExclamationCircleOutlined style={grey} />}
          cancelButtonProps={{ hidden: true }}
        >
          <Tooltip title="Not available" placement="bottom">
            <Button
              disabled={false}
              style={transparentBtn}
              htmlType="button"
            >
              <img
                alt="video off"
                src={videoOffSvg}
              />
            </Button>
          </Tooltip>
        </Popconfirm>
      )}
      {(clinician && clinician.presence === 'ON_CALL') && (
        <Popconfirm
          onConfirm={(e) => onCallClinicianClick(clinician, e)}
          title={`${clinician.firstName} ${clinician.lastName} is currently on call. Do you still want to make the call?`}
          icon={<QuestionCircleOutlined style={blue} />}
        >
          <Tooltip title="On another call" placement="bottom">
            <Button
              disabled
              style={transparentBtn}
              htmlType="button"
            >
              <img
                alt="video off"
                src={videoOffSvg}
              />
            </Button>
          </Tooltip>
        </Popconfirm>
      )}
      {(clinician && clinician.presence === 'DO_NOT_DISTURB') && (
        <Popconfirm
          onConfirm={(e) => onCallClinicianClick(clinician, e)}
          cancelText="Cancel"
          okText="Call"
          title={`${clinician.firstName} ${clinician.lastName} is in Do Not Disturb, do you wish to make the call?`}
          icon={<QuestionCircleOutlined style={red} />}
        >
          <Tooltip title="Do Not Disturb" placement="bottom">
            <Button
              disabled
              style={transparentBtn}
              htmlType="button"
            >
              <img
                alt="video off"
                src={videoOffSvg}
              />
            </Button>
          </Tooltip>
        </Popconfirm>
      )}
    </>
  );
};

CallButton.defaultProps = {
  careDevice: undefined,
  clinician: undefined,
  searchRoom: undefined,
  isCameraOnline: () => false,
  isAPDOnline: () => false,
  isRoomOnline: () => false,
};
