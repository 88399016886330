import React, {
  FC, useEffect,
  useState, useRef,
} from 'react';
import JoinCall from '@assets/audio/joincall.mp3';
import { useRecoilState } from 'recoil';
import { LocalSettings } from '@atoms/LocalSettings';
import { LocalSettingsModel } from '@types';
import { notification } from 'antd';

import { renderCameraError } from '../../common/utils';
import showNotificationDialog, { MessageType } from '../common/notificationDialog';
import UserDeviceSettingsView from './UserDeviceSettingsView';

const LEFT_COLUMN_SIZE = 3;
const SPAN_LEFT_ICON_VALUE = 2;
const SPAN_SLIDER_VALUE = 11;
const SPAN_RIGHT_OFFSET = 1;

/**
 * UserDeviceSettingsComponent - component for the user device settings
 *
 * @param {UserDeviceSettingsProps} UserDeviceSettingsComponentProps UserDeviceSettingsComponentProps
 */

export const UserDeviceSettingsComponent: FC = () => {
  const audioNode = useRef<ScriptProcessorNode | undefined>(undefined);
  const localStream = useRef<MediaStream | undefined | null>(null);
  const audioDestinationNode = useRef<AudioDestinationNode | undefined>(undefined);
  const [localSettings, setLocalSettings] = useRecoilState(LocalSettings);

  const [state, setState] = useState({
    microphones: [] as MediaDeviceInfo[],
    speakers: [] as MediaDeviceInfo[],
    cameras: [] as MediaDeviceInfo[],
    loading: true,
    testMic: false,
    microphoneId: '',
    speakerId: '',
    cameraId: '',
    speakerVolume: 10,
    micLevel: 0,
  });

  /**
   * Stops the microphone stream
   */
  const stopMicrophoneStream = () => {
    if (localStream.current) {
      try {
        localStream.current.getAudioTracks().forEach((track) => track.stop());
        localStream.current = null;
      } catch (err: any) {
        console.error('Error stoping microphone track', err);
        notification.error({ message: err.toString() });
      }
    }
  };

  /**
   * Analyzes audio input and sets microphone level using JavaScript
   *
   */
  const analyzeAudioInput = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: state.microphoneId
            ? { exact: state.microphoneId }
            : undefined,
        },
      })
      .then((stream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        audioNode.current = audioContext.createScriptProcessor(2048, 1, 1);

        audioDestinationNode.current = audioContext.destination;
        localStream.current = stream;

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(audioNode.current);
        audioNode.current.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          let values = 0;

          const { length } = array;
          for (let i = 0; i < length; i++) {
            values += array[i];
          }
          setState((prevState) => ({ ...prevState, micLevel: values / length / 3 }));
        };
      })
      .catch((err) => {
        renderCameraError('Video devices navigator.MediaDevices.getUserMedia error ', err);
      });
  };

  /**
   * Handles change of the microphone test state
   * @param {boolean} newState updated state of the test
   */
  const testMic = (newState: boolean) => {
    setState({ ...state, testMic: newState });
  };

  /**
   * Handles change of the microphone device
   * @param {string} selectedValue device ID
   */
  const onSelectMicrophone = (selectedValue: string) => {
    setState((oldState) => ({ ...oldState, microphoneId: selectedValue }));
    setLocalSettings((oldState: LocalSettingsModel) => ({
      ...oldState,
      defaultAudioId: selectedValue,
    }));
    showNotificationDialog('Default microphone is set.', null, MessageType.Success);
  };

  /**
   * Handles change of the camera device
   * @param {string} selectedValue device ID
   */
  const onSelectCamera = (selectedValue: string) => {
    setState((oldState) => ({ ...oldState, cameraId: selectedValue }));
    setLocalSettings((oldState: LocalSettingsModel) => ({
      ...oldState,
      defaultVideoId: selectedValue,
    }));
    showNotificationDialog('Default camera is set.', null, MessageType.Success);
  };

  /**
  * Handles change of the speaker device
  * @param {string} selectedValue device ID
  */
  const onSelectSpeaker = (selectedValue: string) => {
    setState((oldState) => ({
      ...oldState,
      speakerId: selectedValue,
    }));
    setLocalSettings((oldState: LocalSettingsModel) => ({
      ...oldState,
      defaultSpeakerId: selectedValue,
    }));
    showNotificationDialog('Default speaker is set.', null, MessageType.Success);
  };

  /**
   * Retrieves available devices and sets default devices
   *
   * @param deviceInfos - list of media device
   */
  const gotDevices = (deviceInfos: MediaDeviceInfo[]) => {
    const mics: MediaDeviceInfo[] = [];
    const sps: MediaDeviceInfo[] = [];
    const cams: MediaDeviceInfo[] = [];

    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];

      switch (deviceInfo.kind) {
        case 'audioinput':
          const mic = mics.find((o) => o.groupId === deviceInfo.groupId);
          if (!mic) { mics.push(deviceInfo); }
          break;
        case 'audiooutput':
          const sp = sps.find((o) => o.groupId === deviceInfo.groupId);
          if (!sp) { sps.push(deviceInfo); }
          break;
        case 'videoinput':
          const cam = cams.find((o) => o.groupId === deviceInfo.groupId);
          if (!cam) { cams.push(deviceInfo); }
          break;
        default:
          console.info('Some other kind of source/device: ', deviceInfo);
          break;
      }
    }

    const isMicSet = mics.find((e) => e.deviceId === localSettings.defaultAudioId);
    if (!isMicSet && mics.length) {
      onSelectMicrophone(mics[0].deviceId);
    }

    const isCameraSet = cams.find((e) => e.deviceId === localSettings.defaultVideoId);
    if (!isCameraSet && cams.length) {
      onSelectCamera(cams[0].deviceId);
    }

    const isSpeakerSet = sps.find((e) => e.deviceId === localSettings.defaultSpeakerId);
    if (!isSpeakerSet && sps.length) {
      onSelectSpeaker(sps[0].deviceId);
    }

    const vol = parseInt(localSettings.speakerVolume, 10);

    setState((oldState) => ({
      ...oldState,
      microphones: mics,
      speakers: sps,
      cameras: cams,
      loading: false,
      microphoneId: localSettings.defaultAudioId !== '' ? localSettings.defaultAudioId : mics[0]?.deviceId ?? mics[0]?.deviceId,
      cameraId: localSettings.defaultVideoId !== '' ? localSettings.defaultVideoId : cams[0]?.deviceId ?? cams[0]?.deviceId,
      speakerId: localSettings.defaultSpeakerId !== '' ? localSettings.defaultSpeakerId : sps[0]?.deviceId ?? sps[0]?.deviceId,
      speakerVolume: Number.isNaN(vol) ? 10 : vol,
    }));
  };

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.info('enumerateDevices() not supported.', null);
      return;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then(gotDevices)
      .then(analyzeAudioInput)
      .catch((error) => {
        console.error('navigator.MediaDevices.enumerateDevices error: ', error);
      });
    // eslint-disable-next-line consistent-return
    return () => {
      stopMicrophoneStream();
      audioNode.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    testMic(false);
    analyzeAudioInput();
  }, [state.microphoneId]);

  useEffect(() => {
    if (state.testMic && audioNode.current && audioDestinationNode.current) {
      audioNode.current.connect(audioDestinationNode.current);
    } else if (audioNode.current && state.testMic) {
      audioNode.current.disconnect();
    }
  }, [state.testMic]);

  /**
   * Handles change of the audio volume
   *
   * @param {number} selectedValue new value to be set
   */
  const onSetVolume = (selectedValue: number) => {
    setState({
      ...state,
      speakerVolume: selectedValue,
    });
    setLocalSettings((oldState: LocalSettingsModel) => ({
      ...oldState,
      speakerVolume: selectedValue.toString(),
    }));
  };

  const getOptions = (options: MediaDeviceInfo[]) => {
    if (options) {
      const result: { label: string; value: string }[] = [];
      options.forEach((item) => {
        result.push({ label: item.label, value: item.deviceId });
      });
      return result;
    }
    return [];
  };

  /**
   * Tests the audio device
   */
  const testAudio = () => {
    try {
      const audio = new Audio(JoinCall) as any;
      audio.volume = state.speakerVolume / 10;
      audio.setSinkId(state.speakerId).then(() => {
        audio.play();
      });
    } catch (e) {
      console.error('cannot play audio test', e);
    }
  };

  const volumeOn = { backgroundColor: '#44B449' };
  // eslint-disable-next-line react/no-unstable-nested-components
  const VolumeBar = (props: any) => {
    const { index } = props;

    return (
      <div
        className="vl-bar"
        style={index <= (state.testMic && state.micLevel) ? volumeOn : undefined}
      />
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const VolumeBars = () => (
    <div className="vl-bars-wrapper">
      {[...Array(10)].map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <VolumeBar index={i + 1} key={i} />
      ))}
    </div>
  );

  return (
    <UserDeviceSettingsView
      state={state}
      LEFT_COLUMN_SIZE={LEFT_COLUMN_SIZE}
      SPAN_LEFT_ICON_VALUE={SPAN_LEFT_ICON_VALUE}
      SPAN_SLIDER_VALUE={SPAN_SLIDER_VALUE}
      SPAN_RIGHT_OFFSET={SPAN_RIGHT_OFFSET}
      getOptions={getOptions}
      onSelectSpeaker={onSelectSpeaker}
      onSetVolume={onSetVolume}
      onSelectMicrophone={onSelectMicrophone}
      onSelectCamera={onSelectCamera}
      VolumeBars={VolumeBars}
      testAudio={testAudio}
      testMic={testMic}
    />
  );
};
