import { useEffect } from 'react';
import { CaregilityServiceAdapter } from '@adapters';
import { useRecoilState } from 'recoil';
import { DoorbellOn } from '@atoms/VideoButtons';

/**
 * Custom hook for handling the doorbell functionality.
 *
 * @param adapter - CaregilityServiceAdapter instance for accessing the camera control manager.
 */
export const useDoorbell = (adapter: CaregilityServiceAdapter): void => {
  const [doorbellOn, switchDoorbellProp] = useRecoilState(DoorbellOn);
  const cameraControl = adapter.getCameraControlManager();

  useEffect(() => {
    if (doorbellOn) {
      cameraControl?.ringDoorbell();
      setTimeout(() => {
        switchDoorbellProp(false);
      }, 1000);
    }
  }, [doorbellOn, cameraControl]);
};
