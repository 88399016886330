import { EventManager, RestManager } from '@managers';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { defaultPagination } from '@utils/helpers';
import {
  FavoriteModel, FavoriteData, AddFavoritePayload,
} from '@types';

const urls = {
  GET_FAVORITES_CLINICIANS: '/api/me/favorites/PROFILE',
  GET_FAVORITES_DEVICES: '/api/me/favorites/ROOM',
  FAVORITES_URL: '/api/me/favorites',
};

export class FavoritesManager {
  #adapter!: CaregilityServiceAdapter;

  constructor(adapter: CaregilityServiceAdapter) {
    this.#adapter = adapter;
  }

  /**
   * Notifies UI listeners for change in favorite clinicians list
   *
   * @private
   */
  #notifyCliniciansListeners(): void {
    this.#adapter?.listeners?.[EventNames.onFavoriteCliniciansChange]
      ?.forEach((listener) => listener(this.#adapter.getFavoritesClinicians()));
  }

  /**
   * Notifies UI listeners for change in favorite devices list
   *
   * @private
   */
  #notifyDevicesListeners(): void {
    this.#adapter?.listeners?.[EventNames.onFavoriteDevicesChange]
      ?.forEach((listener) => listener(this.#adapter.getFavoritesDevices()));
  }

  /**
   * Notifies UI listeners for change in current device favorite status
   *
   * @private
   */
  #notifyCurrentDeviceFavoriteStatusListeners(): void {
    this.#adapter?.listeners?.[EventNames.onCurrentDeviceFavoriteChange]
      ?.forEach((listener) => listener(this.#adapter.getCurrentDeviceFavoriteStatus()));
  }

  /**
   * Gets current device favorite status
   */
  async getCurrentDeviceFavoriteStatus(): Promise<void> {
    const favoriteStatus = await this.fetchFavoriteByDeviceId(this.#adapter.config?.sessionInfo?.device_id || '');

    this.#adapter.currentDeviceFavoriteStatus = favoriteStatus;
    this.#notifyCurrentDeviceFavoriteStatusListeners();
  }

  /**
   * Fetches favorite clinicians from backend
   *
   * @param size - size
   * @param page - page
   */
  async fetchFavoriteClinicians(size = 50, page = 0): Promise<void> {
    try {
      this.#adapter.favorites.clinicians = {
        ...this.#adapter.favorites.clinicians,
        loading: true,
      };
      this.#notifyCliniciansListeners();

      const res = await RestManager.request(`${this.#adapter.config?.endpoints?.mobileBackendUrl}${urls.GET_FAVORITES_CLINICIANS}?size=${size}&page=${page}`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      if (Array.isArray(res?._embedded?.favorites)) {
        const data: FavoriteData = {
          pagination: res.page,
          list: {},
          loading: false,
        };

        res?._embedded?.favorites.forEach((favorite: FavoriteModel) => {
          data.list[favorite.id] = favorite;
        });

        this.#adapter.favorites.clinicians = data;
      }
    } catch (e) {
      console.log('[FavoritesManager] fetchFavoriteClinicians Error: ', e);
      this.#adapter.favorites.clinicians = {
        ...this.#adapter.favorites.clinicians,
        loading: false,
      };
    }

    this.#notifyCliniciansListeners();
  }

  /**
   * Fetches favorite devices from backend
   *
   * @param size - size
   * @param page - page
   */
  async fetchFavoriteDevices(size = 50, page = 0): Promise<void> {
    try {
      this.#adapter.favorites.devices = {
        ...this.#adapter.favorites.devices,
        loading: true,
      };
      this.#notifyDevicesListeners();

      const res = await RestManager.request(`${this.#adapter.config?.endpoints?.mobileBackendUrl}${urls.GET_FAVORITES_DEVICES}?size=${size}&page=${page}`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      if (Array.isArray(res?._embedded?.favorites)) {
        const data: FavoriteData = {
          pagination: res.page,
          list: {},
          loading: false,
        };

        res?._embedded?.favorites.forEach((favorite: FavoriteModel) => {
          data.list[favorite.id] = favorite;
        });

        this.#adapter.favorites.devices = data;
      }
    } catch (e) {
      console.log('[FavoritesManager] fetchFavoriteDevices Error: ', e);
      this.#adapter.favorites.devices = {
        ...this.#adapter.favorites.devices,
        loading: false,
      };
    }

    this.#notifyDevicesListeners();
  }

  /**
   * Fetches favorite by device id
   *
   * @param deviceId - id
   */
  async fetchFavoriteByDeviceId(deviceId: string): Promise<FavoriteModel | null> {
    try {
      const res = await RestManager.request(`${this.#adapter.config?.endpoints?.mobileBackendUrl}${urls.FAVORITES_URL}?metadata~=${encodeURIComponent(`%${deviceId}%`)}`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      if (Array.isArray(res?._embedded?.favorites)
        && res?._embedded?.favorites.length > 0) {
        const favorite: FavoriteModel = res?._embedded?.favorites[0];

        return favorite;
      }

      return null;
    } catch (e) {
      console.log('[FavoritesManager] fetchFavoriteByDeviceId Error: ', e);
      return null;
    }
  }

  /**
   * Adds to favorites based on type
   *
   * @param payload - fav payload
   */
  async addFavorite(payload: AddFavoritePayload): Promise<void> {
    EventManager.dispatchEvent('onAddFavorite', payload);

    try {
      return await RestManager.request(`${this.#adapter.config?.endpoints?.mobileBackendUrl}${urls.FAVORITES_URL}`, {
        method: 'POST',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
        body: JSON.stringify(payload),
      });
    } catch (e) {
      console.log('[FavoritesManager] addFavorite Error: ', e, payload);
      return Promise.reject(e);
    }
  }

  /**
   * Removes from favorites
   *
   * @param id - id
   */
  async removeFavorite(id: string | undefined): Promise<void> {
    EventManager.dispatchEvent('onRemoveFavorite', id);

    try {
      return await RestManager.request(`${this.#adapter.config?.endpoints?.mobileBackendUrl}${urls.FAVORITES_URL}/${id}`, {
        method: 'DELETE',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });
    } catch (e) {
      console.log('[FavoritesManager] removeFavorite Error: ', e, id);
      return Promise.reject(e);
    }
  }

  /**
   * Cleanup data
   */
  cleanup(): void {
    this.#adapter.favorites.devices = {
      list: {},
      loading: false,
      pagination: { ...defaultPagination },
    };
    this.#adapter.favorites.clinicians = {
      list: {},
      loading: false,
      pagination: { ...defaultPagination },
    };
  }
}
