import { CSSProperties } from 'react';

const greyColor = '#C8C8C8';
const primaryColor = '#083659';
const labelColor = '#0092D7';
const greyColorDark = '#7D7D7D';
const fieldColor = '#0A1F33';

export const inviteBtn: CSSProperties = {
  margin: '10px 0 0 40px',
  width: '225px',
};

export const inviteGreenBtn: CSSProperties = {
  ...inviteBtn,
  marginTop: '0',
};

export const inviteGreenBtn50: CSSProperties = {
  ...inviteGreenBtn,
  margin: '50px 0 0 40px',
};

export const dashedBtn: CSSProperties = {
  background: 'none',
  margin: '10px 0 0 40px',
  width: '225px',
  height: '40px',
  color: greyColorDark,
};

export const transparentBtn: CSSProperties = {
  background: 'transparent',
  borderColor: 'transparent',
};

export const h50: CSSProperties = { height: 50 };
export const f18: CSSProperties = { fontSize: 18 };
export const f24: CSSProperties = { fontSize: 24 };

export const grey: CSSProperties = { color: 'grey' };
export const lightGrey: CSSProperties = { color: greyColor };
export const blue: CSSProperties = { color: 'blue' };
export const red: CSSProperties = { color: 'red' };

export const spin: CSSProperties = {
  fontSize: 48,
  color: labelColor,
};

export const btnSpin: CSSProperties = {
  fontSize: 18,
  color: labelColor,
};

export const btnSpinWhite: CSSProperties = {
  ...btnSpin,
  color: '#ffffff',
};

const inviteList: CSSProperties = {
  borderWidth: 0,
  borderColor: 'transparent',
};
export const inviteListEven: CSSProperties = {
  ...inviteList,
  backgroundColor: 'transparent',
};
export const inviteListOdd: CSSProperties = {
  ...inviteList,
  backgroundColor: fieldColor,
};

export const filledStar: CSSProperties = {
  verticalAlign: '-0.35em',
  color: labelColor,
  fontSize: 22,
};

export const headerFavoriteStar: CSSProperties = {
  verticalAlign: '-0.25em',
  color: greyColor,
  fontSize: 22,
  marginLeft: '30px',
};

export const headerFavoriteStarFilled: CSSProperties = {
  ...headerFavoriteStar,
  color: labelColor,
};

export const filledStarLrg: CSSProperties = {
  color: labelColor,
  fontSize: 28,
};

export const starOutlined: CSSProperties = {
  verticalAlign: '-0.35em',
  color: greyColor,
  fontSize: 22,
};

export const starOutlinedLrg: CSSProperties = {
  color: greyColor,
  fontSize: 28,
};

export const callBtn: CSSProperties = { margin: '0 10px 0 20px', lineHeight: '50px' };

const selectField: CSSProperties = {
  margin: '20px 0 0 0',
  paddingBottom: '0',
};

export const selectStyle420 = {
  style: {
    ...selectField,
    width: '420px',
    margin: '20px 0 0 0',
  },
};

export const selectStyle100 = {
  style: {
    ...selectField,
    width: '100px',
    margin: '0',
  },
};

export const logo300 = { width: '300px', height: '300px' };

export const selectStyle200h = {
  style: {
    ...selectField,
    width: '100%',
    margin: '0',
  },
};

export const inviteBottom = (height: number): CSSProperties => ({
  height: `calc(100vh - ${height}px)`,
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const selectStyle200 = {
  style: {
    ...selectField,
    width: '225px',
    margin: '20px 0 0 0',
  },
};

export const optionStyle: CSSProperties = {
  fontSize: '25px',
  color: '#B5B5B5',
};

export const inviteCloseX: CSSProperties = {
  fontSize: '20px',
  color: labelColor,
  // marginTop: "2px"
};

export const dialogBtn: CSSProperties = {
  width: '125px',
  height: '40px',
};

export const modalOKBtn: CSSProperties = {
  width: '125px',
  height: '40px',
  fontSize: '16px',
  color: '#FFFFFF',
  margin: '40px 0',
};
export const modalContent: CSSProperties = {
  padding: 0,
};

export const mt20: CSSProperties = {
  marginTop: '20px',
};

export const headerBadge: CSSProperties = {
  background: '#FF0000',
  borderRadius: '2px',
  borderColor: 'transparent',
  paddingLeft: '4px',
  paddingRight: '4px',
};

export const endSessionBtn = (isMobile: boolean): CSSProperties => (!isMobile ? { margin: '5px 0 0 40px' } : { width: 'auto', margin: '5px' });

export const mainSpin: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: primaryColor,
};
