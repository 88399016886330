import React, { FC, useState } from 'react';
import { MovablePanel } from '@components/MovablePanel/MovablePanel';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AudioOn, InviteOn, VideoOn } from '@atoms/VideoButtons';
import {
  Col, Form, Row, Switch,
} from 'antd';
import { getOptions } from '@utils/helpers';
import showNotificationDialog, { MessageType } from '@components/common/notificationDialog';
import { Guest } from './Guest';
import { Bedside } from './Bedside';
import { Clinician } from './Clinician';
import { Interpreter } from './Interpreter';

const INVITE_CHOICE = ['Care Devices', 'Clinicians', 'Guest', 'Interpreters'];

export interface InvitePanelProps {
  cameraId: string;
}

/**
 * InvitePanel - component for inviting a participant
 *
 * @param cameraId - camera id
 */
export const InvitePanel: FC<InvitePanelProps> = ({ cameraId }) => {
  const [selected, setSelected] = useState(INVITE_CHOICE[2]);
  const [inviteOn, setInviteOn] = useRecoilState(InviteOn);
  const [privacy, setPrivacy] = useState<boolean>(false);
  const setAudio = useSetRecoilState(AudioOn);
  const setVideo = useSetRecoilState(VideoOn);

  const inCall = cameraId.length > 0;
  const showPrivacySwitch = !inCall && selected === INVITE_CHOICE[0];

  /**
   * Handles change of the invite type
   *
   * @param selectedValue - invite type value
   */
  const onSelect = (selectedValue: string) => {
    setSelected(selectedValue);
  };

  /**
   * Handles a change in privacy mode by updating the audio, video, and privacy settings
   *
   * @param checked - a boolean value indicating whether the privacy mode is on or off
   */
  const handlePrivacyModeChange = (checked: boolean) => {
    if (checked) {
      setAudio(false);
      setVideo(false);
      setPrivacy(true);
    } else {
      showNotificationDialog('Privacy mode is off.', null, MessageType.Error);
      setAudio(true);
      setVideo(true);
      setPrivacy(false);
    }
  };

  return (
    <MovablePanel
      hidden={!inviteOn}
      title="Invite"
      id="invite_panel"
      width={500}
      height={720}
      top={120}
      left={1}
      onClose={() => setInviteOn(false)}
    >
      <Form>
        <Row className="invite-header" hidden={!showPrivacySwitch}>
          <Col span={24}>
            <Switch
              onChange={handlePrivacyModeChange}
              checked={privacy}
            />
            <div className="switch-label vl-cb-label vl-f-16">Privacy</div>
          </Col>
        </Row>
        <Row className="vl-invite-tabs">
          {getOptions(INVITE_CHOICE).map((tab) => (
            <div
              onClick={() => onSelect(tab.value)}
              key={tab.value}
              className={`${selected === tab.label ? 'vl-selected' : ''}`}
            >
              {tab.label}
            </div>
          ))}
        </Row>
      </Form>
      {selected === 'Care Devices' && <Bedside />}
      {selected === 'Clinicians' && <Clinician /> }
      {selected === 'Guest' && <Guest />}
      {selected === 'Interpreters' && <Interpreter />}
    </MovablePanel>
  );
};
