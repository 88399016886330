import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { ChatRoomsAtom } from '@atoms/Chat';
import { ChatRoom } from '@types';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

/**
 * An adapter wrapper to handle global chat state.
 * @param adapter - the adapter
 */
export const useChatHandlers: (
  adapter: CaregilityServiceAdapter,
  isActive: boolean,
) => void = (adapter: CaregilityServiceAdapter, isActive: boolean) => {
  const setRooms = useSetRecoilState(ChatRoomsAtom);

  useEffect(() => {
    if (isActive) {
      setRooms(adapter.getChatRooms());
      const listener = (payload: { [participantId: string]: ChatRoom }) => {
        setRooms({ ...payload });
      };
      adapter.on(EventNames.onChatRoomsUpdated, listener);

      return () => {
        adapter.off(EventNames.onChatRoomsUpdated, listener);
      };
    }
    return () => {};
  }, [adapter, isActive]);
};
