import { ZoomMtg } from '@zoomus/websdk';
import { CaregilityServiceAdapter, LocalStream } from '@adapters';
import { CaregilitVideoUIConfig, ZoomMeetConfig, ZoomParticipant } from '@types';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.5/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

const defaultConfig: ZoomMeetConfig = {
  meetingNumber: '',
  userName: '',
  sdkKey: '',
  signature: '',
  leaveUrl: '',
};

export class ZoomMeetingClientViewSDKAdapter extends CaregilityServiceAdapter {
  /**
   * @constructor
   */
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(config: CaregilitVideoUIConfig) {
    super(config);
    this.zoomUnmuteParticipant = this.zoomUnmuteParticipant.bind(this);
  }

  /**
   * Inits zoom client view
   *
   */
  init(): void {
    ZoomMtg.init({
      leaveUrl: this.config?.zoomMeetConfig?.leaveUrl || defaultConfig.leaveUrl,
      disablePreview: true,
      showMeetingHeader: false,
      success: (success: any) => {
        console.log('[ZoomMeetingClientViewSDKAdapter] init', success);
        ZoomMtg.inMeetingServiceListener('onUserJoin', this.onServiceParticipantCreate);
        ZoomMtg.inMeetingServiceListener('onUserUpdate', this.onServiceParticipantUpdate);
        ZoomMtg.inMeetingServiceListener('onUserLeave', this.onServiceParticipantDelete);
        this.startCall();
      },
      error: (err: any) => {
        console.log('[ZoomMeetingClientViewSDKAdapter] init Error: ', err);
      },
    });
  }

  startCall(): Promise<void> | void {
    ZoomMtg.join({
      signature: this.config?.zoomMeetConfig?.signature || '',
      meetingNumber: this.config?.zoomMeetConfig?.meetingNumber || '',
      userName: this.config?.zoomMeetConfig?.userName || '',
      sdkKey: this.config?.zoomMeetConfig?.sdkKey || '',
      userEmail: this.config?.zoomMeetConfig?.userEmail || '',
      passWord: this.config?.zoomMeetConfig?.zoomMeetingPassword || '',
      tk: '',
      success: () => {
        this.getSessionControlManager()?.connect?.();
        if (this.#onJoinCb) {
          this.#onJoinCb();
        }
      },
      error: (error: any) => {
        console.log('[ZoomMeetingClientViewSDKAdapter] join Error: ', error);
      },
    });
  }

  handleScreenShare(setting?: string): void {
    console.log(setting);
  }

  cleanup(): Promise<void> | void {}

  getLocalStream(): LocalStream {
    return undefined;
  }

  getMpToken(): string {
    return this.config?.zoomMeetConfig?.signature || '';
  }

  #onJoinCb?: () => void;

  onJoinSuccess(cb: () => void): void {
    this.#onJoinCb = cb;
  }

  #onUnmuteCb?: () => void;

  onUnmute(cb: () => void): void {
    this.#onUnmuteCb = cb;
  }

  endCall(): void | Promise<void> {
    ZoomMtg.leaveMeeting({});
    super.endCall();
  }

  /**
   * Lock/Unlock session using the zoom media provider and
   * informs the session controller on success
   *
   * @param action - action
   */
  lockSession(action: boolean): void {
    ZoomMtg.lockMeeting({
      lockMeeting: action,
      success: () => {
        super.lockSession(action);
      },
    });
  }

  /**
 * Unmutes a Zoom participant if the participant is the current user and is currently muted.
 * Directly interacts with the Zoom meeting UI to unmute the participant.
 *
 * @param {ZoomParticipant} participant - The Zoom participant to check for muting.
 */
  zoomUnmuteParticipant(participant: ZoomParticipant): void {
    const { userId: participantId, muted } = participant;
    let selfId = 0;

    ZoomMtg.getCurrentUser({
      success: (res: { result: { currentUser: { userId: number; }; }; }) => {
        selfId = res.result.currentUser.userId;
        if (participantId === selfId && muted === true && this.#onUnmuteCb) {
          this.#onUnmuteCb();
        }
      },
    });
  }

  /**
   * Handler for zoom participant create event
   *
   * @param participant - participant
   */
  onServiceParticipantCreate = (participant: ZoomParticipant): void => {
    super.onServiceParticipantCreate(participant);
  };

  /**
   * Handler for zoom participant update event
   *
   * @param participant - participant
   */
  onServiceParticipantUpdate = (participant: ZoomParticipant): void => {
    this.zoomUnmuteParticipant(participant);
    super.onServiceParticipantUpdate(participant);
  };

  /**
   * Handler for zoom participant delete event
   *
   * @param participant - participant
   */
  onServiceParticipantDelete = (participant: ZoomParticipant): void => {
    super.onServiceParticipantDelete(participant);
  };
}
