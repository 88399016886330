import { CaregilityStomp, IFrame, IMessage } from '@caregility/fe-aps-messaging-library';
import { EventManager, RestManager } from '@managers';
import {
  ChatMessageModel, SessionControlParticipant,
  SessionControlAction, CaregilityAccessTokenPayload,
  SessionControlActivateActionPayload, SessionControlInvitePayload,
  Participant, InviteGuestPayload, InvitationType,
} from '@types';
import { CaregilityServiceAdapter, EventNames } from '@adapters';

const urls = {
  SESSION: '/session',
  JOIN_CALL: '/session/join_call',
  END: '/session/control/end',
  LEAVE: '/session/control/leave',
  PATIENT_ON_HOLD: '/session/control/poh',
  INVITE_CLINICIAN: '/session/control/invite/clinician',
  INVITE_EMAIL: '/session/control/invite/email',
  INVITE_PSTN: '/session/control/invite/pstn',
  INVITE_SMS: '/session/control/invite/sms',
  INVITE_STANDARDS_BASED: '/session/control/invite/standards_based',
  INVITE_STRATUS: '/session/control/invite/interpreter/stratus',
  MUTE_PARTICIPANT: '/session/control/participant/mute',
  PIN_PARTICIPANT: '/session/control/participant/pin',
  DISCONNECT_PARTICIPANT: '/session/control/participant/disconnect',
  LOCK_SESSION: '/session/control/lock',
  ADMIT_PARTICIPANT: '/session/control/participant/admit',
  TRANSFER_CONTROL_PARTICIPANT: '/session/control/changeSessionManager',
  PARTICIPANT_ON_HOLD: '/session/control/participant/onhold',
  REPORTING_PARTICIPANTS: '/reporting/participant/',
};

export class SessionControlManager {
  #adapter!: CaregilityServiceAdapter;

  readonly #stomp: CaregilityStomp;

  #tokensMap: Map<number, string> = new Map();

  constructor(adapter: CaregilityServiceAdapter) {
    this.#adapter = adapter;

    this.#setupInitialParticipants(this.#adapter.config?.sessionInfo?.all_participants || []);
    this.#setupInitialChatRooms();
    this.#stomp = new CaregilityStomp({
      brokerURL: this.#adapter.config?.endpoints?.stompBrokerUrl || '',
      connectHeaders: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      reconnectDelay: 5000,
      onConnect: this.#handleOnStompConnect,
      onDisconnect: this.#handleOnStompDisconnect,
      onStompError: this.#handleOnStompError,
      onWebSocketClose: this.#handleOnWebSocketClose,
      onWebSocketError: this.#handleOnWebSocketError,
    });
  }

  #getSessionId(): string {
    return this.#adapter.config?.sessionInfo?.id?.toString() || '';
  }

  #notifyParticipantListeners(): void {
    this.#adapter?.listeners?.[EventNames.onParticipantsChange]
      ?.forEach((listener) => listener(this.#adapter.getAllParticipants()));
  }

  #notifyRoomsListeners(): void {
    this.#adapter?.listeners?.[EventNames.onChatRoomsUpdated]
      ?.forEach((listener) => listener(this.#adapter.getChatRooms()));
  }

  #notifySessionChangeListeners(): void {
    this.#adapter?.listeners?.[EventNames.onSessionChange]
      ?.forEach((listener) => listener(this.#adapter.session));
  }

  /**
   * Transforms SessionControlParticipant to UI Participant
   *
   * @param participant - SessionControlParticipant
   * @private
   */
  #transformParticipant(participant: SessionControlParticipant): Participant {
    return {
      id: participant.id,
      displayName: participant.display_name,
      isMuted: !!participant.muted,
      isPinned: !!participant.pinned,
      isWaiting: !!participant.waiting,
      sessionManager: !!participant.session_manager,
      email: participant.clinician_identifier || '',
      type: participant.type,
      isFavorite: !!participant.favorite,
      favorite: participant.favorite,
    };
  }

  /**
   * Setups initial participants
   *
   * @private
   */
  #setupInitialParticipants(participants: SessionControlParticipant[]): void {
    participants.forEach?.((participant) => {
      this.#adapter.participants.set(participant.id, this.#transformParticipant(participant));
    });

    this.#notifyParticipantListeners();
  }

  /**
   * Setups initial chat rooms
   *
   * @private
   */
  #setupInitialChatRooms(): void {
    this.#adapter.rooms[this.#getSessionId()] = {
      id: this.#getSessionId(),
      displayName: 'All',
      chatToken: this.#adapter.config?.sessionInfo?.chat_token || '',
      messages: [],
    };

    Object.entries(this.#adapter.config?.sessionInfo?.participant?.participants_chat_tokens || {})
      .forEach(([id, token]) => {
        const currentParticipant = this.#adapter.config?.sessionInfo?.all_participants?.find(
          (participant) => +participant.id === +id,
        );

        if (currentParticipant) {
          this.#adapter.rooms[+id] = {
            id,
            displayName: currentParticipant.display_name,
            chatToken: token,
            messages: [],
          };
        }
      });

    this.#notifyRoomsListeners();
  }

  /**
   * Returns if participant has a chat token
   *
   * @param participant - participant
   */
  hasChatToken = (participant: Participant): boolean => {
    if (participant.id) {
      return this.#tokensMap.has(+participant.id);
    }

    return false;
  };

  /**
   * Updates participant
   *
   * @param updatedParticipant - participant
   */
  updateParticipant(updatedParticipant: Participant): void {
    const existingParticipant = this.#adapter.participants.get(updatedParticipant?.id || '') || {};

    this.#adapter.participants.set(updatedParticipant?.id || '', {
      ...existingParticipant,
      ...updatedParticipant,
    });

    EventManager.dispatchEvent('onUpdateParticipant', { ...updatedParticipant });

    this.#notifyParticipantListeners();
  }

  /**
   * Modifies the isPinned prop to all participant so there is always
   * a single participant pinned.
   * @param target the participant to be pinned
   */
  #pinParticipant(target: Participant): void {
    this.#adapter.participants.forEach((current: Participant, key: string | number) => {
      this.#adapter.participants.set(key, { ...current, isPinned: current.id === target.id });
    });

    EventManager.dispatchEvent('onUpdateParticipant', { ...target });

    this.#notifyParticipantListeners();
  }

  /**
   * Deletes participant from the list
   *
   * @param removedParticipant
   */
  deleteParticipant(removedParticipant: Participant): void {
    EventManager.dispatchEvent('onDeleteParticipant', { ...removedParticipant });

    this.#adapter.participants.delete(removedParticipant?.id || '');

    this.#notifyParticipantListeners();
  }

  /**
   * Handles remove participant event
   *
   * @param id - participant id
   */
  handleParticipantRemove(id: number): void {
    // Unsubscribe for personal messages
    this.#tokensMap.delete(+id);
    this.#stomp.unsubscribe(id.toString());

    this.deleteParticipant({ id });

    delete this.#adapter.rooms[id.toString()];
    this.#notifyRoomsListeners();
  }

  /**
   * Connect to websocket
   */
  connect(): void {
    this.#stomp.connect();
  }

  /**
   * Disconnect from websocket
   */
  async disconnect(): Promise<void> {
    try {
      return await this.#stomp.disconnect();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Sends a chat message
   *
   * @param participantId - senderId
   * @param msg - the message
   * @param room - chatId
   */
  sendChatMessage = (participantId: string, msg: string, room: string) => {
    const message: ChatMessageModel = {
      participant_id: +participantId,
      message: msg,
      room,
    };
    const token = this.#tokensMap.get(+message.room);

    if (token) {
      this.#stomp.sendMessage({
        destination: `/app/chat/${this.#getSessionId()}/${token}`,
        body: JSON.stringify(message),
      });
    }
  };

  /**
   * Handles chat message from Stomp
   *
   * @param chatId - id of the chat
   * @param stompMessage - the stomp message
   * @private
   */
  #handleChatMessage = (chatId: string, stompMessage: IMessage) => {
    const { body } = stompMessage;
    const message: ChatMessageModel = { ...JSON.parse(body), room: chatId };

    const participantId = message?.participant_id?.toString() || '';
    const newMessage = {
      participantId,
      displayName: message.display_name || '',
      message: message.message,
    };
    let messageRoom = this.#adapter.rooms[message.room];

    if (!messageRoom) {
      messageRoom = {
        id: participantId,
        displayName: newMessage.displayName,
        chatToken: this.#adapter.config?.sessionInfo?.participant.participants_chat_tokens[participantId]
          || this.#adapter.config?.sessionInfo?.chat_token || '',
        messages: [newMessage],
      };

      this.#adapter.rooms[message.room] = messageRoom;
    } else {
      messageRoom.messages.push(newMessage);
    }

    this.#notifyRoomsListeners();
  };

  /**
   * Handles participant message from stomp
   *
   * @param message - the stomp message
   * @private
   */
  #handleParticipantMessage = (message: IMessage) => {
    const { body } = message;
    const participant: SessionControlParticipant = JSON.parse(body);

    if (participant.event === 'JOINED') {
      // Subscribe for personal messages
      this.#tokensMap.set(+participant.id, participant.chat_token);
      this.#subscribeForMessages(participant.id, participant.chat_token);
      this.updateParticipant(this.#transformParticipant(participant));

      this.#adapter.rooms[participant.id] = {
        id: participant.id,
        chatToken: participant.chat_token,
        displayName: participant.display_name,
        messages: [],
      };

      this.#notifyRoomsListeners();
    } else if (participant.event === 'LEFT') {
      this.handleParticipantRemove(+participant.id);
    }
  };

  /**
   * Handles session control message from stomp
   *
   * @param message - the stomp message
   * @private
   */
  #handleSessionControlAction = (message: IMessage) => {
    const { body } = message;
    const sessionAction: SessionControlAction = JSON.parse(body);

    if (sessionAction.action === 'MUTE' && sessionAction.participant_id) {
      const existingParticipant = this.#adapter.participants.get(sessionAction.participant_id);

      if (existingParticipant) {
        this.updateParticipant({
          id: sessionAction.participant_id,
          isMuted: !existingParticipant.isMuted,
        });
      }
    } else if (sessionAction.action === 'PIN' && sessionAction.participant_id) {
      const existingParticipant = this.#adapter.participants.get(sessionAction.participant_id);

      if (existingParticipant) {
        if (existingParticipant.isPinned) {
          this.updateParticipant({
            id: sessionAction.participant_id,
            isPinned: false,
          });
        } else {
          this.#pinParticipant(existingParticipant);
        }
      }
    } else if (sessionAction.action === 'ADMIT' && sessionAction.participant_id) {
      const existingParticipant = this.#adapter.participants.get(sessionAction.participant_id);

      if (existingParticipant) {
        this.updateParticipant({
          id: sessionAction.participant_id,
          isWaiting: false,
        });
      }
    } else if (sessionAction.action === 'NEW_SESSION_MANAGER' && sessionAction.participant_id) {
      this.#adapter.getAllParticipants().forEach((participant) => {
        const newParticipant = { ...participant };
        newParticipant.sessionManager = newParticipant.id === sessionAction.participant_id;
        this.#adapter.participants.set(newParticipant.id || '', newParticipant);
      });

      this.#notifyParticipantListeners();
    } else if (sessionAction.action === 'LOCK' || sessionAction.action === 'UNLOCK') {
      this.#adapter.session = { ...this.#adapter.session, isLocked: sessionAction.action === 'LOCK' };
      this.#notifySessionChangeListeners();
    } else if (sessionAction.action === 'SESSION_ENDED') {
      this.#adapter.endCall();
    } else if (sessionAction.action === 'DISCONNECT' && sessionAction.participant_id) {
      this.handleParticipantRemove(sessionAction.participant_id);
    }
  };

  /**
   * Decodes the access token
   *
   * @private
   */
  #decodeAccessToken(): CaregilityAccessTokenPayload | undefined {
    if (this.#adapter.config.accessToken) {
      const tokenDataBase64 = this.#adapter.config.accessToken.split('.')[1];
      return JSON.parse(atob(tokenDataBase64));
    }

    return undefined;
  }

  /**
   * Subscribe for messages from stomp
   *
   * @param chatId - the chat id
   * @param chatToken - chat token
   * @private
   */
  #subscribeForMessages(chatId: string, chatToken: string): void {
    this.#stomp.subscribe(
      chatId,
      (message) => this.#handleChatMessage(chatId, message),
      `/topic/chat/${this.#getSessionId()}/${chatToken}`,
    );
  }

  /**
   * Inits subscriptions
   *
   * @private
   */
  #initSubscriptions(): void {
    const sessionId = this.#getSessionId();
    const chatToken = this.#adapter.config?.sessionInfo?.chat_token || '';
    const { joinCallToken } = this.#adapter.config;
    const participantsChatTokens = this.#adapter.config?.sessionInfo?.participant.participants_chat_tokens || {};

    // Subscribe for session messages (public messages)
    this.#tokensMap.set(+sessionId, chatToken);
    this.#subscribeForMessages(sessionId, chatToken);

    // Subscribe for personal chat messages
    Object.entries(participantsChatTokens).forEach(([id, token]) => {
      this.#tokensMap.set(+id, token);
      this.#subscribeForMessages(id, token);
    });

    const userIdentifier = this.#decodeAccessToken()?.email || joinCallToken || '';

    // Subscribe for participants update
    this.#stomp.subscribe(
      'participants',
      this.#handleParticipantMessage,
      `/topic/${sessionId}/${userIdentifier}/participants`,
    );

    // Subscribe for session actions
    this.#stomp.subscribe(
      'sessionActions',
      this.#handleSessionControlAction,
      `/topic/session/${sessionId}/actions`,
    );
  }

  /**
   * Handles stomp connect message
   *
   * @param receipt - receipt
   * @private
   */
  #handleOnStompConnect = async (receipt: IFrame): Promise<void> => {
    console.log('[SessionControlManager] handleOnStompConnect', receipt);

    this.#initSubscriptions();
    try {
      const participants = await this.getSessionParticipants();
      this.#setupInitialParticipants(participants);
    } catch (e) {
      console.log('[PexipServiceAdapter] getSessionParticipants Error: ', e);
    }
  };

  /**
   * Handles stomp disconnect message
   *
   * @param receipt - receipt
   * @private
   */
  #handleOnStompDisconnect = (receipt: IFrame) => {
    console.log('[SessionControlManager] handleOnStompDisconnect', receipt);
  };

  /**
   * Handles stomp error message
   *
   * @param  receipt - receipt
   * @private
   */
  #handleOnStompError = (receipt: IFrame) => {
    console.log('[SessionControlManager] handleOnStompError', receipt);
  };

  /**
   * Handles websocket close message
   *
   * @param event - event
   * @private
   */
  #handleOnWebSocketClose = (event: any) => {
    console.log('[SessionControlManager] handleOnWebSocketClose', event);
  };

  /**
   * Handles websocket error message
   *
   * @param event - event
   * @private
   */
  #handleOnWebSocketError = (event: any) => {
    console.log('[SessionControlManager] handleOnWebSocketError', event);
  };

  // Rest endpoints

  /**
   * Get session participants request
   */
  getSessionParticipants(): Promise<SessionControlParticipant[]> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}/session/${this.#getSessionId()}/participants`, {
      method: 'GET',
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Mute/Unmute the local audio stream
   *
   * @param isMute - setting
   */
  muteSelfAudio(isMute: boolean): Promise<void> {
    EventManager.dispatchEvent('onMuteSelfVideo', { isMute });

    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.MUTE_PARTICIPANT}`, {
      method: 'POST',
      body: JSON.stringify({
        participant_id: this.#adapter.getMyId(),
        mp_token: this.#adapter.getMpToken(),
        activate: isMute,
        session_id: +this.#getSessionId(),
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Mute/Unmute participant
   *
   * @param participant - the participant
   */
  muteParticipant(participant: Participant): Promise<void> {
    EventManager.dispatchEvent('onMuteParticipantAudio', participant);
    const payload: SessionControlActivateActionPayload = {
      participant_id: participant.id ? +participant.id : 0,
      mp_token: this.#adapter.getMpToken(),
      activate: !(participant.isMuted),
      session_id: +this.#getSessionId(),
    };

    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.MUTE_PARTICIPANT}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Admits the participant to join the meeting
   *
   * @param participant - participant
   */
  admitParticipant(participant: Participant): Promise<void> {
    EventManager.dispatchEvent('onAdmitParticipant', participant);
    const payload: SessionControlActivateActionPayload = {
      mp_token: this.#adapter.getMpToken(),
      participant_id: participant.id ? +participant.id : 0,
      session_id: +this.#getSessionId(),
    };

    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.ADMIT_PARTICIPANT}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Denies the participant from joining the meeting
   *
   * @param participant - participant
   */
  denyParticipant(participant: Participant): Promise<void> {
    EventManager.dispatchEvent('onDenyParticipant', participant);
    return this.disconnectParticipant(participant);
  }

  /**
   * Removes the participant
   *
   * @param participant - participant
   */
  removeParticipant(participant: Participant): Promise<void> {
    EventManager.dispatchEvent('onRemoveParticipant', participant);
    return this.disconnectParticipant(participant);
  }

  /**
   * Disconnect participant request
   *
   * @param participant - participant
   */
  disconnectParticipant(participant: Participant): Promise<void> {
    const payload: SessionControlActivateActionPayload = {
      participant_id: participant.id ? +participant.id : 0,
      mp_token: this.#adapter.getMpToken(),
      session_id: +this.#getSessionId(),
    };

    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.DISCONNECT_PARTICIPANT}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Put participant on hold request
   *
   * @param payload - request payload
   */
  putParticipantOnHold(payload: SessionControlActivateActionPayload): Promise<void> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.PARTICIPANT_ON_HOLD}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config?.accessToken || '') },
    });
  }

  /**
   * Pin/Unpin Participant request
   *
   * @param participant - participant
   */
  pinParticipant(participant: Participant): Promise<void> {
    EventManager.dispatchEvent('onPinParticipant', participant);
    const payload: SessionControlActivateActionPayload = {
      participant_id: participant.id ? +participant.id : 0,
      mp_token: this.#adapter.getMpToken(),
      session_id: +this.#getSessionId(),
      activate: true,
    };

    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.PIN_PARTICIPANT}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Toggles patient on hold
   *
   * @param patientOnHold - action
   */
  putPatientOnHold(patientOnHold: boolean): Promise<void> {
    EventManager.dispatchEvent('onPatientOnHold', patientOnHold);
    const payload: SessionControlActivateActionPayload = {
      mp_token: this.#adapter.getMpToken(),
      session_id: +this.#getSessionId(),
      activate: patientOnHold,
    };

    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.PATIENT_ON_HOLD}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Lock/Unlock session request
   *
   * @param action - action
   */
  lockSession(action: boolean): Promise<void> {
    EventManager.dispatchEvent('onToggleSessionLock', action);
    const payload: SessionControlActivateActionPayload = {
      mp_token: this.#adapter.getMpToken(),
      session_id: +this.#getSessionId(),
      enabled: action,
    };

    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.LOCK_SESSION}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Ends the session
   */
  endSession(): Promise<void> {
    const payload = {
      session_id: +this.#getSessionId(),
    };
    const isHost = this.#adapter.getSelfParticipant().sessionManager;
    const url = isHost ? urls.END : urls.LEAVE;
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${url}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Invites a guest to the call
   *
   * @param payload - InviteGuestPayload
   */
  onInviteGuest(payload: InviteGuestPayload): void {
    EventManager.dispatchEvent('onInviteGuest', payload);

    switch (payload.type) {
      case InvitationType.clinician:
        this.inviteClinician({
          session_id: +this.#getSessionId(),
          email: payload.value,
        });
        break;
      case InvitationType.byPhoneNumber:
        this.inviteByPhoneNumber({
          mp_token: this.#adapter.getMpToken(),
          session_id: +this.#getSessionId(),
          phone_number: payload.value,
        });
        break;
      case InvitationType.byEmail:
        this.inviteByEmail({
          session_id: +this.#getSessionId(),
          email: payload.value,
        });
        break;
      case InvitationType.bySms:
        this.inviteBySms({
          session_id: +this.#getSessionId(),
          phone_number: payload.value,
        });
        break;
      case InvitationType.byStandardsBased:
        this.inviteByStandardsBased({
          mp_token: this.#adapter.getMpToken(),
          session_id: +this.#getSessionId(),
          address: payload.value,
          protocol: payload.protocol,
          billing_code: this.#adapter.cameraMetadata.stratus_billing_code || '',
        });
        break;
      case InvitationType.byStandardsBasedStratus:
        this.inviteByStandardsBasedStratus({
          mp_token: this.#adapter.getMpToken(),
          session_id: +this.#getSessionId(),
          guest_identifier: payload.value,
          billing_code: this.#adapter.cameraMetadata.stratus_billing_code || '',
        });
        break;
      default:
        break;
    }
  }

  /**
   * Invites a clinician to the session call
   *
   * @param payload - SessionControlInvitePayload
   */
  inviteClinician(payload: SessionControlInvitePayload): Promise<void> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.INVITE_CLINICIAN}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Invite by email
   *
   * @param payload - SessionControlInvitePayload
   */
  inviteByEmail(payload: SessionControlInvitePayload): Promise<void> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.INVITE_EMAIL}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Invite by phone number
   *
   * @param payload -SessionControlInvitePayload
   */
  inviteByPhoneNumber(payload: SessionControlInvitePayload): Promise<void> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.INVITE_PSTN}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Invite by sms
   *
   * @param payload - SessionControlInvitePayload
   */
  inviteBySms(payload: SessionControlInvitePayload): Promise<void> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.INVITE_SMS}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Invite by standard based
   *
   * @param payload - SessionControlInvitePayload
   */
  inviteByStandardsBased(payload: SessionControlInvitePayload): Promise<void> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.INVITE_STANDARDS_BASED}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Invite by stratus
   *
   * @param payload - SessionControlInvitePayload
   */
  inviteByStandardsBasedStratus(payload: SessionControlInvitePayload): Promise<void> {
    return RestManager.request(`${this.#adapter.config?.endpoints?.sessionControlUrl}${urls.INVITE_STRATUS}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }
}
