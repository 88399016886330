import {
  Button, Col, Modal, Row,
} from 'antd';
import React, { useState, FC } from 'react';
import warningSvg from '@assets/images/warning.svg';

/**
 * A notification message to be shown when the room is locked.
 * Disappears forever on close.
 */
export const LockedRoomNotification: FC = () => {
  const [show, setShow] = useState<boolean>(true);
  if (show) {
    return (
      <Modal
        title={(
          <div className="modal-title">
            <span>Room is locked.</span>
          </div>
          )}
        className="header-modal"
        onCancel={() => setShow(false)}
        visible
        centered
        destroyOnClose
        footer={null}
        width={500}
      >
        <div className="modal-content">
          <img alt="" src={warningSvg} />
          <span className="modal-info">Room is locked. Please wait, the meeting participants will let you in soon.</span>
        </div>
        <Row className="buttons">
          <Col span={24}>
            <Button
              className="btn btn-blue ml20"
              htmlType="button"
              type="primary"
              onClick={() => setShow(false)}
            >
              OK
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
  return null;
};
