import React, {
  FC, useState, useEffect,
} from 'react';
import html2canvas from 'html2canvas';
import { useRecoilState } from 'recoil';
import { ScreenshotOn } from '@atoms/VideoButtons';
import { ScreenShotView } from './ScreenShotView';

/**
 * Controller component that contains the logic part of the ScreenShot
 */
const ScreenShotComponent: FC = () => {
  const [isScreenshotOn, setScreenshotOn] = useRecoilState(ScreenshotOn);
  const [screenshotImgSrc, setScreenshotImgSrc] = useState<string | null>(null);

  /**
 * Makes a screenshot of an element with the given ID,
 *  either 'ppmtg-root' or 'zmmtg-root', and sets it as the source of an image.
 * It first gets the element with either 'ppmtg-root' or 'zmmtg-root' ID, converts it to a canvas using `html2canvas`,
 * adds the canvas to the body with class 'vl-screenshot-canvas',
 * sets the source of an image to the data URL of the canvas,
 * and finally removes the canvas from the body.
 * @returns {void}
 */
  const makeScreenshot = (): void => {
    window.requestIdleCallback(() => {
      const element = document.getElementById('ppmtg-root') || document.getElementById('zmmtg-root');
      html2canvas(element!).then((canvas) => {
        const el = document.body.appendChild(canvas);
        el.className = 'vl-screenshot-canvas';
        setScreenshotImgSrc(canvas.toDataURL());
        document.body.removeChild(canvas);
      });
    });
  };

  /**
 * Downloads an image with the given source URL and specified name.
 * This function creates an anchor element, sets its href to the source URL of the image,
 * sets the download attribute to the specified name, and finally triggers a click on the anchor element.
 * @returns {void}
 */
  const downloadImage = () => {
    const a = document.createElement('a');
    a.href = screenshotImgSrc!;
    a.download = 'image.png';
    a.click();
  };

  useEffect(() => {
    if (isScreenshotOn) {
      makeScreenshot();
    } else {
      setScreenshotImgSrc(null);
    }
  }, [isScreenshotOn]);

  return (
    isScreenshotOn ? (
      <ScreenShotView
        makeScreenshot={makeScreenshot}
        setScreenshotOn={setScreenshotOn}
        downloadImage={downloadImage}
        screenshotImgSrc={screenshotImgSrc}
      />
    ) : null
  );
};

export default ScreenShotComponent;
