import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { Layout, Row } from 'antd';
import { ButtonTypes } from '@types';
import {
  AudioOn,
  CameraControlsOn,
  ChatOn,
  DoorbellOn,
  InviteOn,
  ParticipantsOn,
  PatientOnHold,
  ScreenshareOn,
  ScreenshotOn,
  SelfViewOn,
  SettingsOn,
  VideoOn,
  HomeOn,
} from '@atoms/VideoButtons';
import { UserControlsComponent } from '../UserControls/UserControlsComponent';

const Container = Layout.Footer;

export interface FooterProps {
  onMicClick?: () => void;
  onVideoClick?: () => void;
  onSelfViewClick?: () => void;
  onDoorbellClick?: () => void;
  onScreenshotClick?: () => void;
  onPatientOnHoldClick?: () => void;
  onShareContentClick?: () => void;
  onParticipantsClick?: () => void;
  onSettingsClick?: () => void;
  onInviteClick?: () => void;
  onChatClick?: () => void;
  onCameraControlsClick?: () => void;
  onHomeClick?: () => void;
  cameraId: string;
  configFooterButtons?: ButtonTypes[];
  style?: React.CSSProperties;
}

/**
 * Footer - component for controls and settings
 * @param style - exposes the footer main container's style attribute for customization.
 */
export const Footer: FC<FooterProps> = (props) => {
  const {
    onMicClick,
    onVideoClick,
    onPatientOnHoldClick,
    onSelfViewClick,
    onDoorbellClick,
    onScreenshotClick,
    onShareContentClick,
    onParticipantsClick,
    onSettingsClick,
    onInviteClick,
    onChatClick,
    onCameraControlsClick,
    onHomeClick,
    cameraId,
    configFooterButtons,
    style,
  } = props;

  const [videoOn, switchVideoProp] = useRecoilState(VideoOn);
  const [audioOn, switchAudioProp] = useRecoilState(AudioOn);
  const [selfViewOn, switchSelfViewProp] = useRecoilState(SelfViewOn);
  const [patientOnHold, switchPatientOnHoldProp] = useRecoilState(PatientOnHold);
  const [doorbellOn, switchDoorbellProp] = useRecoilState(DoorbellOn);
  const [screenshotOn, switchScreenshotOn] = useRecoilState(ScreenshotOn);
  const [screenshareOn, switchScreenshareOn] = useRecoilState(ScreenshareOn);
  const [settingsOn, switchSettingsOn] = useRecoilState(SettingsOn);
  const [inviteOn, switchInviteOn] = useRecoilState(InviteOn);
  const [chatOn, switchChatOn] = useRecoilState(ChatOn);
  const [cameraControlsOn, switchCameraControlsOn] = useRecoilState(CameraControlsOn);
  const [participantsOn, switchParticipantsOn] = useRecoilState(ParticipantsOn);
  const [homeOn, switchHomeOn] = useRecoilState(HomeOn);

  return (
    <Container data-html2canvas-ignore className="vl-app-footer" style={style}>
      <Row className="vl-app-footer-row">
        <UserControlsComponent
          cameraId={cameraId}
          audioOn={audioOn}
          onMicClick={onMicClick || (() => switchAudioProp((p: boolean) => !p))}
          videoOn={videoOn}
          onVideoClick={onVideoClick || (() => switchVideoProp((p: boolean) => !p))}
          patientOnHoldOn={patientOnHold}
          onPatientOnHoldClick={onPatientOnHoldClick || (() => switchPatientOnHoldProp((p: boolean) => !p))}
          selfViewOn={selfViewOn}
          onSelfViewClick={onSelfViewClick || (() => switchSelfViewProp((p: boolean) => !p))}
          doorbellOn={doorbellOn}
          onDoorbellClick={onDoorbellClick || (() => switchDoorbellProp((p: boolean) => !p))}
          screenShotOn={screenshotOn}
          onScreenshotClick={onScreenshotClick || (() => switchScreenshotOn((p: boolean) => !p))}
          screenShareOn={screenshareOn}
          onShareContentClick={onShareContentClick || (() => switchScreenshareOn((p: boolean) => !p))}
          participantsOn={participantsOn}
          onParticipantsClick={onParticipantsClick || (() => switchParticipantsOn((p: boolean) => !p))}
          settingsOn={settingsOn}
          onSettingsClick={onSettingsClick || (() => switchSettingsOn((p: boolean) => !p))}
          inviteOn={inviteOn}
          onInviteClick={onInviteClick || (() => switchInviteOn((p: boolean) => !p))}
          chatOn={chatOn}
          onChatClick={onChatClick || (() => switchChatOn((p: boolean) => !p))}
          cameraControlsOn={cameraControlsOn}
          homeOn={homeOn}
          onHomeClick={onHomeClick || (() => switchHomeOn((p: boolean) => !p))}
          onCameraControlsClick={onCameraControlsClick || (() => switchCameraControlsOn((p: boolean) => !p))}
          configFooterButtons={configFooterButtons}
        />
      </Row>
      <div
        className="vl-poh-sign"
        style={{ visibility: (patientOnHold) ? undefined : 'hidden' }}
      >
        Patient is On Hold
      </div>
    </Container>
  );
};

Footer.defaultProps = {
  onMicClick: undefined,
  onVideoClick: undefined,
  onPatientOnHoldClick: undefined,
  onSelfViewClick: undefined,
  onDoorbellClick: undefined,
  onScreenshotClick: undefined,
  onShareContentClick: undefined,
  onParticipantsClick: undefined,
  onSettingsClick: undefined,
  onInviteClick: undefined,
  onChatClick: undefined,
  onCameraControlsClick: undefined,
  onHomeClick: undefined,
  configFooterButtons: undefined,
  style: undefined,
};
