import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { RecoilRoot } from 'recoil';
import { ServiceManager } from '@managers';
import { CaregilityServiceAdapter } from '@adapters';
import { CaregilitVideoUIConfig } from '@types';
import { VideoSceneWrapper } from './VideoSceneWrapper';

/**
 * VideoScene - Main VideoScene component
 *
 * @param props - CaregilitVideoUIConfig
 * @constructor
 */
export const VideoScene = forwardRef((props: CaregilitVideoUIConfig, ref) => {
  const {
    view, showHeader, configFooterButtons, joinCallToken,
  } = props;

  const adapter = useRef<CaregilityServiceAdapter>(new ServiceManager(props).getAdapter());

  /**
   * Exposes the returned methods to be used as ref
   */
  useImperativeHandle(ref, () => ({
    /**
     * Disconnects an active call
     *
     * @param callback - callback
     */
    disconnectCall: async (callback?: (success: boolean) => void) => {
      try {
        await adapter.current.endCall();
        callback?.(true);
      } catch (e) {
        console.log('[VideoScene] disconnectCall Error: ', e);
        callback?.(false);
      }
    },
  }));

  return (
    <RecoilRoot>
      <VideoSceneWrapper
        adapter={adapter.current}
        view={view}
        configFooterButtons={configFooterButtons}
        showHeader={showHeader}
        joinCallToken={joinCallToken}
      />
    </RecoilRoot>
  );
});
