import { RestManager } from '@managers';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import {
  CameraBookmarkModel, CameraMetadata,
  CameraPan, CameraZoom, CameraCommand, CaregilitySession,
} from '@types';

export class CameraControlManager {
  #adapter!: CaregilityServiceAdapter;

  constructor(adapter: CaregilityServiceAdapter) {
    this.#adapter = adapter;
    this.#init();
  }

  /**
   * Handles initialization logic
   *
   * @private
   */
  #init(): void {
    this.#fetchCameraMetadata();
  }

  /**
   * Gets session info
   */
  getSessionInfo(): CaregilitySession | undefined {
    return this.#adapter.config?.sessionInfo;
  }

  /**
   * Updates camera metadata
   *
   * @private
   */
  #updateCameraMetadata(data: CameraMetadata): void {
    this.#adapter.cameraMetadata = data;
    this.#handleBookMarksListener();
  }

  /**
   * Build the request url
   *
   * @private
   */
  #buildCameraMetadataRequestUrl(): string {
    return `${this.getCameraMetadataUrl()}/device/${this.getSessionInfo()?.device_id || ''}`;
  }

  /**
   * Gets the camera metadata from the server
   *
   * @private
   */
  async #fetchCameraMetadata(): Promise<void> {
    try {
      const response = await RestManager.request(this.#buildCameraMetadataRequestUrl(), {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      this.#updateCameraMetadata(response);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Sends bookmarks list to the listener
   *
   * @private
   */
  #handleBookMarksListener(): void {
    this.#adapter?.listeners?.[EventNames.onBookmarksChange]
      ?.forEach((listener) => listener(this.#adapter.getCameraBookmarks()));
  }

  /**
   * Gets camera id
   */
  getCameraId(): string {
    return this.#adapter.cameraMetadata.unique_id
      || `${this.#adapter.config?.endpoints?.envPrefix}${this.#adapter.config?.sessionInfo?.device_id || ''}`;
  }

  /**
   * Gets camera control backend url
   */
  getCameraControlUrl(): string {
    return this.#adapter.config?.endpoints?.cameraControlUrl || '';
  }

  /**
   * Gets camera metadata backend url
   */
  getCameraMetadataUrl(): string {
    return this.#adapter.config?.endpoints?.camerasUrl || '';
  }

  /**
   * Sets the camera speaker volume
   *
   * @param speakerValue - volume value
   */
  setCameraSpeaker(speakerValue: number): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.SET_SPEAKER,
        params_p: {
          mute: speakerValue,
          point: speakerValue,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Sets camera mic volume value
   *
   * @param micValue - mic volume value
   */
  setCameraMic(micValue: number): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.SET_MICROPHONE,
        params_p: {
          mute: micValue,
          point: micValue,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Adjust the volume of the bell in the room.
   *
   * @param bellValue - bell volume value
   */
  setCameraBell(bellValue: number): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.ADJUST_BELL,
        params_p: {
          bell_volume: bellValue,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Rings the bell in the room
   */
  ringDoorbell(): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.RING_BELL,
        params_p: '',
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Adjusts camera brightness
   *
   * @param brightnessValue - Brightness is an integer value from 0 to 20
   */
  setCameraBrightness(brightnessValue: number): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.ADJUST_BRIGHTNESS,
        params_p: {
          bright: brightnessValue,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Sets the camera speed
   *
   * @param speedValue - from 0-20 or auto
   */
  setCameraSpeed(speedValue: number | string): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.SET_SPEED,
        params_p: {
          speed: speedValue,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Sets camera's night view mode
   *
   * @param on - boolean
   */
  setCameraNightView(on: boolean): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.NIGHT_VIEW,
        params_p: {
          on,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Control the camera focus manually
   * or set to ‘auto’. ‘Auto’ and ‘point’ should be the same value.
   *
   * @param focusValue - from 0-20 or auto
   */
  setCameraFocus(focusValue: number | string): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.SET_FOCUS,
        params_p: {
          auto: focusValue,
          point: focusValue,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Send the camera to a bookmarked location
   *
   * @param bookmark - the bookmark
   */
  cameraGoToBookmark(bookmark: CameraBookmarkModel): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.GO_TO_BOOKMARK,
        params_p: {
          bookmark: bookmark.internal_name,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Adds new camera bookmark
   *
   * @param bmName - name of the bookmark
   */
  async addCameraBookmark(bmName: string): Promise<void> {
    try {
      const res = await RestManager.request(this.getCameraControlUrl(), {
        method: 'POST',
        body: JSON.stringify({
          unique_id: this.getCameraId(),
          command: CameraCommand.SET_BOOKMARK,
          params_p: {
            b_name: bmName,
          },
        }),
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      this.#fetchCameraMetadata();

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Updates the bookmark
   *
   * @param bookmark - the bookmark
   */
  async updateBookmark(bookmark: CameraBookmarkModel): Promise<void> {
    try {
      await this.deleteCameraBookmark(bookmark);
      return await this.addCameraBookmark(bookmark.name);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Deletes the bookmark
   *
   * @param bookmark - the bookmark
   */
  async deleteCameraBookmark(bookmark: CameraBookmarkModel): Promise<void> {
    try {
      const res = await RestManager.request(this.getCameraControlUrl(), {
        method: 'POST',
        body: JSON.stringify({
          unique_id: this.getCameraId(),
          command: CameraCommand.DELETE_BOOKMARK,
          params_p: {
            b_name: bookmark.internal_name,
          },
        }),
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      this.#fetchCameraMetadata();

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Updates the camera home bookmark
   */
  updateCameraHome(): Promise<void> {
    return this.addCameraBookmark('Home');
  }

  /**
   * Makes the camera go to the hom bookmark
   */
  cameraGoToHome(): Promise<void> {
    return this.cameraGoToBookmark({ name: 'home', internal_name: 'home' });
  }

  /**
   * Sends command to move the camera
   *
   * @param pan - pan direction
   */
  moveCamera(pan: CameraPan): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.PAN,
        params_p: {
          dir: pan,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Move the camera to a relative point and focus level
   *
   * @param x - x value
   * @param y - y value
   * @param zoomVal - zoom value
   */
  cameraGoToPoint = (
    x: number,
    y: number,
    zoomVal: string,
  ): Promise<void> => RestManager.request(this.getCameraControlUrl(), {
    method: 'POST',
    body: JSON.stringify({
      unique_id: this.getCameraId(),
      command: CameraCommand.GO_TO_POINT,
      params_p: {
        x_val: x,
        y_val: y,
        zoom_val: zoomVal,
      },
    }),
    headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
  });

  /**
   * Reboots the camera
   */
  refreshCamera(): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.REFRESH,
        params_p: {},
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Restarts the OS
   */
  rebootCamera(): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.REBOOT,
        params_p: {},
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Sets the zoom of the camera
   *
   * @param zoom - zoom value
   */
  setCameraZoom(zoom: CameraZoom): Promise<any> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.ZOOM,
        params_p: {
          dir: zoom,
        },
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Sends zoom out command to the camera
   */
  cameraZoomOut(): Promise<void> {
    return RestManager.request(this.getCameraControlUrl(), {
      method: 'POST',
      body: JSON.stringify({
        unique_id: this.getCameraId(),
        command: CameraCommand.ZOOM_OUT,
        params_p: {},
      }),
      headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
    });
  }

  /**
   * Determines if CAMERA GO TO POINT and CAMERA ZOOM OUT events are allowed.
   * Example use case scenario: Camera isn't spotlighted
   */
  isCameraMouseControllersAllowed(): boolean {
    return true;
  }

  /**
   * Determines if the camera rebooting is allowed
   */
  isCameraRebootAllowed(): boolean {
    return !!this.#adapter.config?.sessionInfo?.allow_clinician_soft_reboot;
  }

  /**
   * Determines if camera focusing is allowed
   */
  isCameraFocusAllowed(): boolean {
    return this.#adapter.cameraMetadata.can_focus && this.#adapter.cameraMetadata.dial_out_type !== 'SIP';
  }

  /**
   * Determines if adjusting camera brightness is allowed
   */
  isAdjustBrightnessAllowed(): boolean {
    return this.#adapter.cameraMetadata.can_brightness && this.#adapter.cameraMetadata.dial_out_type !== 'SIP';
  }
}
