import React, { FC } from 'react';
import { inviteGreenBtn50, selectStyle200 } from '@assets/styles/antd-custom-styles';
import {
  Button, Form, Row, Select,
} from 'antd';

/**
 * Interpreter - component for inviting an Interpreter
 *
 */
export const Interpreter: FC = () => (
  <Form layout="vertical">
    <Row>
      <Form.Item className="vl-grey">
        <div className="vl-select-field">
          <Select
            getPopupContainer={(node) => node.parentNode}
            value="Select Language"
            placeholder="Language"
            {...selectStyle200}
          />
        </div>
      </Form.Item>
    </Row>

    <Form.Item>
      <Button
        style={inviteGreenBtn50}
        className="vl-btn vl-btn-green"
        htmlType="submit"
        type="primary"
        size="large"
        block
      >
        Call
      </Button>
    </Form.Item>
  </Form>
);
