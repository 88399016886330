import React, { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Modal } from 'antd';
import { CaregilityServiceAdapter } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ButtonTypes } from '@types';
import { ChatRoomsAtom } from '@atoms/Chat';
import showNotificationDialog, { MessageType } from '../common/notificationDialog';
import { UserControlsView } from './UserControlsView';

export interface UserControlsComponentProps {
  cameraId?: string;
  audioOn?: boolean;
  videoOn?: boolean;
  patientOnHoldOn?: boolean;
  selfViewOn?: boolean;
  doorbellOn?: boolean;
  screenShotOn?: boolean;
  screenShareOn?: boolean;
  participantsOn?: boolean;
  settingsOn?: boolean;
  inviteOn?: boolean;
  chatOn?: boolean;
  endSessionOn?: boolean;
  cameraControlsOn?: boolean;
  homeOn?: boolean;
  onMicClick?: () => void;
  onVideoClick?: () => void;
  onPatientOnHoldClick?: () => void;
  onSelfViewClick?: () => void;
  onDoorbellClick?: () => void;
  onScreenshotClick?: () => void;
  onShareContentClick?: () => void;
  onParticipantsClick?: () => void;
  onSettingsClick?: () => void;
  onInviteClick?: () => void;
  onChatClick?: () => void;
  onEndSessionClick?: () => void;
  onCameraControlsClick?: () => void;
  onHomeClick?: () => void;
  configHeaderButtons?: ButtonTypes[];
  configFooterButtons?: ButtonTypes[];
}

/**
 * UserControlsComponent- component for buttons configuration
 *
 * @param props - UserControlsComponentProps
 */
export const UserControlsComponent: FC<UserControlsComponentProps> = (props) => {
  const {
    cameraId,
    audioOn,
    onMicClick,
    patientOnHoldOn,
    onPatientOnHoldClick,
    videoOn,
    onVideoClick,
    selfViewOn,
    onSelfViewClick,
    doorbellOn,
    onDoorbellClick,
    screenShotOn,
    onScreenshotClick,
    screenShareOn,
    onShareContentClick,
    participantsOn,
    onParticipantsClick,
    settingsOn,
    onSettingsClick,
    inviteOn,
    onInviteClick,
    chatOn,
    onChatClick,
    cameraControlsOn,
    onCameraControlsClick,
    homeOn,
    onHomeClick,
    endSessionOn,
    onEndSessionClick,
    configFooterButtons,
    configHeaderButtons,
  } = props;
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const cameraControl = adapter?.getCameraControlManager();
  const chatRooms = useRecoilValue(ChatRoomsAtom);
  const [unseenChatMsgs, setUnseenChatMsgs] = useState<number>(0);

  useEffect(() => {
    if (chatRooms) {
      let unseenChatMsgsCounter = 0;
      Object.keys(chatRooms).forEach((roomId) => {
        const room = chatRooms[roomId];
        const msgs = room.messages;
        if (msgs) {
          for (let i = 0; i < msgs.length; i++) {
            if (!msgs[i].isSeen) {
              unseenChatMsgsCounter++;
            }
          }
        }
      });
      setUnseenChatMsgs(unseenChatMsgsCounter);
    }
  }, [chatRooms]);

  /**
   * triggers camera go home action
   */
  const onGoHomeClick = () => {
    cameraControl?.cameraGoToHome();
    if (onHomeClick) {
      onHomeClick();
      setTimeout(() => { // set button OFF in 2 sec
        onHomeClick();
      }, 2000);
    }
  };

  /**
   * triggers camera go home action
   */
  const onSetHomeClick = () => {
    Modal.confirm({
      title: 'Set Home Location',
      content: 'Are you sure you want change the Home location to this position!',
      icon: <ExclamationCircleOutlined />,
      okText: 'Cancel',
      cancelText: 'Set as Home',
      okButtonProps: {
        type: 'default',
      },
      cancelButtonProps: {
        type: 'primary',
      },
      centered: true,
      onCancel: () => {
        cameraControl?.updateCameraHome().then(() => {
          showNotificationDialog('New Home position is set.', null, MessageType.Success, 2);
        });
      },
    });
  };

  return (
    <UserControlsView
      cameraId={cameraId}
      audioOn={audioOn}
      onMicClick={onMicClick}
      videoOn={videoOn}
      onVideoClick={onVideoClick}
      patientOnHoldOn={patientOnHoldOn}
      onPatientOnHoldClick={onPatientOnHoldClick}
      selfViewOn={selfViewOn}
      onSelfViewClick={onSelfViewClick}
      doorbellOn={doorbellOn}
      onDoorbellClick={onDoorbellClick}
      screenShotOn={screenShotOn}
      onScreenshotClick={onScreenshotClick}
      screenShareOn={screenShareOn}
      onShareContentClick={onShareContentClick}
      participantsOn={participantsOn}
      onParticipantsClick={onParticipantsClick}
      settingsOn={settingsOn}
      onSettingsClick={onSettingsClick}
      inviteOn={inviteOn}
      onInviteClick={onInviteClick}
      chatOn={chatOn}
      unseenChatMsgs={unseenChatMsgs}
      onChatClick={onChatClick}
      cameraControlsOn={cameraControlsOn}
      onCameraControlsClick={onCameraControlsClick}
      homeOn={homeOn}
      onGoHomeClick={onGoHomeClick}
      onSetHomeClick={onSetHomeClick}
      endSessionOn={endSessionOn}
      onEndSessionClick={onEndSessionClick}
      configHeaderButtons={configHeaderButtons}
      configFooterButtons={configFooterButtons}
    />
  );
};

UserControlsComponent.defaultProps = {
  cameraId: '',
  audioOn: false,
  videoOn: false,
  patientOnHoldOn: false,
  selfViewOn: false,
  doorbellOn: false,
  screenShotOn: false,
  screenShareOn: false,
  participantsOn: false,
  settingsOn: false,
  inviteOn: false,
  chatOn: false,
  homeOn: false,
  cameraControlsOn: false,
  endSessionOn: false,
  onMicClick: () => { },
  onVideoClick: () => { },
  onPatientOnHoldClick: () => { },
  onSelfViewClick: () => { },
  onDoorbellClick: () => { },
  onScreenshotClick: () => { },
  onShareContentClick: () => { },
  onParticipantsClick: () => { },
  onSettingsClick: () => { },
  onInviteClick: () => { },
  onChatClick: () => { },
  onEndSessionClick: () => { },
  onCameraControlsClick: () => { },
  onHomeClick: () => { },
  configFooterButtons: [] as ButtonTypes[],
  configHeaderButtons: [] as ButtonTypes[],
};
