import { RestManager } from '@managers';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { defaultPagination } from '@utils/helpers';

const urls = {
  GET_DEVICES: '/inpatient/device_search',
  GET_CLINICIANS: '/api/profiles/search/byKeywords',
};

export class SearchManager {
  #adapter!: CaregilityServiceAdapter;

  constructor(adapter: CaregilityServiceAdapter) {
    this.#adapter = adapter;
  }

  /**
   * Notifies UI listeners for change in clinicians list
   *
   * @private
   */
  #notifyCliniciansListeners(): void {
    this.#adapter?.listeners?.[EventNames.onCliniciansChange]
      ?.forEach((listener) => listener(this.#adapter.getClinicians()));
  }

  /**
   * Notifies UI listeners for change in devices list
   *
   * @private
   */
  #notifyDevicesListeners(): void {
    this.#adapter?.listeners?.[EventNames.onDevicesChange]
      ?.forEach((listener) => listener(this.#adapter.getDevices()));
  }

  /**
   * Fetches Clinicians
   *
   * @param searchTerm - search term
   * @param size - page size
   * @param page - page number
   */
  async fetchClinicians(searchTerm = '', size = 50, page = 0): Promise<void> {
    try {
      this.#adapter.clinicians = {
        ...this.#adapter.clinicians,
        loading: true,
      };
      this.#notifyCliniciansListeners();

      const res = await RestManager.request(`${this.#adapter.config?.endpoints?.mobileBackendUrl}${urls.GET_CLINICIANS}?text=${encodeURIComponent(searchTerm)}&size=${size}&page=${page}&projection=relatedAttrs`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      if (Array.isArray(res?._embedded?.profiles)) {
        this.#adapter.clinicians = {
          pagination: res.page,
          list: res?._embedded?.profiles,
          loading: false,
        };
      }
    } catch (e) {
      console.log('[SearchManager] fetchClinicians Error: ', e);
      this.#adapter.clinicians = {
        ...this.#adapter.clinicians,
        loading: false,
      };
    }

    this.#notifyCliniciansListeners();
  }

  /**
   * Fetches Devices
   *
   * @param searchTerm - search term
   */
  async fetchDevices(searchTerm = ''): Promise<void> {
    try {
      this.#adapter.devices = {
        ...this.#adapter.devices,
        loading: true,
      };
      this.#notifyDevicesListeners();

      const res = await RestManager.request(`${this.#adapter.config?.endpoints?.adminBackendUrl}${urls.GET_DEVICES}?q=${encodeURIComponent(searchTerm)}`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });

      console.log('[OHO]', res);
      this.#adapter.devices.list = res;
      this.#adapter.devices = {
        ...this.#adapter.devices,
        loading: false,
      };
    } catch (e) {
      console.log('[SearchManager] fetchDevices Error: ', e);
      this.#adapter.devices = {
        ...this.#adapter.devices,
        loading: false,
      };
    }

    this.#notifyDevicesListeners();
  }

  /**
   * Cleanup data
   */
  cleanup(): void {
    this.#adapter.clinicians = {
      list: [],
      pagination: { ...defaultPagination },
      loading: false,
    };
    this.#adapter.devices = {
      list: [],
      loading: false,
    };
  }
}
