import React, { FC } from 'react';
import { CameraMetadata, CameraSettingModel } from '@types';
import { Row, Col, Slider } from 'antd';
import Mike from '@assets/images/panels/settings_room_device_mic.svg';
import Speaker from '@assets/images/panels/settings_room_device_speaker.svg';
import Doorbell from '@assets/images/panels/settings_room_device_doorbell.svg';
import Brightness from '@assets/images/panels/settings_room_device_brightness.svg';
import Speed from '@assets/images/panels/settings_room_device_speed.svg';
import Focus from '@assets/images/panels/settings_room_device_focus.svg';
import '@assets/styles/videodevices.scss';
import '@assets/styles/movablepanel.scss';

export interface RoomDeviceSettingsViewProps {
  cameraSettings: CameraSettingModel | undefined;
  cameraId: string;
  canFocus: boolean;
  canAdjBright: boolean;
  cameraMetadata: CameraMetadata;
  onSetCameraMic: (camId: string, value: number) => void;
  onSetCameraSpeaker: (camId: string, value: number) => void;
  onSetCameraFocus: (camId: string, value: number) => void;
  onSetCameraSpeed: (camId: string, value: number) => void;
  onSetCameraBrightness: (camId: string, value: number) => void;
  onSetCameraBell: (camId: string, value: number) => void;
}

/**
 * FC to visualize the room device audio video settings view.
 * @param settingsOn - boolean prop that determines is settings panel visible
 */
const RoomDeviceSettingsView: FC<RoomDeviceSettingsViewProps> = ({
  cameraSettings,
  cameraId,
  canFocus,
  canAdjBright,
  onSetCameraMic,
  onSetCameraSpeaker,
  onSetCameraFocus,
  onSetCameraSpeed,
  onSetCameraBrightness,
  onSetCameraBell,
  cameraMetadata,
}) => (
  <>
    <div className="vl-setting-left">
      <div className="vl-setting-caption">Patient Video</div>
      <Row className="vl-div-slider">
        <Col hidden={!canAdjBright}>
          <Slider
            vertical
            min={0}
            max={20}
            className="vl-setting-sliderV"
            value={cameraSettings?.brightnessValue ?? 15}
            onChange={(value: number) => onSetCameraBrightness(cameraId, value)}
          />
          <img className="vl-setting-img3" src={Brightness} alt="setting-img3" />
          <div className="vl-setting-tooltip">Brightness</div>
        </Col>
        <Col offset={canAdjBright ? 0 : 8}>
          <Slider
            vertical
            min={0}
            max={20}
            className="vl-setting-sliderV"
            value={cameraSettings?.speedValue}
            defaultValue={cameraMetadata?.default_speed}
            onChange={(value: number) => onSetCameraSpeed(cameraId, value)}
          />
          <img className="vl-setting-img3" src={Speed} alt="setting-img3" />
          <div className="vl-setting-tooltip">Speed</div>
        </Col>
        <Col hidden={!canFocus}>
          <Slider
            vertical
            min={0}
            max={20}
            className="vl-setting-sliderV"
            value={cameraSettings?.focusValue ?? 5}
            onChange={(value: number) => onSetCameraFocus(cameraId, value)}
          />
          <img className="vl-setting-img3" src={Focus} alt="setting-img3" />
          <div className="vl-setting-tooltip">Focus</div>
        </Col>
      </Row>
    </div>
    <div className="vl-setting-right">
      <div className="vl-setting-caption">Patient Audio</div>
      <Row className="vl-div-slider-left">
        <Col>
          <Slider
            vertical
            min={0}
            max={10}
            className="vl-setting-sliderV"
            defaultValue={cameraMetadata?.default_output}
            value={cameraSettings?.speakerValue}
            onChange={(value: number) => onSetCameraSpeaker(cameraId, value)}
          />
          <img className="vl-setting-img3" src={Speaker} alt="setting-img3" />
          <div className="vl-setting-tooltip">Speaker</div>
        </Col>
        <Col>
          <Slider
            vertical
            min={0}
            max={10}
            className="vl-setting-sliderV"
            defaultValue={cameraMetadata?.default_input}
            value={cameraSettings?.micValue}
            onChange={(value: number) => onSetCameraMic(cameraId, value)}
          />
          <img className="vl-setting-img3" src={Mike} alt="setting-img3" />
          <div className="vl-setting-tooltip">Mic</div>
        </Col>
        <Col>
          <Slider
            vertical
            min={0}
            max={20}
            className="vl-setting-sliderV"
            value={cameraSettings?.bellValue}
            defaultValue={cameraMetadata?.default_bell}
            onChange={(value: number) => onSetCameraBell(cameraId, value)}
          />
          <img className="vl-setting-img3" src={Doorbell} alt="setting-img3" />
          <div className="vl-setting-tooltip">Doorbell</div>
        </Col>
      </Row>
    </div>
  </>
);

export default RoomDeviceSettingsView;
