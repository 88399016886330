import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { RestManager } from '@managers';
import { defaultPagination } from '@utils/helpers';
import { CareResponseData } from '@types';

export class CareDevicesManager {
  #adapter!: CaregilityServiceAdapter;

  constructor(adapter: CaregilityServiceAdapter) {
    this.#adapter = adapter;
  }

  /**
   * Gets customers backend url
   */
  getCareDevicesUrl(): string {
    return this.#adapter.config?.endpoints?.careDevicesUrl || '';
  }

  /**
   * Updates the Customers data
   *
   * @private
   */
  #updateCustomersData(data: CareResponseData): void {
    const customers = data.list.map((customer) => ({ label: customer.name, value: customer.id }));
    this.#adapter.customers = {
      options: customers,
      page: data.page,
    };
  }

  /**
   * Updates the Facilities data
   *
   * @private
   */
  #updateFacilitiesData(data: CareResponseData): void {
    const facilities = data.list.map((facility) => ({ label: facility.name, value: facility.id }));
    this.#adapter.facilities = {
      options: facilities,
      page: data.page,
    };
  }

  /**
   * Updates the Units data
   *
   * @private
   */
  #updateUnitsData(data: CareResponseData): void {
    const units = data.list.map((unit) => ({ label: unit.name, value: unit.id }));
    this.#adapter.units = {
      options: units,
      page: data.page,
    };
  }

  /**
   * Updates the Room devices data
   *
   * @private
   */
  #updateRoomDevicesData(data: CareResponseData): void {
    const rooms = data.list.map((room) => ({ label: room.name, value: room.id }));
    this.#adapter.roomDevices = {
      options: rooms,
      page: data.page,
    };
    this.#adapter.roomFullData = {
      list: data.list,
      page: data.page,
    };
  }

  /**
   * Notifies UI listeners for change in customers options
   *
   * @private
   */
  #notifyCustomersListeners(): void {
    this.#adapter?.listeners?.[EventNames.onCustomersChange]
      ?.forEach((listener) => listener(this.#adapter.getCustomers()));
  }

  /**
   * Notifies UI listeners for change in facilities options
   *
   * @private
   */
  #notifyFacilitiesListeners(): void {
    this.#adapter?.listeners?.[EventNames.onFacilitiesChange]
      ?.forEach((listener) => listener(this.#adapter.getFacilities()));
  }

  /**
   * Notifies UI listeners for change in units options
   *
   * @private
   */
  #notifyUnitsListeners(): void {
    this.#adapter?.listeners?.[EventNames.onUnitsChange]
      ?.forEach((listener) => listener(this.#adapter.getUnits()));
  }

  /**
   * Notifies UI listeners for change in room devices options
   *
   * @private
   */
  #notifyRoomDevicesListeners(): void {
    this.#adapter?.listeners?.[EventNames.onRoomDevicesChange]
      ?.forEach((listener) => listener(this.#adapter.getRoomDevices()));
  }

  /**
   * Gets the Customers data from the server
   *
   */
  async fetchCustomersData(): Promise<void> {
    try {
      const response = await RestManager.request(this.getCareDevicesUrl(), {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });
      this.#updateCustomersData(response);
      this.#notifyCustomersListeners();
    } catch (e) {
      console.log('[CareDevicesManager] fetchCustomersData Error: ', e);
    }
  }

  /**
   * Gets the Facilities data from the server
   *
   */
  async fetchFacilitiesData(customerId: number | string): Promise<void> {
    try {
      const response = await RestManager.request(`${this.getCareDevicesUrl()}/${customerId}`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });
      this.#updateFacilitiesData(response);
      this.#notifyFacilitiesListeners();
    } catch (e) {
      console.log('[CareDevicesManager] fetchFacilitiesData Error: ', e);
    }
  }

  /**
   * Gets the Units data from the server
   *
   */
  async fetchUnitsData(customerId: number | string, facilityId: number | string): Promise<void> {
    try {
      const response = await RestManager.request(`${this.getCareDevicesUrl()}/${customerId}/${facilityId}`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });
      this.#updateUnitsData(response);
      this.#notifyUnitsListeners();
    } catch (e) {
      console.log('[CareDevicesManager] fetchUnitsData Error: ', e);
    }
  }

  /**
   * Gets the Room devices data from the server
   *
   */
  async fetchRoomDevicesData(
    customerId: number | string,
    facilityId: number | string,
    unitId: number | string,
  ):Promise<void> {
    try {
      const response = await RestManager.request(`${this.getCareDevicesUrl()}/${customerId}/${facilityId}/${unitId}`, {
        method: 'GET',
        headers: { ...RestManager.getHeader(this.#adapter.config.accessToken || '') },
      });
      this.#updateRoomDevicesData(response);
      this.#notifyRoomDevicesListeners();
    } catch (e) {
      console.log('[CareDevicesManager] fetchRoomsData Error: ', e);
    }
  }

  /**
   * Cleanup data
   */
  cleanup(): void {
    this.#adapter.customers = {
      options: [],
      page: { ...defaultPagination },
    };
    this.#adapter.facilities = {
      options: [],
      page: { ...defaultPagination },
    };
    this.#adapter.units = {
      options: [],
      page: { ...defaultPagination },
    };
    this.#adapter.roomDevices = {
      options: [],
      page: { ...defaultPagination },
    };
  }
}
