import { calculatePoint } from '@utils/helpers';
import React, {
  useCallback, useRef, useEffect, FC,
} from 'react';
import VideoView from './VideoView';
import ScreenShotComponent from '../ScreenShot/ScreenShotComponent';

interface SpeakerElement extends HTMLVideoElement {
  setSinkId: (deviceId: string) => Promise<void>;
  sinkId: string;
}

export interface VideoComponentProps {
  isAps: boolean;
  videoStream: MediaStream;
  cameraVideoStream: MediaStream;
  presentationVideoStream: MediaStream;
  screenshareVideoStream: MediaStream;
  isConnecting: boolean;
  isSelfViewOn: boolean;
  isScreenshotOn: boolean;
  isPresentationOn: boolean;
  closeScreenshot: () => void;
  speakerVolume?: number;
  speakerDeviceId?: string;
  onCloseScreenshareClick: () => void;
  cameraGoToPoint?: (x: number, y: number, zoomVal: string) => void;
  cameraZoomOut?: () => void;
}

/**
 * VideoComponent - Handles the logic of the video scene
 *
 * @param isAps - isAps
 * @param cameraVideoStream - cameraVideoStream
 * @param presentationVideoStream - presentationVideoStream
 * @param screenshareVideoStream - screenshareVideoStream
 * @param videoStream - videoStream
 * @param isConnecting - isConnecting
 * @param isSelfViewOn - isSelfViewOn
 * @param isPresentationOn - isPresentationOn
 * @param isScreenshareOn - isScreenshareOn
 * @param isScreenshotOn - isScreenshotOn
 * @param closeScreenshot - closeScreenshot
 * @param speakerVolume - speakerVolume
 * @param speakerDeviceId - speakerDeviceId
 * @param cameraGoToPoint - on mouse main button hold for more than a second event and double click on main video
 * @param cameraZoomOut - invoked on secondary mouse buttons hold more than 1 second events
 * @returns VideoComponent
 */
export const VideoComponent: FC<VideoComponentProps> = ({
  isAps,
  cameraVideoStream,
  presentationVideoStream,
  screenshareVideoStream,
  videoStream,
  isConnecting,
  isSelfViewOn,
  isPresentationOn,
  closeScreenshot,
  speakerVolume,
  speakerDeviceId,
  onCloseScreenshareClick,
  cameraGoToPoint,
  cameraZoomOut,
}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mainVideoMouseDownTimeout = useRef<any>();
  /**
   * Sets the video ref element
   */
  const cameraVideoRefSetter = useCallback((element: HTMLVideoElement) => {
    if (element) {
      // eslint-disable-next-line no-param-reassign
      element.srcObject = cameraVideoStream;
    }
  }, [cameraVideoStream]);

  const presentationVideoRefSetter = useCallback((element: HTMLVideoElement) => {
    if (element) {
      // eslint-disable-next-line no-param-reassign
      element.srcObject = presentationVideoStream;
    }
  }, [presentationVideoStream]);

  const screenshareVideoRefSetter = useCallback((element: HTMLVideoElement) => {
    if (element) {
      // eslint-disable-next-line no-param-reassign
      element.srcObject = screenshareVideoStream;
    }
  }, [screenshareVideoStream]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  useEffect(() => {
    if (!!speakerVolume && !Number.isNaN(Number(speakerVolume))) {
      videoRef.current!.volume = (Number(speakerVolume) / 10);
    } else if (speakerVolume !== null && speakerVolume !== undefined && Number(speakerVolume) === 0) {
      videoRef.current!.volume = 0;
    } else {
      videoRef.current!.volume = 1;
    }
  }, [speakerVolume]);

  useEffect(() => {
    if (speakerDeviceId) {
      const videoElement: SpeakerElement = videoRef.current as SpeakerElement;
      if (typeof videoElement.sinkId !== 'undefined') {
        videoElement
          .setSinkId(speakerDeviceId)
          .then(() => {
            console.log(
              'Success, audio output device attached',
              speakerDeviceId,
            );
          })
          .catch((error: any) => {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
              errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            console.error(errorMessage, error);
          });
      } else {
        console.warn(
          'Browser does not support output device selection.',
          speakerDeviceId,
        );
      }
    }
  }, [speakerDeviceId]);

  const getVideoBoundingRect = () => videoRef.current?.getBoundingClientRect() ?? new DOMRect();

  /**
   * Handler for main video double click event
   * @param event mouse event
   */
  const onMainVideoDoubleClick = (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    if (cameraGoToPoint) {
      const point = calculatePoint(event.target as HTMLElement, event.pageX, event.pageY, event.clientX, event.clientY);
      if (point !== null) {
        cameraGoToPoint(point.x, point.y, 'false');
      }
    }
  };

  /**
   * Handler for main video mouse down event. Starts 1 second counter to perform action.
   * @param event mouse event
   */
  const onMainVideoMouseDown = (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    if (cameraGoToPoint && cameraZoomOut) {
      mainVideoMouseDownTimeout.current = setTimeout(() => {
        const { button } = event;
        if (button < 1) {
          const point = calculatePoint(event.target as HTMLElement, event.pageX, event.pageY, event.clientX, event.clientY);
          if (point !== null) {
            cameraGoToPoint(point.x, point.y, 'true');
          }
        } else {
          cameraZoomOut();
        }
      }, 1000);
    }
  };

  /**
   * Handler for main mouse up click event. Cancels any scheduled timers.
   * @param event mouse event
   */
  const onMainVideoMouseUp = () => {
    if (mainVideoMouseDownTimeout.current) {
      clearTimeout(mainVideoMouseDownTimeout.current);
      mainVideoMouseDownTimeout.current = false;
    }
  };

  return (
    <>
      <VideoView
        isAps={isAps}
        videoContainerRef={videoContainerRef}
        cameraVideoRef={cameraVideoRefSetter}
        presentationVideoRef={presentationVideoRefSetter}
        videoRef={videoRef}
        screenshareVideoRef={screenshareVideoRefSetter}
        canvasRef={canvasRef}
        makeScreenshot={() => { }}
        closeScreenshot={closeScreenshot}
        connecting={isConnecting}
        getVideoBoundingRect={getVideoBoundingRect}
        screenshotImgSrc={null}
        isPresentationOn={isPresentationOn}
        isScreenshareOn={screenshareVideoStream !== null}
        isSelfViewOn={isSelfViewOn}
        onCloseScreenshareClick={onCloseScreenshareClick}
        onVideoDoubleClick={onMainVideoDoubleClick}
        onVideoMouseDown={onMainVideoMouseDown}
        onVideoMouseUp={onMainVideoMouseUp}
      />
      <ScreenShotComponent />
    </>
  );
};

VideoComponent.defaultProps = {
  speakerVolume: undefined,
  speakerDeviceId: undefined,
  cameraGoToPoint: undefined,
  cameraZoomOut: undefined,
};
