import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';

export interface AccordeonProps {
  children: React.ReactNode;
}

/**
 * Wrapper component that expands and collaps content
 * @param children - the content to be visible
 */
export const Accordeon: FC<AccordeonProps> = ({
  children,
}) => {
  const [collapsed, switchCollapsed] = useState<boolean>(true);
  const [height, setHeight] = useState<number>();
  const divRef = useRef<HTMLDivElement>(null);

  const style: React.CSSProperties = {
    top: collapsed ? `-${height}px` : '0px',
    transitionDuration: '1s',
    transform: 'all 0s',
    position: 'relative',
    zIndex: 1000,
  };

  useEffect(() => {
    if (divRef.current) {
      const currentRef = divRef.current;
      const observer = new ResizeObserver((entries) => {
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i];
          if (entry.target === currentRef) {
            setHeight(entry.contentRect.height);
          }
        }
      });
      observer.observe(currentRef);
      return () => {
        observer.unobserve(currentRef);
        observer.disconnect();
      };
    }
    return () => {};
  }, []);

  return (
    <div
      ref={divRef}
      style={style}
    >
      <button
        type="button"
        style={{
          height: '1.5rem',
          width: '7rem',
          position: 'absolute',
          left: '50%',
          zIndex: '1000',
          color: '#0092D7',
          border: '2px solid #0092D7',
          transform: 'translateX(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transitionDuration: '1s',
          backgroundColor: collapsed ? '#083659' : undefined,
          borderTopLeftRadius: collapsed ? undefined : '50%',
          borderTopRightRadius: collapsed ? undefined : '50%',
          borderBottomLeftRadius: collapsed ? '50%' : undefined,
          borderBottomRightRadius: collapsed ? '50%' : undefined,
          bottom: collapsed ? '-1.5rem' : '0px',
        }}
        onClick={() => {
          switchCollapsed((p) => !p);
        }}
      >
        {collapsed ? (<BsChevronDoubleDown />) : (<BsChevronDoubleUp />)}
      </button>
      {children}
    </div>
  );
};
