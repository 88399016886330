/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import {
  Badge, Dropdown, Menu,
} from 'antd';

export interface ButtonProps {
  type: string,
  text: string,
  textOn?: string,
  textOff?: string,
  isOn?: boolean,
  onClick?: () => void,
  onGoHomeClick?: () => void,
  onSetHomeClick?: () => void,
  src?: string,
  srcOn?: string,
  srcOff?: string,
  number?: number,
  visible?: boolean,
  className?: string,
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    type,
    text,
    textOn,
    textOff,
    isOn,
    onClick,
    onGoHomeClick,
    onSetHomeClick,
    src,
    srcOn,
    srcOff,
    number,
    visible,
    className,
  } = props;

  let iconStyle = '';
  if (src) {
    if (isOn) {
      iconStyle = 'vl-footer-icon-on';
    } else {
      iconStyle = 'vl-footer-icon-off';
    }
  }

  switch (type) {
    case 'button':
      return (
        visible ? (
          <div>
            {number ? (
              <Badge count={number} showZero>
                <div className={isOn ? 'vl-footer-icon-on' : 'vl-footer-icon-off'}>
                  <div id={`${text.trim()}_btn`} onClick={onClick}>
                    <img alt="vl-footer-img" className="vl-footer-img" src={src} />
                    <div className="w-90">{text}</div>
                  </div>
                </div>
              </Badge>
            ) : (
              <div className={`${iconStyle}`}>
                <div id={`${text.trim()}_btn`} onClick={onClick}>
                  {src && <img alt="" className="vl-footer-img" src={src} /> }
                  <div className={src ? 'w-90' : `${className}`}>{text}</div>
                </div>
              </div>
            )}
          </div>
        ) : null
      );
    case 'dualButton':
      return (
        visible ? (
          <div className={isOn ? 'vl-footer-icon-on' : 'vl-footer-icon-off'}>
            <span id={`${textOn}_btn`} onClick={onClick}>
              <img
                alt="vl-footer-img"
                className="vl-footer-img"
                src={isOn ? srcOn : srcOff}
              />
              <div className="w-90">{isOn ? textOn : textOff}</div>
            </span>
          </div>
        ) : null
      );
    case 'marginButton':
      return (
        visible ? (
          <div
            className={isOn
              ? 'vl-footer-icon-on vl-mr-50'
              : 'vl-footer-icon-off vl-mr-50'}
          >
            <span onClick={onClick}>
              <img alt="vl-footer-img" className="vl-footer-img" src={src} />
              <div className="w-90">{text}</div>
            </span>
          </div>
        ) : null
      );
    case 'redButton':
      return (
        visible ? (
          <div className={isOn ? 'vl-footer-icon-on-red' : 'vl-footer-icon-off'}>
            <span onClick={onClick}>
              <img alt="vl-footer-img" className="vl-footer-img" src={src} />
              <div className="w-90">{text}</div>
            </span>
          </div>
        ) : null
      );
    case 'homeButton':
      return (
        visible ? (
          <Dropdown
            overlay={(
              <Menu className="vl-header-menu">
                <Menu.Item key="0" onClick={onSetHomeClick}>
                  Set as Home
                </Menu.Item>
                <Menu.Item key="1" onClick={onGoHomeClick}>
                  Home
                </Menu.Item>
              </Menu>
          )}
            trigger={['hover']}
          >
            <a className="user-name" onClick={(e) => e.preventDefault()}>
              <div className={isOn ? 'vl-footer-icon-on' : 'vl-footer-icon-off'}>
                <div id={`${text.trim()}_btn`}>
                  <img className="vl-footer-img" src={src} alt="vl-footer-img" />
                  <div className="w-90">{text}</div>
                </div>
              </div>
            </a>
          </Dropdown>
        ) : null
      );
    default:
      return null;
  }
};

Button.defaultProps = {
  textOn: 'txtOn',
  textOff: 'txtOff',
  isOn: false,
  onClick: () => {},
  onGoHomeClick: () => {},
  onSetHomeClick: () => {},
  src: '',
  srcOn: 'srcOn',
  srcOff: 'srcOFF',
  number: 0,
  visible: true,
  className: '',
};
