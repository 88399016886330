const IS_OPERA = /OPR/.test(navigator.userAgent);
const IS_EDGE = /Edg/.test(navigator.userAgent) || /Edge/.test(navigator.userAgent);
const IS_CHROME = /Google Inc/.test(navigator.vendor) && !IS_EDGE && !IS_OPERA;
export const IS_FIREFOX = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
export const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);
export const IS_IOS = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform)
// iPad on iOS 13 detection
|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const IS_SUPPORTED = IS_EDGE || IS_CHROME || IS_FIREFOX || IS_SAFARI;

/**
 * Checks of the provided element is in the viewport
 *
 * @param el - the element
 * @param translateX - translateX value
 * @param translateY - translateY value
 * @returns {boolean} - boolean
 */
export function isElementInViewport(el : Element, translateX = 0, translateY = 0): boolean {
  const rect = el.getBoundingClientRect();

  return (
    (rect.top + translateY) >= 0
      && (rect.left + translateX) >= 0
      && (rect.bottom + translateY) <= (window.innerHeight
        || document.documentElement.clientHeight) /* or $(window).height() */
      && (rect.right + translateX) <= (window.innerWidth
        || document.documentElement.clientWidth) /* or $(window).width() */
  );
}
