import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AudioOn,
  ScreenshareOn,
  ScreenshotOn,
  SelfViewOn,
  PatientOnHold,
  DoorbellOn,
  VideoOn,
  SettingsOn,
  InviteOn,
  ChatOn,
  EndSessionOn,
  CameraControlsOn,
  HomeOn,
} from '@atoms/VideoButtons';
import { CaregilityServiceAdapter } from '@adapters';
import { useDoorbell, useIsMount, useStartNewSession } from '@hooks';

/**
 * Hook for video button handlers
 *
 * @param adapter - CaregilityServiceAdapter
 */
export const useVideoButtonsHandler = (adapter: CaregilityServiceAdapter) => {
  const isFirstMount = useIsMount();
  const [videoOn, switchVideoProp] = useRecoilState(VideoOn);
  const [audioOn, switchAudioProp] = useRecoilState(AudioOn);
  const [selfViewOn, switchSelfViewProp] = useRecoilState(SelfViewOn);
  const [patientOnHold, switchPatientOnHoldProp] = useRecoilState(PatientOnHold);
  const [doorbellOn, switchDoorbellProp] = useRecoilState(DoorbellOn);
  const screenshareOn = useRecoilValue(ScreenshareOn);
  const [screenshotOn, switchScreenshotOn] = useRecoilState(ScreenshotOn);
  const [settingsOn, switchSettingsOn] = useRecoilState(SettingsOn);
  const [inviteOn, switchInviteOn] = useRecoilState(InviteOn);
  const [chatOn, switchChatOn] = useRecoilState(ChatOn);
  const [endSessionOn, switchEndSessionOn] = useRecoilState(EndSessionOn);
  const [cameraControlsOn, switchCameraControlsOn] = useRecoilState(CameraControlsOn);
  const [homeOn, switchHomeOn] = useRecoilState(HomeOn);

  useStartNewSession();

  useEffect(() => {
    adapter.muteSelfVideo(!videoOn);
  }, [videoOn, adapter]);

  useEffect(() => {
    if (!isFirstMount) {
      adapter.getSessionControlManager()?.muteSelfAudio(!audioOn);
    }
  }, [audioOn, adapter]);

  useEffect(() => {
    if (!isFirstMount) {
      adapter.getSessionControlManager()?.putPatientOnHold(patientOnHold);
      if (patientOnHold) {
        switchVideoProp(false);
        switchAudioProp(false);
      } else if (!patientOnHold) {
        switchVideoProp(true);
        switchAudioProp(true);
      }
    }
  }, [patientOnHold, adapter]);

  useDoorbell(adapter);

  /**
   * turns screen sharing on and off
   * @param isOn - actual value
   */
  const switchScreenshareOn = (isOn: boolean) => {
    adapter.handleScreenShare(isOn ? 'screen' : undefined);
  };

  return {
    videoOn,
    switchVideoProp,
    audioOn,
    switchAudioProp,
    selfViewOn,
    switchSelfViewProp,
    patientOnHold,
    switchPatientOnHoldProp,
    doorbellOn,
    switchDoorbellProp,
    screenshareOn,
    switchScreenshareOn,
    screenshotOn,
    switchScreenshotOn,
    settingsOn,
    switchSettingsOn,
    inviteOn,
    switchInviteOn,
    chatOn,
    switchChatOn,
    endSessionOn,
    switchEndSessionOn,
    cameraControlsOn,
    switchCameraControlsOn,
    homeOn,
    switchHomeOn,
  };
};
