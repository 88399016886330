import CaregilityPexip, { Participant as PexipParticipant } from '@caregility/fe-pexip-client-library';
import { CaregilityServiceAdapter, EventNames, LocalStream } from '@adapters';
import { EventManager } from '@managers';
import { CaregilitVideoUIConfig } from '@types';

export class PexipServiceAdapter extends CaregilityServiceAdapter {
  #rtc: CaregilityPexip | null = null;

  /**
   * @constructor
   */
  constructor(config: CaregilitVideoUIConfig) {
    super(config);
    this.#rtc = new CaregilityPexip({
      pin: this.config?.pexipConfig?.pin,
      idp: this.config?.pexipConfig?.idp,
      extension: this.config?.pexipConfig?.extension,
      onConnect: this.onConnect,
      onScreenshareConnected: this.onScreenshareConnected,
      onScreenshareStopped: this.onScreenshareStopped,
      onPresentation: this.onPresentation,
      onPresentationConnected: this.onPresentationConnected,
      onPresentationDisconnected: this.onPresentationDisconnected,
      onParticipantCreate: this.onServiceParticipantCreate,
      onParticipantUpdate: this.onServiceParticipantUpdate,
      onParticipantDelete: this.onServiceParticipantDelete,
    });
  }

  /**
   * Initializes PexRTC
   */
  async init(): Promise<void> {
    try {
      const result = await this.#rtc?.init(this.config?.pexipConfig?.nodeAddress || '');

      EventManager.dispatchEvent('onInit', { success: true });
      const propKey = this.isAPS() ? 'deviceId' : 'sessionControlParticipantId';
      const callTag = {
        inpatientData: {
          [propKey]: +this.getMyId(),
        },
      };

      this.#rtc?.setCallTag(callTag);

      return result;
    } catch (e) {
      console.log('[PexipServiceServerConfig] Error: Failed to init', e);
      EventManager.dispatchEvent('onInit', { success: false });
      return Promise.reject(e);
    }
  }

  getMpToken(): string {
    return this.#rtc?.getToken() || '';
  }

  cleanup(): Promise<void> {
    return this.getSessionControlManager()?.disconnect();
  }

  /**
   * Starts Pexip call
   *
   */
  startCall(): void {
    EventManager.dispatchEvent('onCallStartPexip', null);
    this.#rtc?.clientControl?.makeCall(
      this.config?.pexipConfig?.node || '',
      this.config?.pexipConfig?.conferenceVmr || '',
      this.config?.pexipConfig?.name || '',
      this.config?.pexipConfig?.bandwidth,
      this.config?.pexipConfig?.callType,
    );
  }

  /**
   * Ends Pexip call
   */
  async endCall(): Promise<void> {
    if (this.isAPS()) {
      this.#rtc?.clientControl?.disconnect();
    } else {
      this.#rtc?.clientControl?.disconnectCall();
    }
    try {
      await this.getSessionControlManager()?.disconnect();
    } catch (e) {
      console.error(e);
    }
    super.endCall();
  }

  /**
   * Returns the local stream
   */
  getLocalStream(): LocalStream {
    return this.#rtc?.clientControl?.getLocalStream();
  }

  /**
   * Returns the id of the audio device used
   */
  getAudioDevice(): string | null {
    return this.#rtc?.clientControl?.getAudioSource() || null;
  }

  /**
   * Sets the id of the audio device to be used
   *
   * @param audioSource - audio device id
   */
  setAudioDevice(audioSource: string | null): void {
    this.#rtc?.clientControl?.setAudioSource(audioSource);
  }

  /**
   * Returns the id of the video device used
   */
  getVideoDevice(): string | null {
    return this.#rtc?.clientControl?.getVideoSource() || null;
  }

  /**
   * Sets the id of the video device to be used
   *
   * @param videoSource - video device id
   */
  setVideoDevice(videoSource: string | null): void {
    this.#rtc?.clientControl?.setVideoSource(videoSource);
  }

  /**
   * Handles screen share action
   *
   * @param action - screen share action
   */
  handleScreenShare(action?: string): void {
    this.#rtc?.clientControl?.present(action);
  }

  /**
   * Starts/Stops the local camera
   *
   * @param isMute - setting
   */
  muteSelfVideo(isMute: boolean): void {
    super.muteSelfVideo(isMute);
    // TODO: Replace with session control request?
    this.#rtc?.clientControl?.muteVideo(isMute);
  }

  /**
   * On connect handler
   *
   * @param stream - stream
   */
  onConnect = (stream: MediaStream | string | null): void => {
    EventManager.dispatchEvent('onConnectPexip', { stream });
    this.listeners?.[EventNames.onConnect]?.forEach(((listener) => listener(stream)));
    this.getSessionControlManager().connect();
  };

  /**
   * On screen share handler
   *
   * @param setting - setting
   * @param presenter - presenter
   * @param uuid - uuid
   */
  onPresentation = (setting: boolean, presenter: string, uuid: string): void => {
    EventManager.dispatchEvent('onScreenSharePexip', { setting, presenter, uuid });
    this.listeners?.[EventNames.onScreenShare]?.forEach(((listener) => listener(setting, presenter, uuid)));
    this.#rtc?.clientControl.getPresentation();
  };

  /**
   *  On self screen share connected handler
   *
   * @param stream - stream
   */
  onScreenshareConnected = (stream: MediaStream | string): void => {
    EventManager.dispatchEvent('onSelfScreenshareConnectedPexip', { stream });
    this.listeners?.[EventNames.onSelfScreenshareConnected]?.forEach(((listener) => listener(stream)));
  };

  /**
   * On screen share stopped handler
   *
   * @param reason - reason
   */
  onScreenshareStopped = (reason: string): void => {
    EventManager.dispatchEvent('onSelfScreenshareDisconnectedPexip', { reason });
    this.listeners?.[EventNames.onSelfScreenshareDisconnected]?.forEach(((listener) => listener(reason)));
  };

  /**
   * On screen share connected handler
   *
   * @param stream - stream
   */
  onPresentationConnected = (stream: MediaStream | string): void => {
    EventManager.dispatchEvent('onScreenShareConnectedPexip', { stream });
    this.listeners?.[EventNames.onScreenShareConnected]?.forEach(((listener) => listener(stream)));
  };

  /**
   * On screen share disconnected handler
   *
   * @param reason - reason
   */
  onPresentationDisconnected = (reason: string): void => {
    EventManager.dispatchEvent('onSelfScreenshareDisconnectedPexip', { reason });
    this.listeners?.[EventNames.onScreenShareDisconnected]?.forEach(((listener) => listener(reason)));
  };

  /**
   * Handler for pexip participant create event
   *
   * @param participant - participant
   */
  onServiceParticipantCreate = (participant: PexipParticipant): void => {
    super.onServiceParticipantCreate(participant);
  };

  /**
   * Handler for pexip participant update event
   *
   * @param participant - participant
   */
  onServiceParticipantUpdate = (participant: PexipParticipant): void => {
    super.onServiceParticipantUpdate(participant);
  };

  /**
   * Handler for pexip participant delete event
   *
   * @param participant - participant
   */
  onServiceParticipantDelete = (participant: PexipParticipant): void => {
    super.onServiceParticipantDelete(participant);
  };
}
