import React, { FC, useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { SmileOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CaregilitySession } from '@types';
import { Demo } from './Demo';

import '../assets/styles/app.scss';
import 'antd/dist/antd.css';

export const App: FC = () => {
  const [errorToShow, setErrorToShow] = useState<string>('');
  const [callData, setCallData] = useState<CaregilitySession>();
  const [showJoinCallButton, setShowJoinCallButton] = useState<boolean>(false);
  const callToken = new URL(window.location.href).searchParams.get('call_token') ?? '';

  useEffect(() => {
    if (callToken) {
      fetch('https://env2-sessioncontrol.caregility.net/session/join_call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          call_token: callToken,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.id) {
            setShowJoinCallButton(true);
            setCallData(data);
          }
          if (data.error) {
            setShowJoinCallButton(false);
            setErrorToShow(data.error);
          }
        })
        .catch((err) => {
          console.error(err);
          setShowJoinCallButton(false);
        });
    }
  }, []);

  const renderJoinCallButton = () => (
    <Button
      onClick={() => { setShowJoinCallButton(false); }}
      type="primary"
    >
      Join Call
    </Button>
  );

  return (
    callToken ? (
      <>
        {(callData?.id) && !showJoinCallButton && (
          <Demo
            callData={callData}
            callToken={callToken}
          />
        )}
        {errorToShow && (
          <Result
            status="error"
            icon={<CloseCircleOutlined />}
            title={errorToShow}
          />
        )}
        {showJoinCallButton && !errorToShow && (
          <Result
            status="info"
            icon={<SmileOutlined />}
            title={`Join Call with ${callData?.device_name || ''}`}
            extra={renderJoinCallButton()}
          />
        )}
      </>
    )
      : (
        <div>
          <Demo />
        </div>
      )
  );
};
