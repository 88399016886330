import ZoomMtgEmbedded, {
  EmbeddedClient, ExecutedFailure, InitOptions, JoinOptions,
} from '@zoomus/websdk/embedded';
import { EventManager } from '@managers';
import { ZoomMeetConfig } from '@types';

export class ZoomMeetingSDKAdapter {
  #client: typeof EmbeddedClient | null = null;

  #config: ZoomMeetConfig = {
    meetingNumber: '',
    userName: '',
    sdkKey: '',
    signature: '',
    leaveUrl: '',
  };

  /**
   * @param config - ZoomMeetConfig
   * @constructor
   */
  constructor(config: ZoomMeetConfig) {
    this.#config = config;
  }

  /**
   * Inits zoom client
   *
   * @param appRoot - HTMLElement
   * @param width - width
   * @param height - height
   */
  async init(appRoot: HTMLElement, width: number, height: number): Promise<void> {
    try {
      this.#client = ZoomMtgEmbedded.createClient();

      try {
        await this.#client.init(this.#generateInitOptions(appRoot, width, height));
      } catch (e) {
        console.log('[ZoomMeetingSDKAdapter] init => Error: ', e);
      }

      await this.#joinCall();

      EventManager.dispatchEvent('onInit', { success: true });
      EventManager.dispatchEvent('onCallStartZoom', {});
    } catch (e) {
      console.log('[ZoomMeetingSDKAdapter] join => Error: ', e);
    }
  }

  /**
   * Returns disable drag setting
   *
   * @private
   */
  #getDisableDrag(): object {
    return {
      popper: {
        disableDraggable: true,
      },
    };
  }

  /**
   * Generates InitOptions for ZoomMeet
   *
   * @param appRoot - container element
   * @param width - width
   * @param height - height
   * @private
   */
  #generateInitOptions(appRoot: HTMLElement, width: number, height: number): InitOptions {
    return {
      debug: true,
      zoomAppRoot: appRoot,
      language: 'en-US',
      customize: {
        meetingInfo: [
          'topic',
          'host',
          'mn',
          'pwd',
          'telPwd',
          'invite',
          'participant',
          'dc',
          'enctype',
        ],
        meeting: {
          ...this.#getDisableDrag(),
        },
        video: {
          ...this.#getDisableDrag(),
          isResizable: false,
          viewSizes: {
            default: {
              width,
              height: 1,
            },
            ribbon: {
              width,
              height: 1,
            },
          },
        },
      },
    };
  }

  /**
   * Joins ZoomMeet call
   *
   * @private
   */
  async #joinCall(): Promise<string | ExecutedFailure | undefined> {
    try {
      const joinOptions: JoinOptions = {
        sdkKey: this.#config.sdkKey,
        signature: this.#config.signature,
        meetingNumber: this.#config.meetingNumber,
        password: this.#config.zoomMeetingPassword,
        userName: this.#config.userName || '',
        userEmail: this.#config.userEmail,
        success: () => {
          console.log('[ZoomMeetingSDKAdapter] joinCall Success!');
        },
        error: (e: string) => {
          console.log('[ZoomMeetingSDKAdapter] joinCall Error: ', e);
        },
      };

      return await this.#client?.join(joinOptions);
    } catch (e) {
      console.log('[ZoomMeetingSDKAdapter] joinCall => #getSignature Error: ', e);
      return Promise.reject(e);
    }
  }

  /**
   * Ends the call
   */
  async endCall(): Promise<void> {
    try {
      await this.#client?.leaveMeeting();
      EventManager.dispatchEvent('onCallEnd', {});
    } catch (e) {
      console.log('[ZoomMeetingSDKAdapter] endCall => Error: ', e);
    }
  }
}
