import React, { FC } from 'react';
import { useCameraSettingsHandler } from '@hooks';
import {
  CameraSettingModel, CameraMetadata,
} from '@types';
import RoomDeviceSettingsView from './RoomDeviceSettingsView';

export interface CameraSettingProps extends CameraSettingModel {
  onSetCameraSpeaker: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraMic: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraBell: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraBrightness: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraSpeed: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraFocus: (cameraId: string, value: string, final: boolean) => void;
}

export interface RoomDeviceSettingsComponentProps {
  cameraId: string;
}

/**
 * RoomDeviceSettingsComponent - component for the care device settings
 *
 * @param {RoomDeviceSettingsComponentProps} RoomDeviceSettingsComponentProps RoomDeviceSettingsComponentProps
 */
export const RoomDeviceSettingsComponent: FC<RoomDeviceSettingsComponentProps> = ({
  cameraId,
}) => {
  const cameraSettingsHandler = useCameraSettingsHandler();

  const cameraSettings = {
    bellValue: cameraSettingsHandler.bell.bellValue * 40.0,
    brightnessValue: cameraSettingsHandler.brightness.brightnessValue,
    speedValue: cameraSettingsHandler.speed.speedValue,
    focusValue: cameraSettingsHandler.focus.focusValue,
    speakerValue: cameraSettingsHandler.speaker.speakerValue,
    micValue: cameraSettingsHandler.mic.micValue,
  };
  const { cameraMetadata } = cameraSettingsHandler;

  /**
  * Handles setting of the bell value for care device
  * @param {string} camId device ID
  * @param {number} value new value to set
  */
  const onSetCameraBell = (camId: string, value: number) => {
    cameraSettingsHandler.onSetCameraBell({ cameraId: camId, bellValue: value / 40.0 });
  };

  /**
 * Handles setting of the camera brightness for care device
 * @param {string} camId device ID
 * @param {number} value new value to set
 */
  const onSetCameraBrightness = (camId: string, value: number) => {
    cameraSettingsHandler.onSetCameraBrightness({ cameraId: camId, brightnessValue: value });
  };

  /**
   * Handles setting of the camera speed for care device
   * @param {string} camId device ID
   * @param {number} value new value to set
   */
  const onSetCameraSpeed = (camId: string, value: number) => {
    cameraSettingsHandler.onSetCameraSpeed({ cameraId: camId, speedValue: value });
  };

  /**
   * Handles setting of the camera focus for care device
   * @param {string} camId device ID
   * @param {number} value new value to set
   */
  const onSetCameraFocus = (camId: string, value: number) => {
    cameraSettingsHandler.onSetCameraFocus({ cameraId: camId, focusValue: value });
  };

  /**
   * Handles setting of the speaker volume for care device
   * @param {string} camId device ID
   * @param {number} value new value to set
   */
  const onSetCameraSpeaker = (camId: string, value: number) => {
    cameraSettingsHandler.onSetCameraSpeaker({ cameraId: camId, speakerValue: value });
  };

  /**
   * Handles setting of the mic volume for care device
   * @param {string} camId device ID
   * @param {number} value new value to set
   */
  const onSetCameraMic = (camId: string, value: number) => {
    cameraSettingsHandler.onSetCameraMic({ cameraId: camId, micValue: value });
  };

  return (
    <RoomDeviceSettingsView
      cameraId={cameraId}
      cameraSettings={cameraSettings}
      canFocus={!!cameraSettingsHandler.isCameraFocusAllowed}
      canAdjBright={!!cameraSettingsHandler.isAdjustBrightnessAllowed}
      onSetCameraMic={onSetCameraMic}
      onSetCameraSpeaker={onSetCameraSpeaker}
      onSetCameraFocus={onSetCameraFocus}
      onSetCameraSpeed={onSetCameraSpeed}
      onSetCameraBrightness={onSetCameraBrightness}
      onSetCameraBell={onSetCameraBell}
      cameraMetadata={cameraMetadata as unknown as CameraMetadata}
    />
  );
};
