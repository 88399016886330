import React, { FC, useState } from 'react';
import { CaregilityServiceAdapter } from '@adapters';
import { AudioVideoSettingsComponent } from '@components/AudioVideoSettings';
import { CameraControlsComponent } from '@components/CameraControls/CameraControlsComponent';
import ChatComponent from '@components/Chat/ChatComponent';
import { InvitePanel } from '@components/Invite/InvitePanel';
import { AdmitParticipantsPanel } from '@components/Participants/AdmitParticipantsPanel';
import { ParticipantsPanel } from '@components/Participants/ParticipantsPanel';
import { useChatHandlers } from '@hooks/useChatHandlers';
import { VideoView, ButtonTypes } from '@types';
import { LockedRoomNotification } from '@components/Participants/LockedRoomNotification';
import CaregilityVideoScene from './CaregilityVideoScene';
import ZoomMeetingClientViewVideoScene from './ZoomMeetingClientViewVideoScene';

export interface VideoSceneWrapperProps {
  adapter: CaregilityServiceAdapter;
  view: VideoView;
  showHeader?: boolean;
  configFooterButtons?: ButtonTypes[];
  joinCallToken?: string;
}
export const VideoSceneWrapper: FC<VideoSceneWrapperProps> = ({
  adapter,
  view,
  showHeader,
  configFooterButtons,
  joinCallToken,
}) => {
  const [showRoomLockedNotification] = useState<boolean>(!!adapter?.getSession()?.isLocked);
  const isChatActive = configFooterButtons?.includes('Chat');
  useChatHandlers(adapter, !!isChatActive);

  const renderVideoScene = () => {
    if (view === VideoView.pexip && adapter) {
      return (
        <CaregilityVideoScene
          adapter={adapter}
          configFooterButtons={configFooterButtons}
          cameraId={adapter.getCameraControlManager()?.getCameraId?.() || ''}
          showHeader={showHeader}
          joinCallToken={joinCallToken}
        />
      );
    }

    if (view === VideoView.zoom && adapter) {
      return (
        <ZoomMeetingClientViewVideoScene
          adapter={adapter}
          cameraId={adapter.getCameraControlManager()?.getCameraId?.() || ''}
          configFooterButtons={configFooterButtons}
          showHeader={showHeader}
          joinCallToken={joinCallToken}
        />
      );
    }

    return null;
  };

  return (
    <>
      {isChatActive && (<ChatComponent />)}
      {configFooterButtons?.includes('Settings') && (
        <AudioVideoSettingsComponent
          cameraId={adapter.getCameraControlManager()?.getCameraId?.() || ''}
        />
      )}
      {configFooterButtons?.includes('Camera Controls') && (
        <CameraControlsComponent />
      )}
      {configFooterButtons?.includes('Invite') && (
        <InvitePanel
          cameraId={adapter.getCameraControlManager()?.getCameraId?.() || ''}
        />
      )}
      {configFooterButtons?.includes('Participants') && (
        <ParticipantsPanel />
      )}
      {adapter.getSelfParticipant().sessionManager && (
        <AdmitParticipantsPanel />
      )}
      {showRoomLockedNotification && (
        <LockedRoomNotification />
      )}
      {renderVideoScene()}
    </>
  );
};

VideoSceneWrapper.defaultProps = {
  showHeader: false,
  configFooterButtons: [
    'Settings',
    'Participants',
    'Mic',
    'Video',
    'Patient on Hold',
    'Doorbell',
    'Self View',
    'Share Content',
    'Camera Controls',
    'Home',
    'Screen Shot',
    'Invite',
    'Chat',
  ],
  joinCallToken: '',
};
