import {
  CaregilityServiceAdapter,
  PexipServiceAdapter,
  ZoomMeetingClientViewSDKAdapter,
} from '@adapters';
import { CaregilitVideoUIConfig, VideoSDK } from '@types';

export class ServiceManager {
  readonly #config: CaregilitVideoUIConfig;

  #adapter!: CaregilityServiceAdapter;

  /**
   * @param config - CaregilitVideoUIConfig
   * @constructor
   */
  constructor(config: CaregilitVideoUIConfig) {
    this.#config = config;
    this.#createAdapter();
  }

  /**
   * Creates adapter based on provided SDK
   *
   * @private
   */
  #createAdapter(): void {
    switch (this.#config.sdk) {
      case VideoSDK.pexip:
        this.#adapter = new PexipServiceAdapter(this.#config);
        break;
      case VideoSDK.zoom:
        this.#adapter = new ZoomMeetingClientViewSDKAdapter(this.#config);
        break;
      case VideoSDK.msTeams:
        // TODO: To be implemented
        break;
      default:
        break;
    }
  }

  /**
   * Returns the SDK adapter
   */
  getAdapter() {
    return this.#adapter;
  }
}
