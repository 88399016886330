import React, { FC } from 'react';
import { List } from 'antd';
import { MovablePanel } from '@components/MovablePanel/MovablePanel';
import { Participant } from '@types';
import { Profile } from './Profile';
import '@assets/styles/custom-antd.scss';

export interface ParticipantsPanelProps {
  self: Participant;
  visible: boolean;
  onClose: () => void;
  participants: Participant[];
  isSessionLocked: boolean;
  onAddFavorite: (participant: Participant) => void;
  onAdmit: (participant: Participant) => void;
  onChat: (participant: Participant) => void;
  onDeny: (participant: Participant) => void;
  onMute: (participant: Participant) => void;
  onRemove: (participant: Participant) => void;
  onRemoveFavorite: (participant: Participant) => void;
  onSpotlight: (participant: Participant) => void;
  hasChatToken: (participant: Participant) => boolean;
}

/**
 * ParticipantsPanel Component
 * @param visible - flag to hide/show the panel
 * @param onClose - trigger when panel's X mark is clicked
 * @param participants - participants
 * @param self - self
 * @param isSessionLocked - isSessionLocked
 * @param onAddFavorite - handler
 * @param onAdmit - handler
 * @param onChat - handler
 * @param onDeny - handler
 * @param onMute - handler
 * @param onRemove - handler
 * @param onRemoveFavorite - handler
 * @param onSpotlight - handler
 * @param hasChatToken - boolean
 * @constructor
 */
export const ParticipantsPanelView: FC<ParticipantsPanelProps> = ({
  visible,
  onClose,
  participants,
  self,
  isSessionLocked,
  onAddFavorite,
  onAdmit,
  onChat,
  onDeny,
  onMute,
  onRemove,
  onRemoveFavorite,
  onSpotlight,
  hasChatToken,
}) => (
  <MovablePanel
    hidden={!visible}
    title="Participants"
    width={500}
    height={650}
    top={120}
    left={501}
    hasDivider
    onClose={onClose}
  >
    <div className="vl-align-center">
      <List
        className="vl-profiles-list"
        dataSource={participants}
        renderItem={(participant: Participant) => (
          <List.Item key={participant.id} className="vl-list-item">
            <Profile
              displayName={participant?.displayName}
              isFavorite={!!participant.isFavorite}
              isMuted={!!participant.isMuted}
              isPinned={!!participant.isPinned}
              isSelf={participant.id === self.id}
              isAdmin={!!self.sessionManager}
              isSessionLocked={isSessionLocked}
              isWaiting={!!participant.isWaiting}
              onAddFavorite={() => onAddFavorite(participant)}
              onAdmitClick={() => onAdmit(participant)}
              onChatClick={() => onChat(participant)}
              onDenyClick={() => onDeny(participant)}
              onMuteClick={() => onMute(participant)}
              onRemoveClick={() => onRemove(participant)}
              onRemoveFavorite={() => onRemoveFavorite(participant)}
              onSpotlightClick={() => onSpotlight(participant)}
              email={participant.email}
              facility={participant.facility}
              initials={participant.initials}
              organization={participant.organization}
              phoneNumber={participant.phoneNumber}
              pictureUrl={participant.pictureUrl}
              hasChatToken={() => hasChatToken(participant)}
            />
          </List.Item>
        )}
      />
    </div>
  </MovablePanel>
);
