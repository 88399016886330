import React, { FC } from 'react';
import { getOptions } from '@utils/helpers';
import { MovablePanel } from '@components';
import { Row } from 'antd';
import { UserDeviceSettingsComponent } from '../UserDeviceSettings';
import { RoomDeviceSettingsComponent } from '../RoomDeviceSettings';

export interface AudioVideoSettingsViewProps {
  settingsOn: boolean;
  HEADER_HEIGHT: number;
  onCloseSettings: () => void;
  onSelect: (selectedValue: string) => void;
  inCall: boolean;
  choices: string[];
  selected: string;
  SETTINGS_SCREENS: string[];
  cameraId: string;
}

/**
 * FC to visualize the audio video settings view.
 * @param settingsOn - boolean prop that determines is settings panel visible
 */
const AudioVideoSettingsView: FC<AudioVideoSettingsViewProps> = ({
  settingsOn,
  HEADER_HEIGHT,
  onCloseSettings,
  inCall,
  choices,
  onSelect,
  selected,
  SETTINGS_SCREENS,
  cameraId,
}) => (
  <MovablePanel
    hidden={!settingsOn}
    title="Audio and Video Settings"
    width={455}
    height={440}
    top={window.innerHeight - HEADER_HEIGHT - 400}
    left={1}
    expandable={false}
    onClose={onCloseSettings}
  >
    <>
      {inCall && (
        <div>
          <Row className="vl-invite-tabs">
            {getOptions(choices).map((tab: any) => (
              <div
                onClick={() => onSelect(tab.value)}
                key={tab.value}
                className={`${selected === tab.label ? 'selected' : ''}`}
              >
                {tab.label}
              </div>
            ))}
          </Row>
        </div>
      )}
      {selected === SETTINGS_SCREENS[0] && (
        <UserDeviceSettingsComponent />
      )}
      {selected === SETTINGS_SCREENS[1] && (
        <RoomDeviceSettingsComponent
          cameraId={cameraId}
        />
      )}
    </>
  </MovablePanel>
);

export default AudioVideoSettingsView;
