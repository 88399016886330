/* eslint-disable react/no-unstable-nested-components */
import React, { FC } from 'react';
import { Col } from 'antd';
import MicOff from '@assets/images/footer/mic_off.svg';
import MicOn from '@assets/images/footer/mic_on.svg';
import VideoOff from '@assets/images/footer/video_off.svg';
import VideoOn from '@assets/images/footer/video_on.svg';
import SelfViewBtn from '@assets/images/footer/self_view_btn.svg';
import PatientOnHoldBtn from '@assets/images/footer/POH_btn.svg';
import DoorbellOn from '@assets/images/footer/doorbell_on.svg';
import DoorbellOff from '@assets/images/footer/doorbell_off.svg';
import ScreenshotBtn from '@assets/images/footer/screenshot_btn.svg';
import ShareContentBtn from '@assets/images/footer/share_content_btn.svg';
import ParticipantsBtn from '@assets/images/footer/participants_btn.svg';
import CameraControlsBtn from '@assets/images/footer/camera_pins.svg';
import HomeBtn from '@assets/images/footer/home_btn.svg';
import SettingsBtn from '@assets/images/footer/settings_btn.svg';
import InviteBtn from '@assets/images/footer/invite_btn.svg';
import ChatBtn from '@assets/images/footer/chat_btn.svg';

import { ButtonTypes } from '@types';
import { Button } from '../Button/Button';

export interface UserControlsViewProps {
  cameraId?: string;
  audioOn?: boolean;
  videoOn?: boolean;
  patientOnHoldOn?: boolean;
  selfViewOn?: boolean;
  doorbellOn?: boolean;
  screenShotOn?: boolean;
  screenShareOn?: boolean;
  participantsOn?: boolean;
  settingsOn?: boolean;
  inviteOn?: boolean;
  chatOn?: boolean;
  unseenChatMsgs?: number;
  endSessionOn?: boolean;
  cameraControlsOn?: boolean;
  homeOn?: boolean;
  onMicClick?: () => void;
  onVideoClick?: () => void;
  onPatientOnHoldClick?: () => void;
  onSelfViewClick?: () => void;
  onDoorbellClick?: () => void;
  onScreenshotClick?: () => void;
  onShareContentClick?: () => void;
  onParticipantsClick?: () => void;
  onSettingsClick?: () => void;
  onInviteClick?: () => void;
  onChatClick?: () => void;
  onEndSessionClick?: () => void;
  onCameraControlsClick?: () => void;
  onGoHomeClick?: () => void;
  onSetHomeClick?: () => void;
  configHeaderButtons?: ButtonTypes[];
  configFooterButtons?: ButtonTypes[];
}

/**
 * UserControlsView - view for buttons configuration
 *
 * @param props - UserControlsViewProps
 */
export const UserControlsView: FC<UserControlsViewProps> = (props) => {
  const {
    cameraId,
    audioOn,
    onMicClick,
    patientOnHoldOn,
    onPatientOnHoldClick,
    videoOn,
    onVideoClick,
    selfViewOn,
    onSelfViewClick,
    doorbellOn,
    onDoorbellClick,
    screenShotOn,
    onScreenshotClick,
    screenShareOn,
    onShareContentClick,
    participantsOn,
    onParticipantsClick,
    settingsOn,
    onSettingsClick,
    inviteOn,
    onInviteClick,
    chatOn,
    unseenChatMsgs,
    onChatClick,
    endSessionOn,
    onEndSessionClick,
    cameraControlsOn,
    onCameraControlsClick,
    homeOn,
    onGoHomeClick,
    onSetHomeClick,
    configHeaderButtons,
    configFooterButtons,
  } = props;

  return (
    <>
      <Col className="vl-left-section">
        <Button
          type="button"
          text="Settings"
          isOn={settingsOn}
          onClick={onSettingsClick}
          src={SettingsBtn}
          visible={configFooterButtons?.includes('Settings')}
        />
        <Button
          type="button"
          text="Invite"
          isOn={inviteOn}
          onClick={onInviteClick}
          src={InviteBtn}
          visible={configFooterButtons?.includes('Invite')}
        />
        <Button
          type="button"
          text="Chat"
          isOn={chatOn}
          onClick={onChatClick}
          src={ChatBtn}
          visible={configFooterButtons?.includes('Chat')}
          number={unseenChatMsgs}
        />
        <Button
          type="button"
          text="Participants"
          isOn={participantsOn}
          onClick={onParticipantsClick}
          src={ParticipantsBtn}
          visible={configFooterButtons?.includes('Participants')}
        />
      </Col>
      <Col className="vl-middle-section">
        {cameraId !== '' && (
          <Button
            type="redButton"
            text="Patient on Hold"
            isOn={patientOnHoldOn}
            onClick={onPatientOnHoldClick}
            src={PatientOnHoldBtn}
            visible={configFooterButtons?.includes('Patient on Hold')}
          />
        )}
        <Button
          type="dualButton"
          text="Mic"
          textOn="Mic"
          textOff="Mic OFF"
          isOn={audioOn}
          onClick={onMicClick}
          srcOn={MicOn}
          srcOff={MicOff}
          visible={configFooterButtons?.includes('Mic')}
        />
        <Button
          type="dualButton"
          text="Video"
          textOn="Video"
          textOff="Video OFF"
          isOn={videoOn}
          onClick={onVideoClick}
          srcOn={VideoOn}
          srcOff={VideoOff}
          visible={configFooterButtons?.includes('Video')}
        />
        <Button
          type="button"
          text="Self View"
          isOn={selfViewOn}
          onClick={onSelfViewClick}
          src={SelfViewBtn}
          visible={configFooterButtons?.includes('Self View')}
        />
        {cameraId !== ''
        && (
        <Button
          type="dualButton"
          text="Doorbell"
          textOn="Doorbell"
          textOff="Doorbell OFF"
          isOn={doorbellOn}
          onClick={onDoorbellClick}
          srcOn={DoorbellOn}
          srcOff={DoorbellOff}
          visible={configFooterButtons?.includes('Doorbell')}
        />
        )}
      </Col>
      <Col className="vl-right-section">
        <Button
          type="button"
          text="Camera Controls"
          isOn={cameraControlsOn}
          onClick={onCameraControlsClick}
          src={CameraControlsBtn}
          visible={configFooterButtons?.includes('Camera Controls')}
        />
        <Button
          type="homeButton"
          text="Home"
          isOn={homeOn}
          onGoHomeClick={onGoHomeClick}
          onSetHomeClick={onSetHomeClick}
          src={HomeBtn}
          visible={configFooterButtons?.includes('Home')}
        />
        <Button
          type="button"
          text="Share Content"
          isOn={screenShareOn}
          onClick={onShareContentClick}
          src={ShareContentBtn}
          visible={configFooterButtons?.includes('Share Content')}
        />
        <Button
          type="marginButton"
          text="Screen Shot"
          isOn={screenShotOn}
          onClick={onScreenshotClick}
          src={ScreenshotBtn}
          visible={configFooterButtons?.includes('Screen Shot')}
        />
        <Button
          className="mr-50 vl-btn vl-btn-red vl-btn-text"
          type="button"
          text="End Session"
          isOn={endSessionOn}
          onClick={onEndSessionClick}
          visible={configHeaderButtons?.includes('End Session')}
        />
      </Col>
    </>
  );
};

UserControlsView.defaultProps = {
  cameraId: '',
  audioOn: false,
  videoOn: false,
  patientOnHoldOn: false,
  selfViewOn: false,
  doorbellOn: false,
  screenShotOn: false,
  screenShareOn: false,
  participantsOn: false,
  settingsOn: false,
  inviteOn: false,
  chatOn: false,
  unseenChatMsgs: 0,
  endSessionOn: false,
  homeOn: false,
  cameraControlsOn: false,
  onMicClick: () => { },
  onVideoClick: () => { },
  onPatientOnHoldClick: () => { },
  onSelfViewClick: () => { },
  onDoorbellClick: () => { },
  onScreenshotClick: () => { },
  onShareContentClick: () => { },
  onParticipantsClick: () => { },
  onSettingsClick: () => { },
  onInviteClick: () => { },
  onChatClick: () => { },
  onEndSessionClick: () => { },
  onCameraControlsClick: () => { },
  onGoHomeClick: () => { },
  onSetHomeClick: () => { },
  configHeaderButtons: [],
  configFooterButtons: [],
};
