/* eslint-disable no-nested-ternary */
import React, {
  FC, useCallback, useEffect, useState, useRef,
} from 'react';
import {
  EventManager, VideoScene, VideoSDK, VideoView,
  ZoomMeetConfig, PexipConfig, CaregilitySession, ButtonTypes,
  ServiceParticipant, DeviceType,
} from '../../../src';
import { useConfig } from '../hooks';

export interface DemoProps {
  callData?: CaregilitySession;
  callToken?: string;
}

interface VideoSceneRef {
  disconnectCall?: () => void;
}

const configFooterButtons: ButtonTypes[] = [
  'Settings',
  'Participants',
  'Mic',
  'Video',
  'Patient on Hold',
  'Doorbell',
  'Self View',
  'Share Content',
  'Camera Controls',
  'Home',
  'Screen Shot',
  'Invite',
  'Chat',
  'End Session',
];

export const Demo: FC<DemoProps> = ({ callData, callToken }) => {
  const [showVideoScene, setVideoScene] = useState<boolean>(false);
  const [videoSDK, setVideoSDK] = useState<VideoSDK>(VideoSDK.pexip);
  const [view, setView] = useState<VideoView>(VideoView.pexip);
  const [zoomConfig, setZoomConfig] = useState<ZoomMeetConfig | undefined>(undefined);
  const [pexipConfig, setPexipConfig] = useState<PexipConfig | undefined>(undefined);
  const {
    caregilitySession, setCaregilitySession, getEndpointsConfig,
    accessToken, deviceID, environment, handleTokenChange,
    sessionId, setSessionId, handledeviceIDChange, handleEnvironmentChange,
    getPexipServerConfig,
  } = useConfig('env2');
  const libRef = useRef<VideoSceneRef>({});

  useEffect(() => {
    /**
     * Handle before unload event
     *
     * @param e - event
     */
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();

      if (libRef.current.disconnectCall) {
        libRef.current.disconnectCall();
      }

      e.returnValue = '';

      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });

      return e;
    };

    if (callToken) {
      window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    };
  }, []);

  const parseJwt = (token: string) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('')
        .map((decodedData) => `%${(`00${decodedData.charCodeAt(0).toString(16)}`).slice(-2)}`)
        .join(''));
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  const parseSession = (data: CaregilitySession) => {
    if (data.media_provider === 'ZOOM') {
      const tokenInfo = parseJwt(data.mp_token);

      setVideoSDK(VideoSDK.zoom);
      setView(VideoView.zoom);

      setZoomConfig({
        sdkKey: tokenInfo.sdkKey,
        signature: data.mp_token,
        meetingNumber: data.vmr_alias,
        zoomMeetingPassword: data.pin,
        userName: data?.participant?.display_name || 'ZoomMeetDemo',
        leaveUrl: window.location.href,
      });
    } else {
      setVideoSDK(VideoSDK.pexip);
      setView(VideoView.pexip);

      setPexipConfig({
        ...getPexipServerConfig(),
        conferenceVmr: data.vmr_alias,
        name: data?.participant?.display_name || 'PexipDemo',
      });
    }

    setSessionId(data.id);
    setCaregilitySession(data);
  };
  const joinMeeting = () => setVideoScene(true);

  const cleanup = useCallback((param: boolean | CustomEvent<boolean>): void => {
    if (typeof param === 'boolean' && param) {
      setVideoScene(false);
      setCaregilitySession(null);
      setSessionId(0);
      setVideoSDK(VideoSDK.zoom);
      setView(VideoView.zoom);
      setZoomConfig(undefined);
      setPexipConfig(undefined);
    } else {
      window.location.reload();
    }
  }, []);

  const logServiceParticipantCreate = (event: CustomEvent<ServiceParticipant>) => {
    console.log('[CVL] logServiceParticipantCreate', event.detail);
  };

  const logServiceParticipantUpdate = (event: CustomEvent<ServiceParticipant>) => {
    console.log('[CVL] logServiceParticipantUpdate', event.detail);
  };

  const logServiceParticipantDelete = (event: CustomEvent<ServiceParticipant>) => {
    console.log('[CVL] logServiceParticipantDelete', event.detail);
  };

  const onNewSessionHandler = (deviceId?: number, deviceType?: DeviceType) => {
    console.log('[CVL] onNewSessionHandler', deviceId, deviceType);
  };

  useEffect(() => {
    EventManager.subscribe('onCallEnd', cleanup);
    EventManager.subscribe('onServiceParticipantCreate', logServiceParticipantCreate);
    EventManager.subscribe('onServiceParticipantUpdate', logServiceParticipantUpdate);
    EventManager.subscribe('onServiceParticipantDelete', logServiceParticipantDelete);

    return () => {
      EventManager.unsubscribe('onCallEnd', cleanup);
      EventManager.unsubscribe('onServiceParticipantCreate', logServiceParticipantCreate);
      EventManager.unsubscribe('onServiceParticipantUpdate', logServiceParticipantUpdate);
      EventManager.unsubscribe('onServiceParticipantDelete', logServiceParticipantDelete);
    };
  }, []);

  useEffect(() => {
    if (callData?.id) {
      parseSession(callData);
      joinMeeting();
    }
  }, []);

  const disconnectAPS = (sid?: string) => {
    fetch(`/${environment}/session/control/end`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        session_id: sid || sessionId,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        cleanup(true);
      })
      .catch(console.error);
  };

  const generateMeeting = () => {
    fetch(`/${environment}/session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        device_id: deviceID,
        device_type: 'CARE_DEVICE',
        allow_concurrent_session: true,
      }),
    })
      .then((response) => response.json())
      .then((data: CaregilitySession) => {
        if (data?.error && data?.session_id) {
          disconnectAPS(data.session_id);
        } else {
          parseSession(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="demo-root">
      {(!callData?.id && !showVideoScene) && (
        <>
          <div className="demo-input">
            <label htmlFor="environment">
              <select
                name="environment"
                onChange={handleEnvironmentChange}
                value={environment}
              >
                <option value="env2">env2</option>
                <option value="env3">env3</option>
                <option value="env7">env7</option>
              </select>
              Environment
            </label>
            <label htmlFor="deviceID">
              <input
                name="deviceID"
                type="text"
                onChange={handledeviceIDChange}
                value={deviceID}
              />
              Device ID
            </label>
            <label htmlFor="accessToken">
              <input
                name="accessToken"
                type="text"
                onChange={handleTokenChange}
                value={accessToken}
              />
              Access Token
            </label>
          </div>
          <div className="demo-buttons">
            {!caregilitySession
              && (
              <button
                type="button"
                onClick={generateMeeting}
              >
                Create Meeting
              </button>
              )}
          </div>
          <div className="demo-buttons">
            {caregilitySession && !showVideoScene
              && (
                <button
                  type="button"
                  onClick={joinMeeting}
                >
                  Join Meeting
                </button>
              )}
          </div>
        </>
      )}
      <div className={`${view === 'zoom' ? 'zoom-demo-component' : 'pexip-demo-component'}`}>
        {showVideoScene && caregilitySession
          && (
            <VideoScene
              ref={libRef}
              sdk={videoSDK}
              view={view}
              zoomMeetConfig={zoomConfig}
              pexipConfig={pexipConfig}
              sessionInfo={caregilitySession}
              endpoints={getEndpointsConfig()}
              accessToken={accessToken}
              joinCallToken={callToken}
              configFooterButtons={!callToken
                ? view === 'zoom'
                  ? undefined
                  : configFooterButtons
                : []}
              showHeader={!callToken}
              onStartNewSession={onNewSessionHandler}
            />
          )}
      </div>
    </div>
  );
};

Demo.defaultProps = {
  callData: undefined,
  callToken: '',
};
