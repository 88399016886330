import React, { FC } from 'react';
import { MovablePanel } from '@components/MovablePanel/MovablePanel';
import { LoadingOutlined } from '@ant-design/icons';

/**
 * Props for the ScreenShotView component.
 * @property {() => void} makeScreenshot - Makes a screenshot of an element and sets it as the source of an image.
 * @property {(value: boolean) => void} setScreenshotOn - Sets the state of screenshot to on/off.
 * @property {() => void} downloadImage - Downloads an image with the given source URL and specified name.
 * @property {string | null} screenshotImgSrc - The source URL of the screenshot image.
 */
export interface ScreenShotViewProps {
  makeScreenshot: () => void;
  setScreenshotOn: (value: boolean) => void;
  downloadImage: () => void;
  screenshotImgSrc: string | null;
}

/**
 * A component that displays a screenshot of an element.
 * The component displays a movable panel with a title of "Screenshot".
 * It includes functions for making a screenshot, setting the state of screenshot to on/off,
 * downloading the image, and displaying the image or a loading indicator.
 * @param {ScreenShotViewProps} props - The props for the component.
 * @returns {JSX.Element}
 */
export const ScreenShotView: FC<ScreenShotViewProps> = ({
  makeScreenshot,
  setScreenshotOn,
  downloadImage,
  screenshotImgSrc,
}) => (
  <MovablePanel
    title="Screenshot"
    width={520}
    height={363}
    top={405}
    left={1}
    downloadable
    expandable
    reloadable
    hasDivider
    isClosable
    onReload={makeScreenshot}
    onClose={() => setScreenshotOn(false)}
    downloadImage={downloadImage}
  >

    {screenshotImgSrc ? (
      <div className="vl-screenshot">
        <img
          id="video-screenshot"
          alt="Video screenshot"
          src={screenshotImgSrc}
        />
      </div>
    ) : (
      <div className="vl-loading">
        Loading...
        <LoadingOutlined />
      </div>
    )}

  </MovablePanel>
);
