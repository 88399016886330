import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { Participant } from '@types';
import {
  Button, Col, Modal, Row,
} from 'antd';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import warningSvg from '@assets/images/warning.svg';

/**
 * A popup panel notifying an user is attmpting to join a locked room.
 * Containes options to admit or deny him. The panel could be closed with no
 * side effects as well.
 */
export const AdmitParticipantsPanel: FC = () => {
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const [waitingPart, setWaitingPart] = useState<Participant | null>();
  const closedParticipants = useRef<string[]>([]);

  /**
   * loops through all participants and searches for those who are waiting
   * @param participants all participants
   */
  const extractWaitingParticipant = (participants: Participant[]) => {
    const wPart = participants.find(
      (part: Participant) => part.isWaiting && !closedParticipants.current.includes(part.id!.toString()),
    );
    setWaitingPart(wPart);
  };

  /**
   * Triggers admit participant event
   * @param participant the participant
   */
  const admitParticipant = (participant: Participant) => {
    adapter?.getSessionControlManager()?.admitParticipant(participant);
  };
  /**
   * Triggers deny participant event
   * @param participant the participant
   */
  const denyParticipant = (participant: Participant) => {
    adapter?.getSessionControlManager()?.denyParticipant(participant);
  };

  /**
   * Closes the notification panel for certain participant forever.
   * Next checks for next waiting participant if any.
   * @param participant the participant
   */
  const closePanel = (participant: Participant) => {
    closedParticipants.current.push(participant.id!.toString());
    extractWaitingParticipant(adapter!.getAllParticipants());
  };

  useEffect(() => {
    if (adapter) {
      const participantsListener = (participants: Participant[]) => {
        extractWaitingParticipant(participants);
      };
      adapter.on(EventNames.onParticipantsChange, participantsListener);
      return () => {
        adapter.off(EventNames.onParticipantsChange, participantsListener);
      };
    }
    return () => {};
  }, [adapter]);

  if (waitingPart) {
    return (
      <Modal
        title={(
          <div className="modal-title">
            <span>Admit Participants</span>
          </div>
        )}
        visible
        className="header-modal"
        maskClosable={false}
        mask={false}
        centered
        destroyOnClose
        footer={null}
        width={500}
        onCancel={() => closePanel(waitingPart)}
      >
        <div className="modal-content">
          <img alt="" src={warningSvg} />
          <span className="modal-info">
            {`${waitingPart.displayName} is waiting to be admitted.`}
          </span>
        </div>
        <Row className="buttons">
          <Col span={24}>
            <Button
              className="btn btn-grey"
              htmlType="button"
              type="default"
              onClick={() => denyParticipant(waitingPart)}
            >
              Deny
            </Button>
            <Button
              className="btn btn-blue ml20"
              htmlType="button"
              type="primary"
              onClick={() => admitParticipant(waitingPart)}
            >
              Admit
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
  return null;
};
