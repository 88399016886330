import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ScreenshareOn, ScreenshotOn, SelfViewOn, VideoOn,
} from '@atoms/VideoButtons';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { VideoComponentProps } from '../VideoComponent';

/**
 * Hook to handle interactions with the streams
 *
 * @param adapter - CaregilityServiceAdapter
 * @param speakerVolume - volume
 * @param speakerDeviceId - deviceId
 */
export const useVideoHandlers: (
  adapter: CaregilityServiceAdapter,
  speakerVolume: number | undefined,
  speakerDeviceId: string | undefined
) => { componentProps: VideoComponentProps, makeCall: () => void } = (
  adapter,
  speakerVolume,
  speakerDeviceId,
) => {
  const [cameraVideoStream, setCameraVideoStream] = useState<MediaStream | null>(null);
  const [presentationVideoStream, setPresentationVideoStream] = useState<MediaStream | null>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [screenshareVideoStream, setScreenshareVideoStream] = useState<MediaStream | null>(null);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isPresentationOn, setIsPresentationOn] = useState<boolean>(false);
  const setScreenshareOn = useSetRecoilState(ScreenshareOn);
  const cameraControl = adapter.getCameraControlManager();

  /**
   * Inits local camera
   *
   */
  const initCamera = () => {
    const localStream = adapter.getLocalStream();

    if (localStream) {
      const videoTracks = localStream.getVideoTracks();

      if (videoTracks.length > 0) {
        const deviceId = videoTracks[0].getSettings().deviceId ?? '';

        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: { deviceId: { exact: deviceId } },
          })
          .then((stream: MediaStream) => {
            setCameraVideoStream(stream);
          })
          .catch((e) => {
            console.error('Camera error in video initCamera', e);
          });
      }
    }
  };

  /**
   * Setup hook
   */
  useEffect(() => {
    adapter.on(EventNames.onConnect, (stream: MediaStream) => {
      setIsConnecting(false);
      setVideoStream(stream);
      initCamera();
    });
    adapter.on(EventNames.onSelfScreenshareConnected, (stream: MediaStream) => {
      setScreenshareVideoStream(stream);
      setScreenshareOn(true);
    });
    adapter.on(EventNames.onSelfScreenshareDisconnected, () => {
      setScreenshareVideoStream(null);
      setScreenshareOn(false);
    });
    adapter.on(EventNames.onScreenShare, (settings: boolean) => {
      setIsPresentationOn(settings);
    });
    adapter.on(EventNames.onScreenShareConnected, (stream: MediaStream) => {
      setPresentationVideoStream(stream);
    });
    adapter.on(EventNames.onScreenShareDisconnected, () => {
      setPresentationVideoStream(null);
    });
  }, [adapter]);
  const isSelfViewOn = useRecoilValue(SelfViewOn);
  const isVideoOn = useRecoilValue(VideoOn);
  const [isScreenshotOn, setScreenshotOn] = useRecoilState(ScreenshotOn);

  // the following useEffect is a temp patch
  // problem: pexip.muteVideo doesn't stop the camera stream (ref: useVideoButtonsHandler)
  useEffect(() => {
    if (isVideoOn) {
      initCamera();
    } else {
      setCameraVideoStream(null);
    }
  }, [isVideoOn]);

  const onCloseScreenshareClick = () => {
    adapter.handleScreenShare(undefined);
  };

  let cameraGoToPoint;
  let cameraZoomOut;

  if (cameraControl?.isCameraMouseControllersAllowed()) {
    cameraGoToPoint = cameraControl.cameraGoToPoint;
    cameraZoomOut = cameraControl.cameraZoomOut;
  }

  return {
    componentProps: {
      cameraVideoStream,
      isConnecting,
      isScreenshotOn,
      isSelfViewOn,
      presentationVideoStream,
      screenshareVideoStream,
      videoStream,
      speakerDeviceId,
      speakerVolume,
      isPresentationOn,
      closeScreenshot: () => setScreenshotOn(false),
      onCloseScreenshareClick,
      cameraGoToPoint,
      cameraZoomOut,
    } as VideoComponentProps,
    makeCall: () => {
      setIsConnecting(true);
      adapter.startCall();
    },
  };
};
