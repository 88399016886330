import React, { FC, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { SettingsOn } from '@atoms/VideoButtons';
import AudioVideoSettingsView from './AudioVideoSettingsView';
import '@assets/styles/movablepanel.scss';
import '@assets/styles/custom-antd.scss';

export interface AudioVideoSettingsComponentProps {
  cameraId: string;
}

/**
 * AudioVideoSettingsComponent - component for audio and video settings
 *
 * @param {AudioVideoSettingsComponentProps} AudioVideoSettingsComponentProps AudioVideoSettingsComponentProps
 */
export const AudioVideoSettingsComponent: FC<AudioVideoSettingsComponentProps> = ({
  cameraId,
}) => {
  const SETTINGS_SCREENS = ['My Device', 'Care/Room Device'];
  const HEADER_HEIGHT = 70;
  const [settingsOn, switchSettingsOn] = useRecoilState(SettingsOn);
  const [selected, setSelected] = useState(SETTINGS_SCREENS[0]);
  const [callData, setCallData] = useState({
    inCall: cameraId.length > 0,
  });
  const choices = callData ? SETTINGS_SCREENS : [SETTINGS_SCREENS[0]];
  const onSelect = (selectedValue: string) => setSelected(selectedValue);

  useEffect(() => {
    if (callData && callData.inCall && cameraId.length === 0) {
      setSelected(SETTINGS_SCREENS[0]);
      setCallData({
        inCall: false,
      });
    }
  }, [cameraId.length, callData]);

  return (
    <AudioVideoSettingsView
      settingsOn={settingsOn}
      HEADER_HEIGHT={HEADER_HEIGHT}
      onCloseSettings={() => switchSettingsOn(false)}
      inCall={callData.inCall}
      choices={choices}
      onSelect={onSelect}
      selected={selected}
      SETTINGS_SCREENS={SETTINGS_SCREENS}
      cameraId={cameraId}
    />
  );
};
