export const defaultPagination = {
  size: 0,
  totalElements: 0,
  totalPages: 0,
  number: 0,
};

/**
 * Checks of the provided element is in the viewport
 *
 * @param el - the element
 * @param translateX - translateX value
 * @param translateY - translateY value
 * @param ignoreBottomRight - ignores bottom right
 */
export const isElementInViewport = (
  el: Element,
  translateX = 0,
  translateY = 0,
  ignoreBottomRight = false,
): boolean => {
  const rect = el.getBoundingClientRect();
  const result = rect.top + translateY >= 0 && rect.left + translateX >= 0;

  if (ignoreBottomRight) {
    return result;
  }

  return (
    result
      && rect.bottom + translateY
      <= (window.innerHeight
        || document.documentElement
          .clientHeight)
      && /* or $(window).height() */ rect.right + translateX
      <= (window.innerWidth || document.documentElement.clientWidth)
  ); /* or $(window).width() */
};

/**
 * Removes duplicate entries in a string array or empty array
 *
 * @param {string[] | undefined} data data to be processed
 */
function removeDuplicates(data: string[] | undefined) {
  if (data) {
    return data.filter((value, index) => data.indexOf(value) === index);
  }
  return [];
}

/**
 * Creates options array
 *
 * @param {string[] | undefined} data data to be processed
 */
export function getOptions(options: string[] | undefined) {
  if (options) {
    const filtered = removeDuplicates(options);
    const result: { label: string; value: string }[] = [];
    filtered.forEach((item) => {
      result.push({ label: item, value: item });
    });
    return result;
  }
  return [];
}

/**
 * Extracts x/y point from mouse event
 * @param target - HTMLElement
 * @param pageX - element's pageX prop
 * @param pageY - element's pageY prop
 * @param clientX - element's clientX prop
 * @param clientY - element's clientY prop
 * @returns x/y point
 */
export const calculatePoint: (
  target?: HTMLElement,
  pageX?: number,
  pageY?: number,
  clientX?: number,
  clientY?: number,) =>
{
  x: number, y: number
} | null = (
  target,
  pageX,
  pageY,
  clientX,
  clientY,
) => {
  if (!target) {
    return null;
  }
  let x = 0;
  let y = 0;

  if (pageX && pageY) {
    x = pageX;
    y = pageY;
  } else if (clientX && clientY) {
    x = clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
    y = clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  const frameInfo = target.getBoundingClientRect();
  const frameMidWidth = frameInfo.width / 2;
  const frameMidHeight = frameInfo.height / 2;

  x = (x - frameInfo.left - frameMidWidth) / frameMidWidth;
  y = (frameMidHeight - (y - frameInfo.top)) / frameMidHeight;
  return { x, y };
};

/**
 * Gets element by its title
 *
 * @param element
 */
export const getElementTitle = (element: HTMLElement) => {
  let result = element.attributes?.getNamedItem('title')?.value;

  if (!result) {
    result = '';
  }

  return result;
};

/**
 * Zoom buttons consists of a div element containing the svg icon and
 * a span element containing the label text:
 * <button><div><svg /></div><span>[label-text]</span></button>
 * @param btn - rendered by zoom sdk button
 * @returns - button's label
 */
export const getZoomClientViewButtonLabel = (btn: HTMLButtonElement) => {
  const spans = btn.getElementsByTagName('span');
  if (spans && spans.length === 1 && spans[0].textContent) {
    return spans[0].textContent.toLocaleLowerCase();
  }
  return '';
};

/**
 * A function to check certain condition several times with some time delay between each check (tick).
 * @param condition -  the condition to be met
 * @param attempts - number of ticks
 * @param onSuccess - cb to be executed when the condition is met
 * @param onFail - cb to be executed when the waitTime * attempts time pass with no effect
 * @param waitTime - the time interval between each tick
 */
export const checkCondition = (
  condition: () => boolean,
  attempts: number,
  onSuccess: () => void,
  onFail: () => void,
  waitTime: number,
) => {
  if (condition()) {
    onSuccess();
  } else if (attempts > 0) {
    setTimeout(() => checkCondition(condition, attempts - 1, onSuccess, onFail, waitTime), waitTime);
  } else {
    onFail();
  }
};

/**
 * Returns a debounced version of the provided function that delays calling the original function
 * by the specified time (in milliseconds)
 * @param {Function} func
 * @param {Number} wait
 * @param {Boolean} immediate
 */
export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number,
  immediate: boolean,
): ((...args: Parameters<T>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  return (...args: Parameters<T>) => {
    const callNow = immediate && !timeout;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;

      if (!immediate) {
        func.apply(this, args);
      }
    }, wait);

    if (callNow) {
      func.apply(this, args);
    }
  };
};
