import {
  Input, InputRef, List, Spin, Tooltip,
} from 'antd';
import React, { FC } from 'react';
import { CameraBookmarkModel } from '@types';
import { MovablePanel } from '@components/MovablePanel/MovablePanel';
import Controller from '../../assets/images/panels/care_device_controller.svg';
import ControllerOver from '../../assets/images/panels/care_device_controller_over.svg';
import CameraHome from '../../assets/images/panels/care_device_home.svg';
import ZoomIn from '../../assets/images/panels/care_device_zoom_in.svg';
import ZoomInOver from '../../assets/images/panels/care_device_zoom_in_over.svg';
import ZoomOut from '../../assets/images/panels/care_device_zoom_out.svg';
import ZoomOutOver from '../../assets/images/panels/care_device_zoom_out_over.svg';
import Pin from '../../assets/images/panels/care_device_pin.svg';
import Edit from '../../assets/images/panels/care_device_edit.svg';
import Delete from '../../assets/images/panels/care_device_delete.svg';

export interface CameraControlsViewProps {
  visible: boolean;
  loading: boolean;
  setNavigationImgRef: (img: HTMLImageElement) => void;
  onNavigationMouseDown: (event: React.MouseEvent) => void;
  onNavigationMouseLeave: (event: React.MouseEvent) => void;
  onCameraHomeClick: () => void;
  onSetHomeClick: () => void;
  onZoomOutMouseDown: (event: React.MouseEvent) => void;
  onZoomOutMouseLeave: (event: React.MouseEvent) => void;
  onZoomInMouseDown: (event: React.MouseEvent) => void;
  onZoomInMouseLeave: (event: React.MouseEvent) => void;
  onRefreshClick: () => void;
  showReboot: boolean;
  onRebootClick: () => void;
  setPinInputRef: (input: InputRef) => void;
  pinInputValue: string,
  onPinInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onPinInputClick: () => void,
  onPinInputEnterKey: () => void,
  bookmarks: CameraBookmarkModel[],
  onBookmarkClick: (bookmark: CameraBookmarkModel) => void,
  onBookmarkUpdate: (bookmark: CameraBookmarkModel) => void,
  onBookmarkDelete: (bookmark: CameraBookmarkModel) => void,
  onClose: () => void,
}

/**
 * View for Camera Controls
 * @param visible boolean prop to control visibility of the panel
 * @param onClose trigger when panel's X mark is clicked
 * @param loading indicates if camera controls are still loading
 * @param setNavigationImgRef callback to set navigation image ref
 * @param onNavigationMouseDown handler for navigation mouse down event
 * @param onNavigationMouseLeave handler for navigation image mouse up and mouse down event
 * @param onCameraHomeClick handler for camera home click event
 * @param onSetHomeClick handler for set camera home action
 * @param onZoomOutMouseDown handler for zoom out button mouse down event
 * @param onZoomOutMouseLeave handler for zoom out button mouse up and mouse leave event
 * @param onZoomInMouseDown handler for zoom in button mouse down event
 * @param onZoomInMouseLeave handler for zoom in button mouse up and mouse leave event
 * @param onRefreshClick handler for camera refresh click event
 * @param showReboot flag to indicate if camera reboot is allowed
 * @param onRebootClick handler for camera reboot click event
 * @param setPinInputRef callback to set pin input ref
 * @param pinInputValue current pin input value
 * @param onPinInputChange handler for pin input change events
 * @param onPinInputClick handler for pin input click events
 * @param onPinInputEnterKey handler for pin input 'Enter' key event
 * @param bookmarks all bookmarks
 * @param onBookmarkClick handler for bookmark click events
 * @param onBookmarkDelete handler for bookmark delete click events
 * @param onBookmarkUpdate handler for bookmark update click events
 * @returns CameraControlsView
 */
export const CameraControlsView: FC<CameraControlsViewProps> = ({
  visible,
  onClose,
  loading,
  setNavigationImgRef,
  onNavigationMouseDown,
  onNavigationMouseLeave,
  onCameraHomeClick,
  onSetHomeClick,
  onZoomOutMouseDown,
  onZoomOutMouseLeave,
  onZoomInMouseDown,
  onZoomInMouseLeave,
  onRefreshClick,
  showReboot,
  onRebootClick,
  setPinInputRef,
  pinInputValue,
  onPinInputChange,
  onPinInputClick,
  onPinInputEnterKey,
  bookmarks,
  onBookmarkClick,
  onBookmarkUpdate,
  onBookmarkDelete,
}) => (
  <MovablePanel
    hidden={!visible}
    title="Care Device"
    width={455}
    height={315}
    top={window.innerHeight - 315 - 20} // position in bottom right corner
    left={window.innerWidth - 455 - 20}
    hasDivider
    onClose={onClose}
  >
    {loading && (
      <div className="vl-video-spin">
        <Spin />
      </div>
    )}
    {!loading && (
      <div>
        <div className="vl-care-div-left">
          <div className="vl-controller-block">
            <img
              alt=""
              src={Controller}
              className="vl-controller-img"
              style={{
                height: 'unset',
              }}
              onMouseOver={(e) => { e.currentTarget.src = ControllerOver; }}
              onFocus={() => {}}
              onMouseOut={(e) => { e.currentTarget.src = Controller; }}
              onBlur={() => {}}
              ref={setNavigationImgRef}
              onMouseDown={onNavigationMouseDown}
              onMouseLeave={onNavigationMouseLeave}
              onMouseUp={onNavigationMouseLeave}
            />
            <img
              alt=""
              src={CameraHome}
              className="vl-home-img"
              onClick={onCameraHomeClick}
            />
          </div>
          <div className="vl-refresh-set">
            <div
              className="vl-set"
              onClick={onSetHomeClick}
            >
              Set as Home
            </div>
          </div>
          <div className="vl-zoom-block">
            <Tooltip title="Zoom Out" placement="bottom">
              <img
                src={ZoomOut}
                alt=""
                className="vl-zoom-out"
                onMouseOver={(e) => { e.currentTarget.src = ZoomOutOver; }}
                onFocus={() => {}}
                onMouseOut={(e) => { e.currentTarget.src = ZoomOut; }}
                onBlur={() => {}}
                onMouseDown={onZoomOutMouseDown}
                onMouseLeave={onZoomOutMouseLeave}
                onMouseUp={onZoomOutMouseLeave}
              />
            </Tooltip>
            <Tooltip title="Zoom In" placement="bottom">
              <img
                alt=""
                src={ZoomIn}
                className="vl-zoom-in"
                onMouseOver={(e) => { e.currentTarget.src = ZoomInOver; }}
                onFocus={() => {}}
                onMouseOut={(e) => { e.currentTarget.src = ZoomIn; }}
                onBlur={() => {}}
                onMouseDown={onZoomInMouseDown}
                onMouseLeave={onZoomInMouseLeave}
                onMouseUp={onZoomInMouseLeave}
              />
            </Tooltip>
          </div>
          <div className="vl-refresh-block">
            <div
              className="vl-refresh"
              onClick={onRefreshClick}
            >
              Refresh
            </div>
            {showReboot && (
              <div
                className="vl-reboot"
                onClick={onRebootClick}
              >
                Reboot
              </div>
            )}
          </div>
        </div>
        <div className="vl-care-right">
          <div className="vl-input-pin">
            <Input
              ref={setPinInputRef}
              value={pinInputValue}
              placeholder="+Add Pin"
              onChange={onPinInputChange}
              onClick={onPinInputClick}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  onPinInputEnterKey();
                }
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </div>
          <div className="vl-align-center">
            {bookmarks.length > 0 && (
              <div className="vl-list-scroll">
                <List
                  dataSource={bookmarks}
                  renderItem={(item) => (item
                    && (
                    <List.Item className="vl-pin-list-item">
                      <Tooltip title="Go to bookmark" placement="bottom">
                        <div className="vl-pinName" onClick={() => onBookmarkClick(item)}>
                          {item.name}
                        </div>
                      </Tooltip>
                      <Tooltip title="Update bookmark" placement="bottom">
                        <img
                          alt=""
                          src={Edit}
                          className="vl-pinImg"
                          onClick={() => onBookmarkUpdate(item)}
                        />
                      </Tooltip>
                      <Tooltip title="Go to bookmark" placement="bottom">
                        <img
                          alt=""
                          src={Pin}
                          className="vl-pinImg"
                          onClick={() => onBookmarkClick(item)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete bookmark" placement="bottom">
                        <img
                          alt=""
                          src={Delete}
                          className="vl-pinImgRight"
                          onClick={() => onBookmarkDelete(item)}
                        />
                      </Tooltip>
                    </List.Item>
                    )
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </MovablePanel>
);
