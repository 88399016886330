import { useRef, useEffect } from 'react';

/**
 * Hook that returns if component is mounted
 */
export const useIsMount = () => {
  const isMountRef = useRef(true);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  return isMountRef.current;
};
