import * as React from 'react';
import { Button, notification } from 'antd';
import warningSvg from '../../assets/images/warning.svg';
import affirmSvg from '../../assets/images/affirm_check.svg';
import '../../assets/styles/movablepanel.scss';
import XcloseIcon from '../../assets/images/panels/X_close.svg';

export enum MessageType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Open = 'open',
}

function renderCloseIcon() {
  return (
    <div>
      <span>
        <img src={XcloseIcon} alt="XcloseIcon" />
      </span>
    </div>
  );
}

/**
 * Component for notification dialog
 *
 * @param {string} content
 * @param {any} description
 * @param {MessageType} type
 * @param {number} duration
 * @param {function} onClose
 * @param {btnFunction} btnFunction
 * @param {string} btnText
 */
export default function showNotificationDialog(
  content: string,
  description: any,
  type: MessageType,
  duration = 6,
  onClose?: () => void,
  btnFunction?: () => void,
  btnText?: string,
): void {
  const btn = btnFunction && btnText && (
    <Button onClick={btnFunction} className="vl-btn vl-btn-blue wa">
      {btnText}
    </Button>
  );

  notification.open({
    message: content,
    description,
    placement: 'topRight',
    duration,
    icon: <img src={type === MessageType.Success ? affirmSvg : warningSvg} alt="notificationIcon" />,
    closeIcon: renderCloseIcon(),
    onClose,
    btn,
  });
}
