import { atom } from 'recoil';

export const CameraMetadata = atom({
  key: 'CameraMetadata',
  default: {
    bookmarks: [],
    default_speed: 10,
    default_output: 10,
    default_input: 10,
    default_bell: 10,
    type: '',
    address: '',
  },
});

export const DeviceDetails = atom({
  key: 'DeviceDetails',
  default: {
    address: '',
    can_brightness: false,
    can_focus: false,
    can_night_mode: false,
    device_type: '',
    protocol: '',
    features: {
      eko_enabled: false,
    },
  },
});

export const Speaker = atom({
  key: 'Speaker',
  default: {
    cameraId: '',
    speakerValue: 5,
  },
});

export const Mic = atom({
  key: 'Mic',
  default: {
    cameraId: '',
    micValue: 5,
  },
});

export const Bell = atom({
  key: 'Bell',
  default: {
    cameraId: '',
    bellValue: 5,
  },
});

export const Brightness = atom({
  key: 'Brightness',
  default: {
    cameraId: '',
    brightnessValue: 5,
  },
});

export const Speed = atom({
  key: 'Speed',
  default: {
    cameraId: '',
    speedValue: 5,
  },
});

export const Focus = atom({
  key: 'Focus',
  default: {
    cameraId: '',
    focusValue: 5,
  },
});
