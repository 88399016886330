import React, { FC } from 'react';
import {
  Select, Spin, Row, Col, Slider, Button,
} from 'antd';
import '@assets/styles/videodevices.scss';
import '@assets/styles/movablepanel.scss';
import Headphones from '@assets/images/panels/settings_my_device_headphones.svg';
import Mike from '@assets/images/panels/settings_room_device_mic.svg';
import Camera from '@assets/images/panels/settings_my_device_camera.svg';
import VolumeLow from '@assets/images/panels/settings_my_device_volume_low.svg';
import VolumeHigh from '@assets/images/panels/settings_my_device_volume_high.svg';
import { IS_FIREFOX } from '../../common/browser';

export interface UserDeviceSettingsViewProps {
  state: any;
  LEFT_COLUMN_SIZE: number;
  SPAN_LEFT_ICON_VALUE: number;
  SPAN_SLIDER_VALUE: number;
  SPAN_RIGHT_OFFSET: number;
  getOptions: (options: MediaDeviceInfo[]) =>
  { label: string; value: string; }[];
  onSelectSpeaker: (selectedValue: string) => void;
  onSetVolume: (selectedValue: number) => void;
  onSelectMicrophone: (selectedValue: string) => void;
  onSelectCamera: (selectedValue: string) => void;
  VolumeBars: () => JSX.Element;
  testAudio: () => void;
  testMic: (newState: boolean) => void;

}

/**
 * FC to visualize the user device audio video settings view.
 *
 * @param settingsOn - boolean prop that determines is settings panel visible
 */
const UserDeviceSettingsView: FC<UserDeviceSettingsViewProps> = ({
  state,
  VolumeBars,
  LEFT_COLUMN_SIZE,
  SPAN_LEFT_ICON_VALUE,
  SPAN_SLIDER_VALUE,
  SPAN_RIGHT_OFFSET,
  getOptions,
  onSelectSpeaker,
  onSetVolume,
  onSelectMicrophone,
  onSelectCamera,
  testAudio,
  testMic,
}) => (

  <div className="vl-movable-panel-content">
    {state.loading && (
      <div className="vl-video-spin">
        <Spin />
      </div>
    )}

    {!state.loading && (
      <>
        {!IS_FIREFOX && (
          <div>
            <Row className="vl-settings-row vl-mt0">
              <Col span={LEFT_COLUMN_SIZE}><img src={Headphones} className="vl-setting-img" alt="vl-setting-img" /></Col>
              <Col span={24 - LEFT_COLUMN_SIZE}>
                <div className="vl-setting-select">
                  <Select
                    getPopupContainer={(node) => node.parentNode}
                    options={getOptions(state.speakers)}
                    defaultValue={state.speakerId}
                    value={state.speakerId}
                    onSelect={onSelectSpeaker}
                    placeholder="Audio output destination"
                  />
                </div>
              </Col>
            </Row>
            <Row className="vl-settings-row">
              <Col offset={LEFT_COLUMN_SIZE} span={SPAN_LEFT_ICON_VALUE}>
                <img
                  className="vl-setting-img2"
                  onClick={() => onSetVolume(0)}
                  src={VolumeLow}
                  alt="vl-setting-img2"
                />
              </Col>
              <Col id="volume_slider" span={SPAN_SLIDER_VALUE}>
                <Slider
                  className="vl-setting-slider"
                  min={0}
                  max={10}
                  defaultValue={state.speakerVolume}
                  value={state.speakerVolume}
                  onChange={(value: number) => onSetVolume(value)}
                />
              </Col>
              <Col offset={SPAN_RIGHT_OFFSET} span={SPAN_LEFT_ICON_VALUE}>
                <img
                  className="vl-setting-img2"
                  onClick={() => onSetVolume(10)}
                  src={VolumeHigh}
                  alt="vl-setting-img2"
                />
              </Col>
              <Col offset={1} span={3}>
                <Button className="vl-btn vl-btn-blue vl-settings-button" onClick={testAudio}>Test</Button>
              </Col>
            </Row>
          </div>
        )}

        <Row className="vl-settings-row">
          <Col span={LEFT_COLUMN_SIZE}><img src={Mike} className="setting-img" alt="setting-img" /></Col>
          <Col span={24 - LEFT_COLUMN_SIZE}>
            <div className="vl-setting-select">
              <Select
                getPopupContainer={(node) => node.parentNode}
                options={getOptions(state.microphones)}
                defaultValue={state.microphoneId}
                value={state.microphoneId}
                onSelect={onSelectMicrophone}
                placeholder="Audio input source"
              />
            </div>
          </Col>
        </Row>
        <Row className="vl-settings-row">
          <Col offset={LEFT_COLUMN_SIZE} span={2 * SPAN_LEFT_ICON_VALUE + SPAN_SLIDER_VALUE + SPAN_RIGHT_OFFSET}>
            <div className="mt5px">
              <VolumeBars />
            </div>
          </Col>
          <Col offset={1} span={3}>
            <Button className="vl-btn vl-btn-blue vl-settings-button" onClick={() => testMic(!state.testMic)}>{state.testMic ? 'Stop test' : 'Test'}</Button>
          </Col>
        </Row>
        <Row className="vl-settings-row vl-mb0">
          <Col span={LEFT_COLUMN_SIZE}><img src={Camera} className="vl-setting-img" alt="vl-setting-img" /></Col>
          <Col span={24 - LEFT_COLUMN_SIZE}>
            <div className="vl-setting-select">
              <Select
                getPopupContainer={(node) => node.parentNode}
                options={getOptions(state.cameras)}
                defaultValue={state.cameraId}
                value={state.cameraId}
                onSelect={onSelectCamera}
                placeholder="Video source"
              />
            </div>
          </Col>
        </Row>
      </>
    )}
  </div>
);

export default UserDeviceSettingsView;
