import React, { FC, ReactNode } from 'react';
import { Col, Tooltip } from 'antd';
import chatSvg from '@assets/images/participants/chat_btn.svg';
import micOnSvg from '@assets/images/participants/mic_on.svg';
import micOffSvg from '@assets/images/participants/mic_off.svg';
import admitSvg from '@assets/images/participants/admit.svg';
import denySvg from '@assets/images/participants/deny.svg';

/**
 * Renders button
 *
 * @param img - img
 * @param onClick - onClick func
 * @param style - style
 */
const renderBtn = (
  img: string,
  onClick: () => void,
  style?: React.CSSProperties,
): ReactNode => (
  <img
    src={img}
    style={style}
    onClick={onClick}
    alt="button"
  />
);

/**
 * Builds tooltip title string
 *
 * @param isMuted - isMuted
 * @param isAdmin - isAdmin
 */
const getMuteTooltipTitle = (isMuted: boolean, isAdmin: boolean): string => {
  if (isAdmin) {
    return `${isMuted ? 'Unmute' : 'Mute'} Participant`;
  }

  return `${isMuted ? 'Muted' : 'Unmuted'} Participant`;
};

export interface ParticipantButtonsProps {
  isSelf: boolean;
  isAdmin: boolean;
  isWaiting: boolean;
  isSessionLocked: boolean;
  isMuted: boolean;
  hasChatToken: () => boolean;
  onAdmitClick: () => void;
  onDenyClick: () => void;
  onChatClick: () => void;
  onMuteClick: () => void;
  onRemoveClick: () => void;
}

/**
 * ParticipantButtons Component
 *
 * @param isSessionLocked - isSessionLocked
 * @param isWaiting - isWaiting
 * @param isMuted - isMuted
 * @param onAdmitClick - handler
 * @param onDenyClick - handler
 * @param onChatClick - handler
 * @param onMuteClick - handler
 * @param onRemoveClick - handler
 * @param hasChatToken - boolean
 * @constructor
 */
export const ParticipantButtons: FC<ParticipantButtonsProps> = ({
  isSelf,
  isAdmin,
  isSessionLocked,
  isWaiting,
  isMuted,
  onAdmitClick,
  onDenyClick,
  onChatClick,
  onMuteClick,
  onRemoveClick,
  hasChatToken,
}) => (isSessionLocked && isWaiting ? (
  <Col onClick={(event) => event.stopPropagation()}>
    <Tooltip title="Admit Participant" placement="bottom">
      <span className="vl-part-button">
        {renderBtn(admitSvg, onAdmitClick, { height: 24 })}
      </span>
    </Tooltip>
    <Tooltip title="Deny Participant" placement="bottom">
      <span className="vl-part-button">
        {renderBtn(denySvg, onDenyClick, { height: 24, marginLeft: 10 })}
      </span>
    </Tooltip>
  </Col>
) : (
  <Col onClick={(event) => event.stopPropagation()} className="vl-part-button-wrap">
    {!isSelf && hasChatToken() && (
      <Tooltip title="Open Chat" placement="bottom">
        <span className="vl-part-button">
          {renderBtn(chatSvg, onChatClick)}
        </span>
      </Tooltip>
    )}
    <Tooltip title={getMuteTooltipTitle(isMuted, isAdmin)} placement="bottom">
      <span className="vl-part-button">
        {renderBtn(
          isMuted ? micOffSvg : micOnSvg,
          isAdmin ? onMuteClick : () => {},
          isAdmin
            ? { }
            : { cursor: 'not-allowed' },
        )}
      </span>
    </Tooltip>
    {!isSelf && isAdmin && (
    <Tooltip title="Remove Participant" placement="bottom">
      <span>
        {renderBtn(denySvg, onRemoveClick)}
      </span>
    </Tooltip>
    )}
  </Col>
));
