import {
  AddFavoritePayload, InviteGuestPayload,
  Participant, ServiceParticipant,
} from '@types';

type MutePayload = { isMute: true };

export interface CaregilityEventMap {
  onInit: CustomEvent<{ success: boolean }>;

  onCallStartPexip: CustomEvent<null>;
  onCallStartZoom: CustomEvent;
  onCallEnd: CustomEvent<boolean>;

  onMuteSelfAudio: CustomEvent<MutePayload>;
  onMuteSelfVideo: CustomEvent<MutePayload>;
  onMuteParticipantAudio: CustomEvent<Participant>;
  onMuteParticipantVideo: CustomEvent<Participant>;
  onPinParticipant: CustomEvent<Participant>;
  onRemoveParticipant: CustomEvent<Participant>;
  onAdmitParticipant: CustomEvent<Participant>;
  onDenyParticipant: CustomEvent<Participant>;
  onAddFavorite: CustomEvent<AddFavoritePayload>;
  onRemoveFavorite: CustomEvent<string>;
  onUpdateParticipant: CustomEvent<Participant>;
  onDeleteParticipant: CustomEvent<Participant>;
  onInviteGuest: CustomEvent<InviteGuestPayload>;

  onPatientOnHold: CustomEvent<boolean>;

  onToggleSessionLock: CustomEvent<boolean>;

  onConnectPexip: CustomEvent<{ stream: MediaStream | string }>;
  onScreenSharePexip: CustomEvent<{ setting: boolean, presenter: string, uuid: string }>;
  onSelfScreenshareConnectedPexip: CustomEvent<{ stream: MediaStream | string }>;
  onSelfScreenshareDisconnectedPexip: CustomEvent<{ reason: string }>;
  onScreenShareConnectedPexip: CustomEvent<{ stream: MediaStream | string }>;
  onScreenShareDisconnectedPexip: CustomEvent<{ reason: string }>;
  onServiceParticipantCreate: CustomEvent<ServiceParticipant>;
  onServiceParticipantUpdate: CustomEvent<ServiceParticipant>;
  onServiceParticipantDelete: CustomEvent<ServiceParticipant>;
  onStartNewSession: CustomEvent;
}

export class EventManager {
  public static subscribe<T extends keyof CaregilityEventMap>(
    type: T,
    listener: (this: EventManager, ev: CaregilityEventMap[T]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;

  /**
   * Subscribes to the provided event type
   *
   * @param type - event type
   * @param listener - listener func
   * @param options - options
   */
  public static subscribe(
    type: string,
    listener: (this: EventManager, ev: Event) => any,
    options?: boolean | AddEventListenerOptions,
  ): void {
    document.addEventListener(type, listener, options);
  }

  public static unsubscribe<T extends keyof CaregilityEventMap>(
    type: T,
    listener: (this: EventManager, ev: CaregilityEventMap[T]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;

  /**
   * Unsubscribes from the provided event type
   *
   * @param type - event type
   * @param listener - listener func
   * @param options - options
   */
  public static unsubscribe(
    type: string,
    listener: (this: EventManager, ev: Event) => any,
    options?: boolean | AddEventListenerOptions,
  ): void {
    document.removeEventListener(type, listener, options);
  }

  public static dispatchEvent<T extends keyof CaregilityEventMap, P>(
    type: T,
    payload: P,
  ): void;

  /**
   * Dispatches the provided event type
   *
   * @param type - event type
   * @param payload - event data
   */
  public static dispatchEvent(
    type: string,
    payload: any,
  ): void {
    document.dispatchEvent(new CustomEvent(type, { detail: payload }));
  }
}
