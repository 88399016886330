import { CaregilityServiceAdapter } from '@adapters';
import { checkCondition, getZoomClientViewButtonLabel } from '@utils/helpers';
import { useRef, useEffect } from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import {
  AudioOn, VideoOn, PatientOnHold,
} from '@atoms/VideoButtons';
import { useIsMount, useStartNewSession } from '@hooks';

/**
 * Custom hook to wrap zoom button.
 *
 * @param atom - the atom that stores the global state of the setting
 * @param onValue - the label that should be displayed when the button is on
 * @returns custom hook that wraps zoom button
 */
export const useZoomButton = (atom: RecoilState<boolean>, onValue: string) => {
  const [isOn, setIsOn] = useRecoilState(atom);
  const button = useRef<HTMLButtonElement>();
  const acceptClicks = useRef<boolean>(true);

  /**
   * Clicks the button and waits several seconds that click to take effect
   * Blocks click events until then.
   * On fail changes the global state.
   */
  const click = () => {
    if (acceptClicks.current && button.current) {
      acceptClicks.current = false;
      const currentValue = getZoomClientViewButtonLabel(button.current);
      button.current.click();
      checkCondition(
        () => !!button.current && currentValue !== getZoomClientViewButtonLabel(button.current),
        6,
        () => {
          if (button.current) {
            setIsOn(getZoomClientViewButtonLabel(button.current) === onValue);
          }
          acceptClicks.current = true;
        },
        () => {
          if (button.current) {
            setIsOn(getZoomClientViewButtonLabel(button.current) === onValue);
          }
          acceptClicks.current = true;
        },
        1000,
      );
    }
  };

  /**
   * An initializer function that checks if current button state is same as the configured one.
   *
   * @param btn - zoom button
   */
  const setButton = (btn: HTMLButtonElement) => {
    button.current = btn;
    if (btn) {
      const isButtonOn = getZoomClientViewButtonLabel(btn) === onValue;
      if (isButtonOn !== isOn) {
        click();
      }
    }
  };

  return {
    click,
    setButton,
  };
};

/**
 * Custom hook for handling the patient on hold functionality.
 *
 * @param micButton - Object containing click and setButton functions for the microphone button.
 * @param cameraButton - Object containing click and setButton functions for the camera button.
 */
export const usePOH = (
  micButton: { click: () => void; setButton: (btn: HTMLButtonElement) => void; },
  cameraButton: { click: () => void; setButton: (btn: HTMLButtonElement) => void; },
  adapter: CaregilityServiceAdapter,
): { click: () => void } => {
  const [patientOnHold, switchPatientOnHoldProp] = useRecoilState(PatientOnHold);
  const videoOn = useRecoilValue<boolean>(VideoOn);
  const audioOn = useRecoilValue<boolean>(AudioOn);
  const isFirstMount = useIsMount();

  useStartNewSession();

  useEffect(() => {
    if (!isFirstMount) {
      adapter.getSessionControlManager()?.putPatientOnHold(patientOnHold);
      if (patientOnHold) {
        if (videoOn) {
          cameraButton.click();
        }
        if (audioOn) {
          micButton.click();
        }
      } else if (!patientOnHold) {
        if (!videoOn) {
          cameraButton.click();
        }
        if (!audioOn) {
          micButton.click();
        }
      }
    }
  }, [patientOnHold]);

  const click = () => {
    switchPatientOnHoldProp((p: boolean) => !p);
  };
  return {
    click,
  };
};
