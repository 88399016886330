import React, {
  FC, useState, useEffect, ReactNode, useMemo,
} from 'react';
import {
  Badge, Button, Checkbox, Col, Collapse, Form, Input, List, Row, Select, Spin, Tooltip,
} from 'antd';
import {
  LoadingOutlined, WarningOutlined, SearchOutlined, StarOutlined,
} from '@ant-design/icons';
import {
  filledStar, filledStarLrg, inviteGreenBtn, selectStyle420, spin, starOutlined, starOutlinedLrg,
} from '@assets/styles/antd-custom-styles';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { useRecoilValue } from 'recoil';
import {
  AssociatedProfile, AssociatedRoom, DeviceData, FavoriteData, FavoriteModel, ListData, OptionsData,
} from '@types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import soundWavesSvg from '@assets/images/panels/med_device_connect.svg';
import { isEmpty } from 'lodash';
import { debounce } from '@utils/helpers';
import { FavoriteStar } from './FavoriteStar';
import { CallButton } from './CallButton';

const { Panel } = Collapse;

const SEARCH_LIMIT = 1;

type ScreenType = 'DEFAULT' | 'FAVORITES' | 'SEARCH';

/**
 * Bedside - component for inviting a Care Device
 *
 */
export const Bedside: FC = () => {
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const careDevicesManager = adapter?.getCareDevicesManager();
  const favoritesManager = adapter?.getFavoritesManager();
  const [devices, setDevices] = useState<DeviceData[] | undefined>(undefined);
  const [customers, setCustomers] = useState<OptionsData[] | undefined>([]);
  const [facilities, setFacilities] = useState<OptionsData[] | undefined>([]);
  const [units, setUnits] = useState<OptionsData[] | undefined>([]);
  const [rooms, setRooms] = useState<OptionsData[] | undefined>([]);
  const [roomsFullData, setRoomsFullData] = useState<ListData[] | undefined>([]);
  const [careDevicesState, setCareDevicesState] = useState({
    customerId: '' as number | string,
    facilityId: '' as number | string,
    unitId: '' as number | string,
    roomId: '' as number | string,
  });
  const [roomName, setRoomName] = useState<string | undefined>('');
  const [customerSingleOption, setCustomerSingleOption] = useState<string>('');
  const [facilitySingleOption, setFacilitySingleOption] = useState<string>('');
  const [unitSingleOption, setUnitSingleOption] = useState<string>('');
  const [roomSingleOption, setRoomSingleOption] = useState<string>('');
  const [screen, setScreen] = useState<ScreenType>('DEFAULT');
  const [favorites, setFavorites] = useState<FavoriteData | undefined>(undefined);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    careDevicesManager?.fetchCustomersData();
    favoritesManager?.fetchFavoriteDevices();

    const searchDevicesListener = () => {
      setDevices(adapter?.devices.list);
    };
    const customersListener = () => {
      setCustomers(adapter?.customers.options);
    };
    const facilitiesListener = () => {
      setFacilities(adapter?.facilities.options);
    };
    const unitsListener = () => {
      setUnits(adapter?.units.options);
    };
    const roomDevicesListener = () => {
      setRooms(adapter?.roomDevices.options);
      setRoomsFullData(adapter?.roomFullData.list);
    };
    const favoriteDevicesListener = () => {
      setFavorites(adapter?.favorites.devices);
    };
    adapter?.on(EventNames.onDevicesChange, searchDevicesListener);
    adapter?.on(EventNames.onCustomersChange, customersListener);
    adapter?.on(EventNames.onFacilitiesChange, facilitiesListener);
    adapter?.on(EventNames.onUnitsChange, unitsListener);
    adapter?.on(EventNames.onRoomDevicesChange, roomDevicesListener);
    adapter?.on(EventNames.onFavoriteDevicesChange, favoriteDevicesListener);

    return () => {
      adapter?.off(EventNames.onDevicesChange, searchDevicesListener);
      adapter?.off(EventNames.onCustomersChange, customersListener);
      adapter?.off(EventNames.onFacilitiesChange, facilitiesListener);
      adapter?.off(EventNames.onUnitsChange, unitsListener);
      adapter?.off(EventNames.onRoomDevicesChange, roomDevicesListener);
      adapter?.off(EventNames.onFavoriteDevicesChange, favoriteDevicesListener);
      careDevicesManager?.cleanup();
      favoritesManager?.cleanup();
      adapter?.getSearchManager().cleanup();
    };
  }, [adapter, careDevicesManager, favoritesManager]);

  /**
   * Calls the `fetchDevices` method of the `searchManager`
   * @param searchTerm - the search term to use when fetching devices
   */
  const handleSearch = (searchTerm: string) => adapter?.getSearchManager().fetchDevices(searchTerm);

  /**
    * Returns a debounced version of the `handleSearch` function that delays calling the original function
    * by the specified time (in milliseconds). The debounced function will only be called after the delay time
    * has passed and no further calls to the function have been made during that time.
    * @param searchTerm - the search term to use when fetching devices
   */
  const delaySaveToDb: (searchTerm: string) => void = useMemo(() => debounce(handleSearch, 500, false), []);

  /**
   * Handles changes in the search input field
   * @param e event
   */
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInputValue(value);

    if (value.length >= SEARCH_LIMIT) {
      setScreen('SEARCH');
      delaySaveToDb(value);
    } else {
      setScreen('DEFAULT');
    }
  };

  /**
   * Handles keyboard events
   * If the key pressed is `Enter`, devices are fetched
   * @param e event
   */
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      adapter?.getSearchManager().fetchDevices(inputValue);
    }
  };

  /**
   * Handles change of customer
   *
   * @param selectedValue - customer id
   */
  const onSelectCustomer = (selectedValue: number | string): void => {
    setCareDevicesState((prevState) => ({
      ...prevState,
      customerId: selectedValue,
      facilityId: '',
      unitId: '',
      roomId: '',
    }));

    careDevicesManager?.fetchFacilitiesData(+selectedValue);
  };

  /**
   * Handles change of facility
   *
   * @param selectedValue - facility id
   */
  const onSelectFacility = (selectedValue: number | string): void => {
    setCareDevicesState((prevState) => ({
      ...prevState,
      facilityId: selectedValue,
      unitId: '',
      roomId: '',
    }));

    careDevicesManager?.fetchUnitsData(+careDevicesState.customerId, +selectedValue);
  };

  /**
   * Handles change of unit
   *
   * @param selectedValue - unit id
   */
  const onSelectUnit = (selectedValue: number | string): void => {
    setCareDevicesState((prevState) => ({
      ...prevState,
      unitId: selectedValue,
      roomId: '',
    }));

    careDevicesManager
      ?.fetchRoomDevicesData(+careDevicesState.customerId, +careDevicesState.facilityId, +selectedValue);
  };

  /**
   * Updates the favorite status of a device
   *
   * @param deviceId - deviceId
   */
  const updateFavoriteHandler = (deviceId: number | undefined): void => {
    favoritesManager?.fetchFavoriteByDeviceId((deviceId || 0).toString()).then((data) => {
      setIsFavorite(!!data);
    });
  };

  /**
   * Handles change of room device
   *
   * @param selectedValue - room id
   */
  const onSelectRoom = (selectedValue: number | string): void => {
    setCareDevicesState((prevState) => ({
      ...prevState,
      roomId: selectedValue,
    }));
    const selectedOption = rooms?.find((option) => option.value === selectedValue);
    setRoomName(selectedOption?.label);
    updateFavoriteHandler(+selectedValue);
  };

  useEffect(() => {
    if (customers?.length === 1 && careDevicesState.customerId !== customers[0].value) {
      setCustomerSingleOption(customers[0].label);
      onSelectCustomer(customers[0].value);
    } else if (facilities?.length === 1 && careDevicesState.facilityId !== facilities[0].value) {
      setFacilitySingleOption(facilities[0].label);
      onSelectFacility(facilities[0].value);
    } else if (units?.length === 1 && careDevicesState.unitId !== units[0].value) {
      setUnitSingleOption(units[0].label);
      onSelectUnit(units[0].value);
    } else if (rooms?.length === 1 && careDevicesState.roomId !== rooms[0].value) {
      setRoomSingleOption(rooms[0].label);
      onSelectRoom(rooms[0].value);
    }
  }, [customers, facilities, units, rooms]);

  /**
   * Sorts the array data in ascending non-case-sensitive order
   *
   * @param array - array of objects
   * @param prop - object key used for sorting
   * @returns {Array} - sorted data
   */
  const sortBy = <T extends OptionsData | FavoriteModel | DeviceData>(array:T[] | undefined, prop: keyof T)
    : T[] | undefined => array?.sort((a, b) => (a[prop] as string).localeCompare(b[prop] as string));

  const sortedCustomers = sortBy(customers, 'label');
  const sortedFacilities = sortBy(facilities, 'label');
  const sortedUnits = sortBy(units, 'label');
  const sortedRooms = sortBy(rooms, 'label');
  const sortedFavorites = favorites && sortBy(Object.values(favorites?.list), 'name');
  const sortedSearchDevices = devices && sortBy(Object.values(devices), 'device_name');

  /**
   * Determinates if a room is online
   * @param item room data
   * @returns true if the room is online, false otherwise
   */
  const isRoomOnline = (item: DeviceData | undefined) => item && (['ONLINE', 'AVAILABLE', 'ON_CALL'].includes(item.status ?? '') || item?.device_category === 'STANDARD');

  /**
   * Gets the status of a room
   * @param item room data
   * @returns room status
   */
  const getRoomStatus = (item: DeviceData | undefined) => {
    if (isRoomOnline(item) && item?.status !== 'NOT_CONFIGURED') {
      return 'profile-available';
    }
    return 'profile-offline';
  };

  /**
   * Determinates if a given room is favorite
   * @param room room data
   * @returns true if the room is favorite, false otherwise
   */
  const isRoomFavorite = (room: DeviceData) => {
    if (['CARE_DEVICE', 'STANDARD'].includes(room.device_category)) {
      if (isEmpty(room.unique_id)) return false;
      return !!(favorites && Object.values(favorites.list).find((x) => x.type === 'ROOM' && x.associatedRoom?.unique_id === room.unique_id));
    } if (room.device_category === 'MOBILE_DEVICE') {
      return !!(favorites && Object.values(favorites.list).find((x) => x.type === 'PATIENT' && x.metadata === room.email));
    }
    return false;
  };

  /**
   * Determinate if the current screen is 'FAVORITES'
   */
  const isFavorites = (): boolean => screen === 'FAVORITES';

  /**
   * Updates the screen value when the checkbox is triggered
   *
   * @param e - event triggered by the checkbox
   */
  const toggleFavorites = (e: CheckboxChangeEvent): void => {
    if (e.target.checked) {
      setScreen('FAVORITES');
    } else {
      setScreen('DEFAULT');
    }

    if (!isFavorites()) {
      favoritesManager?.fetchFavoriteDevices();
      setInputValue('');
      setDevices(undefined);
    }
  };

  /**
   * Determinate if a camera is online
   * true if the camera is online, false otherwise
   *
   * @param item - camera data
   */
  const isCameraOnline = (item: AssociatedRoom | undefined): boolean => !!(item && (item.type !== 'vmr' || (item.type === 'vmr' && item.status_of_room === 'ONLINE')));

  /**
   * Determinate if a device is online
   * true if the device is online, false otherwise
   *
   * @param item - device data
   */
  const isAPDOnline = (item: AssociatedProfile | undefined): boolean => !!(item && (item.presence !== 'NOT_AVAILABLE'));

  /**
   * Gets the status of a camera
   *
   * @param item - camera data
   */
  const getCameraStatus = (item: FavoriteModel): string => {
    if ((isCameraOnline(item.associatedRoom)
      && item?.associatedRoom?.status_of_room !== 'NOT_CONFIGURED')
        || isAPDOnline(item.associatedProfile)) {
      return 'profile-available';
    }

    return 'profile-offline';
  };

  /**
   * Displays the details of a given patient
   *
   * @param associatedProfile - patient data
   */
  const getProfilesDetails = (associatedProfile?: AssociatedProfile): ReactNode => {
    if (associatedProfile) {
      return (
        <div className="vl-collapse vl-f-14">
          <div className="vl-property">Phone:</div>
          <div className="vl-value">{associatedProfile?.phoneNumber}</div>
          <div className="vl-property">Email:</div>
          <div className="vl-value">{associatedProfile?.email}</div>
          <div className="vl-property">Organization:</div>
          <div className="vl-value">{associatedProfile?.organization}</div>
          <div className="vl-property">Facility:</div>
          <div className="vl-value">{associatedProfile?.facility}</div>
        </div>
      );
    }

    return null;
  };

  /**
   * Displays the details of a given room
   *
   * @param room - room data
   */
  const getCameraDetails = (room?: AssociatedRoom): ReactNode => {
    if (room) {
      return (
        <div className="vl-collapse vl-f-16">
          <Row>
            <Col span={12} className="vl-property">Organization:</Col>
            <Col span={12} className="vl-value">{room.channel}</Col>
          </Row>
          <Row>
            <Col span={12} className="vl-property">Customer:</Col>
            <Col span={12} className="vl-value">{room.customer}</Col>
          </Row>
          <Row>
            <Col span={12} className="vl-property">Facility:</Col>
            <Col span={12} className="vl-value">{room.location}</Col>
          </Row>
          <Row>
            <Col span={12} className="vl-property">Unit:</Col>
            <Col span={12} className="vl-value">{room.room}</Col>
          </Row>
          <Row>
            <Col span={12} className="vl-property">Status:</Col>
            <Col span={12} className="vl-value">{room.status_of_room}</Col>
          </Row>
          <Row>
            <Col span={12} className="vl-property">Med Device:</Col>
            <Col span={12} className="vl-value">Eko Enabled</Col>
          </Row>
        </div>
      );
    }

    return null;
  };

  /**
   * Checks if the favorite is a profile or a room
   *
   * @param favorite - favorite data
   */
  const checkProfilesDetailsOrCameraDetails = (favorite: FavoriteModel): ReactNode => {
    if (favorite.type === 'PATIENT') {
      return getProfilesDetails(favorite?.associatedProfile);
    }

    return getCameraDetails(favorite?.associatedRoom);
  };

  const handleStartNewSession = (): void => {
    const device = roomsFullData?.find((roomFull) => roomFull.id === +careDevicesState.roomId);

    adapter?.startNewSession(+careDevicesState.roomId, device?.device_type);
  };

  const metadata = `${careDevicesState.customerId}/${careDevicesState.facilityId}/${careDevicesState.unitId}/${careDevicesState.roomId}`;
  const roomFavoriteName = favorites && Object.values(favorites.list).find((room) => room.name === roomName);
  const roomFavoriteData = roomsFullData && Object.values(roomsFullData).find((room) => room.name === roomName);

  /**
   * Finds if a given room is favorite
   * @param room room data
   * @returns favorite data object if the room is favorite, undefined otherwise
   */
  const findFavoriteRoom = (room: DeviceData) => favorites
    && Object.values(favorites.list).find((r) => r.name === room.device_name);

  return (
    <>
      <Form>
        <>
          <Row className="vl-h-60">
            <Col span={24}>
              <div className="vl-select-field-lgr">
                <div>
                  <Input
                    className="vl-search-field"
                    prefix={<SearchOutlined />}
                    size="large"
                    placeholder="WallCart / Mobile Search"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className="vl-cb-right">
                <Tooltip title={isFavorites() ? 'Hide favorites' : 'Show favorites'} placement="bottom">
                  <Checkbox className="vl-cb" checked={isFavorites()} onChange={toggleFavorites}>
                    <span className={isFavorites() ? 'cb-label-active vl-f-16' : 'vl-cb-label vl-f-16'}>My Favorites</span>
                  </Checkbox>
                </Tooltip>
              </div>
            </Col>
          </Row>
          <hr className="vl-hr-invite" />
        </>
      </Form>

      {screen === 'DEFAULT' && (
      <Form layout="vertical">
        <Row>
          <div className="vl-grey">
            <div className="vl-select-field">
              <Select
                id="customer_selector"
                placeholder="Customer"
                getPopupContainer={(node) => node.parentNode}
                options={sortedCustomers}
                value={customers?.length === 1 ? customerSingleOption : undefined}
                onSelect={onSelectCustomer}
                {...selectStyle420}
              />
            </div>
          </div>
        </Row>
        <Row>
          <div className="vl-grey">
            <div className="vl-select-field">
              <Select
                id="facility_selector"
                placeholder="Facility"
                getPopupContainer={(node) => node.parentNode}
                options={sortedFacilities}
                value={facilities?.length === 1 ? facilitySingleOption : undefined}
                disabled={!careDevicesState.customerId}
                onSelect={onSelectFacility}
                {...selectStyle420}
              />
            </div>
          </div>
        </Row>
        <Row>
          <div className="vl-grey">
            <div className="vl-select-field">
              <Select
                id="unit_selector"
                placeholder="Unit"
                getPopupContainer={(node) => node.parentNode}
                options={sortedUnits}
                value={units?.length === 1 ? unitSingleOption : undefined}
                disabled={!careDevicesState.facilityId}
                onSelect={onSelectUnit}
                {...selectStyle420}
              />
            </div>
          </div>
        </Row>
        <Row>
          <div className="vl-grey">
            <div className="vl-select-field">
              <Select
                id="room_selector"
                placeholder="Room (WallCart)"
                getPopupContainer={(node) => node.parentNode}
                options={sortedRooms}
                value={rooms?.length === 1 ? roomSingleOption : undefined}
                disabled={!careDevicesState.unitId}
                onSelect={onSelectRoom}
                {...selectStyle420}
              />
            </div>
          </div>
        </Row>
        <Row>
          {(careDevicesState.customerId
                && careDevicesState.facilityId
                && careDevicesState.unitId
                && careDevicesState.roomId)
            ? (
              <Row className="vl-saveToFav">
                <Col>
                  <FavoriteStar
                    isFavorite={isFavorite}
                    favorite={roomFavoriteName}
                    onUpdateFavorite={updateFavoriteHandler}
                    roomData={roomFavoriteData}
                    metadata={metadata}
                    filledStyle={filledStarLrg}
                    outlinedStyle={starOutlinedLrg}
                  />
                </Col>
                <Col>
                  {isFavorite
                    ? <div className="vl-bedside-star">Saved to My Favorites</div>
                    : <div className="vl-bedside-star">Save to My Favorites</div>}
                </Col>
              </Row>
            )
            : (
              <Row className="vl-saveToFav">
                <Col>
                  <StarOutlined className="disabled" style={starOutlinedLrg} />
                </Col>
                <Col>
                  <div className="vl-bedside-star disabled">Save to My Favorites</div>
                </Col>
              </Row>
            )}
        </Row>
        <Row className="vl-warning-message">
          <Col className="vl-image-warning">
            <div>
              <WarningOutlined className="vl-image" />
            </div>
          </Col>
          <Col className="vl-text-warning">
            <div>
              Adding or joining another care device/room will start a different session in a new browser tab.
              Your current session will be muted until you return and restart the session.
            </div>
          </Col>
        </Row>

        <Form.Item>
          <Button
            id="call_camera_btn"
            className="vl-btn vl-btn-green"
            style={inviteGreenBtn}
            htmlType="submit"
            type="primary"
            size="large"
            block
            disabled={!careDevicesState.roomId}
            onClick={handleStartNewSession}
          >
            Start New Session
          </Button>
        </Form.Item>
      </Form>
      )}

      {screen === 'FAVORITES' && (
      <div className="vl-invite-scroll" style={{ height: '480px' }}>
        <div>
          {adapter?.favorites.devices.loading
          && (
            <div>
              <br />
              <Spin indicator={<LoadingOutlined style={spin} spin />} />
            </div>
          )}

          {(!adapter?.favorites.devices.loading && favorites?.list) && (
          <List
            className="vl-profiles-list"
            pagination={{
              pageSize: favorites?.pagination.size ?? 20,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            dataSource={sortedFavorites}
            renderItem={(favorite) => (favorite
            && (
              <List.Item className="vl-list-item">

                <div className="vl-profiles-list-item">
                  <Badge
                    status="default"
                    className={`${getCameraStatus(favorite)} search-profile`}
                  />

                  <Collapse bordered={false} expandIconPosition="right">
                    <Panel
                      className="vl-profile-collapse-panel"
                      header={(
                        <Tooltip title={favorite.associatedRoom?.status_of_room} placement="bottom">
                          <span>
                            {favorite.name}
                            {favorite.associatedRoom
                            && favorite.associatedRoom.features
                            && favorite.associatedRoom.features.eko_enabled
                            && (
                              <img
                                alt=""
                                className="sound-wave-icon"
                                src={soundWavesSvg}
                              />
                            )}
                          </span>
                        </Tooltip>
                    )}
                      key="1"
                    >
                      <>
                        {checkProfilesDetailsOrCameraDetails(favorite)}
                      </>
                    </Panel>
                  </Collapse>
                  {!adapter?.favorites.devices.loading && (
                    <>
                      <div className="favorite-star-wrapper">
                        <FavoriteStar
                          isFavorite
                          favorite={favorite}
                          metadata={metadata}
                          filledStyle={filledStar}
                          outlinedStyle={starOutlined}
                        />
                      </div>
                      <div className="call-button-wrapper">
                        <CallButton
                          careDevice={favorite}
                          isCameraOnline={isCameraOnline}
                          isAPDOnline={isAPDOnline}
                        />
                      </div>
                    </>
                  )}
                </div>
              </List.Item>
            )
            )}
          />
          )}
          {(!adapter?.favorites.devices.loading && !favorites?.list)
            && (
              <div className="noFavorites">
                You have no saved favorites.
              </div>
            )}
        </div>
      </div>
      )}

      {screen === 'SEARCH' && (
        <div className="vl-invite-scroll" style={{ height: '480px' }}>
          <div>
            {(adapter?.devices.loading || adapter?.favorites.devices.loading)
              && (
                <div>
                  <br />
                  <Spin
                    indicator={<LoadingOutlined style={spin} spin />}
                  />
                </div>
              )}

            {(!adapter?.devices.loading && devices && devices.length > 0)
              && (
                <List
                  className="vl-profiles-list"
                  pagination={{
                    pageSize: 20,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                  }}
                  footer={(!adapter?.devices.loading && devices?.length && devices.length > 20)
                    && (
                      <div className="caption f-22">
                        There are more devices available.
                        <br />
                        Please refine the search
                        criteria.
                      </div>
                    )}
                  dataSource={sortedSearchDevices}
                  renderItem={(room: DeviceData) => (room
                    && (
                      <List.Item className="vl-list-item">

                        <div className="vl-profiles-list-item">
                          <Badge
                            status="default"
                            className={`${getRoomStatus(room)} search-profile`}
                          />

                          <Collapse bordered={false} expandIconPosition="right">
                            <Panel
                              className="vl-profile-collapse-panel"
                              header={(
                                <Tooltip title={room.device_name} placement="bottom">
                                  <span>
                                    {room.device_name}
                                    {room.features && room.features.eko_enabled
                                      && (
                                        <img
                                          alt="sound wave icon"
                                          className="sound-wave-icon"
                                          src={soundWavesSvg}
                                        />
                                      )}
                                  </span>
                                </Tooltip>
                              )}
                              key="1"
                            >
                              <div className="vl-collapse vl-f-16">
                                <Row>
                                  <Col span={12} className="vl-property">Organization:</Col>
                                  <Col span={12} className="vl-value">{room.organization}</Col>
                                </Row>
                                <Row>
                                  <Col span={12} className="vl-property">Customer:</Col>
                                  <Col span={12} className="vl-value">{room.customer}</Col>
                                </Row>
                                <Row>
                                  <Col span={12} className="vl-property">Facility:</Col>
                                  <Col span={12} className="vl-value">{room.facility}</Col>
                                </Row>
                                <Row>
                                  <Col span={12} className="vl-property">Unit:</Col>
                                  <Col span={12} className="vl-value">{room.unit}</Col>
                                </Row>
                                <Row>
                                  <Col span={12} className="vl-property">Status:</Col>
                                  <Col span={12} className="vl-value">{room.status}</Col>
                                </Row>
                                <Row>
                                  <Col span={12} className="vl-property">Med Device:</Col>
                                  <Col span={12} className="vl-value">Eko Enabled</Col>
                                </Row>
                              </div>
                            </Panel>
                          </Collapse>

                          <div className="favorite-star-wrapper">
                            <FavoriteStar
                              isFavorite={isRoomFavorite(room)}
                              favorite={findFavoriteRoom(room)}
                              searchRoom={room}
                              onUpdateFavorite={updateFavoriteHandler}
                              filledStyle={filledStar}
                              outlinedStyle={starOutlined}
                            />
                          </div>
                          <div className="call-button-wrapper">
                            <CallButton
                              searchRoom={room}
                              isRoomOnline={isRoomOnline}
                            />
                          </div>

                        </div>
                      </List.Item>
                    )
                  )}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
};
