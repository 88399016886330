import { Participant as PexipParticipant } from '@caregility/fe-pexip-client-library';

export interface PatientOnHold {
  poh_blocks_patient_sound: boolean;
  poh_doorbell_disabled: boolean;
}

export interface InterpreterServices {
  configured: boolean;
  supported_languages: string[];
}

export interface DeviceDetails {
  device_type: DeviceType;
  can_focus: boolean;
  can_night_mode: boolean;
  can_brightness: boolean;
  protocol: string;
}

export interface DeviceExternalUsage {
  in_use: boolean;
  service?: any;
}

export interface SessionMyParticipant extends SessionControlParticipant {
  participants_chat_tokens: SessionControlParticipantChatTokens;
}

export interface CaregilitySession {
  id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  organization_id: number;
  patient_on_hold: PatientOnHold;
  interpreter_services: InterpreterServices;
  screenshot_permission: boolean;
  content_sharing_permission: boolean;
  clinician_can_lock_room: boolean;
  automatic_doorbell: boolean;
  allow_clinician_soft_reboot: boolean;
  customer: string;
  customer_id: number;
  facility: string;
  facility_id: number;
  unit: string;
  unit_id: number;
  device_name: string;
  device_id: string;
  device_details: DeviceDetails;
  device_status: string;
  device_external_usage: DeviceExternalUsage;
  participant: SessionMyParticipant;
  all_participants: SessionControlParticipant[];
  chat_token: string;
  vmr_alias: string;
  mp_token: string;
  pin: string;
  media_provider: 'PEXIP' | 'ZOOM';
  error?: string;
  session_id?: string;
  locked?: boolean;
}

export interface CaregilityAccessTokenPayload {
  admin_id: number;
  email: string;
  aud: string;
  auth_type: string;
  user_typ: string;
  exp: number;
}

export interface CaregilityPagination {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}

export type PresenceType = 'AVAILABLE' | 'NOT_AVAILABLE' | 'ON_CALL' | 'DO_NOT_DISTURB';

export type RoomStatusType = 'ONLINE' | 'OFFLINE' | 'NOT_CONFIGURED';

export type FavoritesType = 'PROFILE' | 'ROOM' | 'SIP' | 'PATIENT' | 'NONE';

export type ParticipantType = 'CLINICIAN' | 'CARE_DEVICE' | 'SBAPS' | 'APD' | 'GUEST' | 'STRATUS_INTERPRETER';

export interface Favorite {
  name: string;
  id: number;
  type: string;
  metadata: string;
}

export interface AssociatedProfile {
  id: number;
  favorite: Favorite;
  isPatient: boolean;
  isVICN: boolean;
  isClinician: boolean;
  hasPatients: boolean;
  notificationPortalEnabled: boolean;
  hasIobserverRole: boolean;
  interpreterEnabled: boolean;
  guestButtonEnabled: boolean;
  presence: PresenceType;
  additionalClinicianInfo?: any;
  prefix: string;
  email: string;
  alias?: any;
  organizationId: number;
  customerId: number;
  firstName: string;
  lastName: string;
  facility?: any;
  unit?: any;
  queueUrl?: any;
  phoneNumber?: any;
  specialities: any[];
  canAccessNotificationPortal: boolean;
  createdAt: Date;
  role?: any;
  organization: string;
  customer: string;
  publicMessage?: string | null;
  pictureUrl?: string | null;
  isInvited?: boolean;
}

export interface AssociatedRoom extends CameraMetadata {
  name: string;
  channel: string;
  customer: string;
  location: string;
  room: string;
  device_id: number;
  device_type: DeviceType;
  status_of_room: string;
  type: 'sip' | 'vmr' | 'h323' | undefined;
  features: {
    'ipc': {
      'ipc_enabled': boolean | null,
      'ipc_call_patient_prompt': boolean | null
    },
    eko_enabled: boolean
  };
  stratus_billing_code: string;
}

export interface FavoriteModel {
  version: number;
  name: string;
  id: number;
  type: FavoritesType;
  notes?: any;
  updatedAt: Date;
  associatedRoom?: AssociatedRoom;
  associatedProfile?: AssociatedProfile;
  associatedDevice?: any;
  createdAt: Date;
  metadata: string;
}

export interface FavoriteData {
  list: { [id: string | number]: FavoriteModel };
  pagination: CaregilityPagination;
  loading: boolean;
}

export interface AddFavoritePayload {
  type: FavoritesType;
  name: string | undefined;
  metadata: string | undefined;
  notes?: string;
}

export interface CaregilityFavorites {
  clinicians: FavoriteData;
  devices: FavoriteData;
}

export interface CliniciansData {
  list: ProfileData[];
  pagination: CaregilityPagination;
  loading: boolean;
}

export interface DevicesData {
  list: DeviceData[];
  loading: boolean;
}

export interface ProfileDataFavorite {
  name: string;
  id: number;
  type: string;
  metadata: string;
}
export interface ProfileData {
  id: number;
  organization: string;
  email: string;
  customer: string;
  facility: string;
  isClinician: boolean;
  hasPatients: boolean;
  notificationPortalEnabled: boolean;
  hasIobserverRole: boolean;
  interpreterEnabled: boolean;
  guestButtonEnabled: boolean;
  isVICN: boolean;
  favorite: ProfileDataFavorite;
  createdAt: Date;
  canAccessNotificationPortal: boolean;
  organizationId: number;
  customerId: number;
  firstName: string;
  lastName: string;
  prefix: string;
  updatedAt: Date;
  isPatient: boolean;
  presence: PresenceType;
  pictureUrl?: string | null;
  publicMessage?: string | null;
  phoneNumber?: string;
  specialities: any[];
}

export interface DeviceData {
  device_id: number;
  unique_id : string;
  device_name: string;
  organization: string;
  organization_alias: string;
  customer: string;
  customer_alias: string;
  facility: string;
  unit: string;
  device_category: DeviceType;
  features?: {
    eko_enabled: boolean;
    ipc: {
      ipc_enabled: boolean,
      ipc_call_patient_prompt: boolean
    } | null;
  };
  status?: string;
  email : string;
}

export interface Participant {
  id?: string | number;
  displayName?: string;
  isFavorite?: boolean;
  isMuted?: boolean;
  isPinned?: boolean;
  isWaiting?: boolean;
  pictureUrl?: string;
  initials?: string;
  email?: string;
  phoneNumber?: string;
  organization?: string;
  facility?: string;
  sessionManager?: boolean;
  type?: ParticipantType;
  favorite?: FavoriteModel;
}

export interface SessionControlParticipant {
  id: string;
  aps: boolean;
  chat_token: string,
  display_name: string;
  waiting?: boolean;
  pinned?: boolean;
  muted?: boolean;
  type?: ParticipantType;
  event?: 'JOINED' | 'LEFT'
  clinician_identifier?: string; // Email?
  session_manager?: boolean;
  favorite?: FavoriteModel;
}

export interface SessionControlAction {
  participant_id?: number;
  action: 'POH' | 'MUTE' | 'ON_HOLD' | 'PIN' | 'ADMIT' | 'DISCONNECT' | 'LOCK' | 'UNLOCK' | 'NEW_SESSION_MANAGER' | 'SESSION_ENDED';
}

export interface SessionControlParticipantChatTokens {
  [key: string]: string;
}

export interface SessionControlActivateActionPayload {
  mp_token?: string;
  session_id?: number;
  participant_id?: number;
  activate?: boolean;
  enabled?: boolean;
}

export interface SessionControlInvitePayload {
  mp_token?: string;
  session_id?: number;
  email?: string;
  phone_number?: string;
  protocol?: string;
  address?: string;
  billing_code?: string;
  guest_identifier?: string;
}

export interface ChatMessage {
  // TODO: add message status that indicates if the message is sent.
  participantId: string;
  displayName: string;
  message: string;
  avatar?: string;
  initials?: string;
  isSeen?: boolean;
}

export interface ChatMessageModel {
  from?: {
    avatar: string,
    name: string
  },
  participant_id?: number,
  display_name?: string,
  message: string,
  room: string
}

export interface ChatRoom {
  id: string; // participant id
  displayName: string; // the name of the participant
  chatToken: string; // the chat token required to have a private conversation
  messages: ChatMessage[];
}

export enum InvitationType {
  clinician,
  bySms,
  byEmail,
  byPhoneNumber,
  byStandardsBased,
  byStandardsBasedStratus,
}

export interface InviteGuestPayload {
  type: InvitationType
  value?: string;
  protocol?: string;
  device_id?: number | string,
  device_type?: DeviceType,
}
export interface Session {
  isLocked?: boolean;
}
export interface CaregilityEndpoints {
  /**
   * Used for camera controls
   */
  cameraControlUrl: string;
  /**
   * Used to get camera info requires v2 api
   */
  camerasUrl: string;
  /**
   *  Used to get Care Devices info
   */
  careDevicesUrl: string;
  /**
   * Used to connect to session control stomp WebSocket
   */
  stompBrokerUrl: string;
  /**
   * Used to send session control requests
   */
  sessionControlUrl: string;
  /**
   * Used for favorites requests
   */
  mobileBackendUrl: string;
  /**
   * Used in searching for devices
   */
  adminBackendUrl: string;
  /**
   * env prefix (ex. env2_) used for camera control purposes
   */
  envPrefix: string;
}
export interface PexipConfig {
  /**
   * Pexip script link
   */
  nodeAddress: string;
  /**
   * Pexip backend node endpoint
   */
  node: string;
  /**
   * Conference vmr
   */
  conferenceVmr: string;
  /**
   * Display name
   */
  name: string;
  bandwidth?: number;
  callType?: string;
  pin?: string;
  extension?: string;
  idp?: string;
}

export interface ZoomMeetConfig {
  /**
   * The Zoom meeting number
   */
  meetingNumber: string;
  /**
   * Display name
   */
  userName: string;
  /**
   * Password for the Zoom meeting
   */
  zoomMeetingPassword?: string;
  userEmail?: string;
  /**
   * SDK Key for Zoom App
   */
  sdkKey: string;
  /**
   * Zoom Meeting token
   */
  signature: string;
  /**
   * After Zoom call is complete app will reload using provided url
   */
  leaveUrl: string;
}

export interface MsTeamsConfig {
  // To be determined (MS Teams View)
  apiKey?: string;
}

export interface RequestHeader {
  'Content-Type': string;
  Authorization: string;
}

export enum VideoSDK {
  pexip = 'pexip',
  zoom = 'zoom',
  msTeams = 'msTeams',
}

export enum VideoView {
  pexip = 'pexip',
  zoom = 'zoom',
  msTeams = 'msTeams',
}

export type ButtonTypes =
  'Settings' |
  'Participants' |
  'Patient on Hold' |
  'Self View' |
  'Share Content' |
  'Video' |
  'Screen Shot' |
  'Invite' |
  'Chat' |
  'Mic' |
  'Doorbell' |
  'End Session' |
  'Camera Controls' |
  'Home';

export interface Listener {
  [key: string]: any[];
}

export interface CaregilitVideoUIConfig {
  sdk: VideoSDK;
  view: VideoView;
  pexipConfig?: PexipConfig;
  zoomMeetConfig?: ZoomMeetConfig;
  /**
   * Response from create/get session request
   * Needed for joining the SessionControl WebSocket events
   */
  sessionInfo?: CaregilitySession;
  /**
   * List of backend URL needed for various calls the lib makes
   */
  endpoints?: CaregilityEndpoints;
  /**
   * Caregility access token
   */
  accessToken?: string;
  /**
   * Toggle showing the header
   */
  showHeader?: boolean;
  /**
   * Show only selected buttons
   * Use empty [] to hide the footer
   * Use undefined to load default buttons
   */
  configFooterButtons?: ButtonTypes[];
  /**
   * Join call token used when joining from invitation
   */
  joinCallToken?: string;

  /**
   * Handler for starting new session from Invite Panel
   */
  onStartNewSession?: (deviceId?: number, deviceType?: DeviceType) => void;
}

export interface LocalSettingsModel {
  autoAnswer: boolean;
  defaultVideoId: string;
  defaultAudioId: string;
  defaultSpeakerId: string;
  speakerVolume: string;
}

export interface CameraMetadataFeatures {
  eko_enabled?: boolean;
}

export interface CameraMetadata {
  id: number;
  name: string;
  device_type: string;
  default_speed: number;
  default_output: number;
  default_input: number;
  default_bell: number;
  dial_out_type: 'SIP' | 'VMR' | 'H323' | undefined;
  bookmarks: CameraBookmarkModel[];
  can_focus: boolean;
  can_brightness: boolean;
  unique_id: string;
  stratus_billing_code: string | null;
  created_at: number;
  updated_at: number;
}

export interface ListData {
  id: number;
  name: string;
  device_type?: DeviceType;
  unique_id?: string;
  stratus_billing_code?: string | null;
}
export interface CareResponseData {
  list: ListData[];
  page: CaregilityPagination;
}

export interface OptionsData {
  label: string;
  value: number;
}
export interface CareOptionsData {
  options: OptionsData[];
  page: CaregilityPagination;
}

export interface RoomData {
  list: ListData[];
  page: CaregilityPagination;
}

export interface CameraSettingModel {
  speakerValue?: number;
  micValue?: number;
  bellValue?: number;
  brightnessValue?: number;
  speedValue?: number;
  focusValue?: number;
}
export interface CameraSettingProps extends CameraSettingModel {
  onSetCameraSpeaker: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraMic: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraBell: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraBrightness: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraSpeed: (cameraId: string, value: string, final: boolean) => void;
  onSetCameraFocus: (cameraId: string, value: string, final: boolean) => void;
}

export interface CameraBookmarkModel {
  name: string;
  internal_name: string;
}

export interface DeviceDetailsModel {
  address: string;
  can_brightness: boolean;
  can_focus: boolean;
  can_night_mode: boolean;
  device_type: string; // Probably we need to create enum for  Care Device Care Device,
  protocol: string; // Probably we need to create enum for  Care DeviceH.323
  features?: {
    eko_enabled: boolean;
  };
}

export enum CameraZoom {
  WIDE = 'wide',
  TELE = 'tele',
  STOP = 'stop',
}

export enum CameraPan {
  UP = 'up',
  DOWN = 'down',
  RIGHT = 'right',
  LEFT = 'left',
  UPLEFT = 'upleft',
  UPRIGHT = 'upright',
  DOWNLEFT = 'downleft',
  DOWNRIGHT = 'downright',
  STOP = 'stop',
}

export enum CameraCommand {
  START_CALL = 'api',
  END_CALL = 'final_end_call',
  PAN = 'pan',
  ZOOM = 'zoom',
  STOP_MOTION = 'stop',
  MUTE_PATIENT = 'mute_patient', // patient on hold
  UNMUTE_PATIENT = 'unmute_patient', // remove patient on hold
  SET_SPEAKER = 'set_speaker',
  SET_MICROPHONE = 'set_microphone',
  START_ALARM = 'play_alert',
  STOP_ALARM = 'stop_play_alert',
  ADJUST_BELL = 'adjust_bell', // sets camera bell
  RING_BELL = 'play_sound',
  NIGHT_VIEW = 'night_view',
  ADJUST_BRIGHTNESS = 'adj_brightness',
  RESET_BRIGHTNESS = 'reset_brightness',
  SET_SPEED = 'set_speed',
  SET_FOCUS = 'set_focus',
  GO_TO_BOOKMARK = 'go_to_bookmark',
  SET_BOOKMARK = 'set_as_bookmark',
  DELETE_BOOKMARK = 'delete_bookmark',
  GO_TO_POINT = 'go_to_point',
  REBOOT = 'reboot_os',
  REFRESH = 'reboot_camera',
  ZOOM_OUT = 'zoom_out',
}

export enum VideoState {
  InCall,
  InCallMidStep,
  BedsideExternal,
  IObserverExternal,
  None,
  Guest,
  Call,
}

export enum GuestType {
  SMS,
  EMAIL,
  CLIPBOARD,
  PSTN,
  SIP,
  H323,
}

export enum DeviceType {
  CareDevice = 'CARE_DEVICE',
  Standard = 'STANDARD',
  Mobile = 'MOBILE_DEVICE',
  APD = 'APD',
  Guest = 'GUEST',
}

export interface ZoomParticipant {
  userId: number;
  userName: string;
  muted: boolean;
  audio: string | null;
  isHost: boolean;
  isCoHost: boolean | string;
  isGuest: boolean;
  isHold: boolean;
  persistentID: string;
}

export type ServiceParticipant = PexipParticipant | ZoomParticipant;
