import { ChatMessage } from '@types';
import { RightCircleFilled } from '@ant-design/icons';
import {
  Button,
  Form,
  FormInstance,
  Input,
  List,
  Row,
  Select,
} from 'antd';
import React, { FC } from 'react';
import Message from './Message';

export interface ChatViewProps {
  isMobile: boolean;
  message: string;
  onMessageChange: (newMsg: string) => void;
  allMessages: ChatMessage[];
  currentUserId: string;
  rooms: { label: string, value: string }[];
  selectedRoom: string;
  setSelectedRoom: (roomId: string) => void;
  sendMessage: () => void;
  setFormRef: (form: FormInstance) => void;
}

/**
 * FC to visualize the chat view.
 * @param isMobile - boolean prop that determines the layout
 * @param message - currently typed message in the input
 * @param onMessageChange - callback for any user input
 * @param allMessages - all messages for selected room
 * @param currentUserId - an id to differentiate current user from other participants
 * @param rooms - all avaible participants (plus "All") the user can send messages to
 * @param selectedRoom - selected room from the dropdown.
 * @param setSelectedRoom - invoked when the user selects room from the dropdown
 * @param sendMessage - invoked when the user hits enter to send a message.
 * @param setFormRef - the controller needs ref to the form to clear the input field.
 */
const ChatView: FC<ChatViewProps> = ({
  isMobile,
  message,
  onMessageChange,
  allMessages,
  currentUserId,
  rooms,
  selectedRoom,
  setSelectedRoom,
  sendMessage,
  setFormRef,
}) => (
  <>
    <div className="vl-chat-main vl-invite-scroll" style={{ height: '400px' }}>
      <div className="vl-align-center">
        <List
          className="vl-chat-list"
          dataSource={allMessages}
          renderItem={(item) => item && (
            <List.Item
              className={`vl-list-item ${
                currentUserId === item.participantId
                  ? 'mine'
                  : ''
              }`}
            >
              <Message
                message={item.message}
                name={item.displayName}
                avatar={item.avatar}
                initials={item.initials}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
    <div className="vl-chat-footer">
      <hr className="vl-hr-invite" />
      <Row>
        <Form
          className="vl-chat-form"
          layout={isMobile ? 'horizontal' : 'inline'}
          onFinish={sendMessage}
          ref={setFormRef}
        >
          <Form.Item className="vl-grey" label="Chat to: ">
            <div className="vl-select-field">
              <Select
                getPopupContainer={(node) => node.parentNode}
                options={rooms}
                value={selectedRoom}
                onChange={setSelectedRoom}
                placeholder="Language"
              />
            </div>
          </Form.Item>
          {isMobile ? (
            <Form.Item
              name="msg"
              rules={[
                { required: true, message: 'Please input your message!' },
              ]}
            >
              <Input
                suffix={
                  (
                    <RightCircleFilled
                      style={{
                        fontSize: 16,
                        color: '#1890ff',
                      }}
                    />
                  )
                }
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="msg"
                rules={[
                  { required: true, message: 'Please input your message!' },
                ]}
                style={{ width: 'calc(100% - 145px)' }}
              >
                <Input
                  type="text"
                  value={message}
                  onChange={(event) => onMessageChange(event.target.value)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item style={{ marginRight: '0' }}>
                <Button
                  className="vl-btn vl-btn-blue"
                  type="primary"
                  htmlType="submit"
                  size="small"
                >
                  Send
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Row>
    </div>
  </>
);

export default ChatView;
