import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { MovableDiv, MovablePanel } from '../index';
import '@assets/styles/video.scss';

export interface VideoViewProps {
  isAps: boolean;
  videoContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  cameraVideoRef: (element: HTMLVideoElement) => void;
  presentationVideoRef: (element: HTMLVideoElement) => void;
  screenshareVideoRef: (element: HTMLVideoElement) => void;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  isSelfViewOn: boolean;
  isPresentationOn: boolean;
  isScreenshareOn: boolean;
  screenshotImgSrc: string | null;
  makeScreenshot: () => void;
  closeScreenshot: () => void;
  onCloseScreenshareClick: () => void;
  getVideoBoundingRect: () => DOMRect;
  onVideoDoubleClick: (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => void;
  onVideoMouseDown: (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => void;
  onVideoMouseUp: (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => void;
  connecting: boolean;
}

/**
 * VideoView - Handles the visual part of the VideoScene
 *
 * @param isAps - isAps
 * @param videoContainerRef - container ref
 * @param videoRef - video ref
 * @param cameraVideoRef - camera ref
 * @param presentationVideoRef - presentation video ref
 * @param screenshareVideoRef - self screen share ref
 * @param isSelfViewOn - isSelfViewOn
 * @param isPresentationOn - isPresentationOn
 * @param isScreenshareOn - isScreenshareOn
 * @param screenshotImgSrc - screenshotImgSrc
 * @param canvasRef - canvasRef
 * @param makeScreenshot - makeScreenshot
 * @param closeScreenshot - closeScreenshot
 * @param getVideoBoundingRect - getVideoBoundingRect
 * @param connecting - connecting state
 * @param onVideoDoubleClick - main video double click event handler
 * @param onVideoMouseDown - main video mouse down event handler
 * @param onVideoMouseUp - main video mouse up event handler
 * @returns VideoView
 */
const VideoView: FC<VideoViewProps> = ({
  isAps,
  videoContainerRef,
  videoRef,
  cameraVideoRef,
  presentationVideoRef,
  screenshareVideoRef,
  isSelfViewOn,
  isPresentationOn,
  isScreenshareOn,
  screenshotImgSrc,
  canvasRef,
  makeScreenshot,
  closeScreenshot,
  getVideoBoundingRect,
  connecting,
  onCloseScreenshareClick,
  onVideoDoubleClick,
  onVideoMouseDown,
  onVideoMouseUp,
}) => (
  <Row style={{ justifyContent: 'center' }}>
    <Col id="ppmtg-root">
      <div className="video-container" ref={videoContainerRef} style={isAps ? { position: 'absolute' } : {}}>
        <div className="main-video">
          <MovableDiv
            hidden={!isSelfViewOn}
            width={400}
            height={225}
            top={20}
            left={20}
            getBoundingClientRect={getVideoBoundingRect}
          >
            <video
              ref={cameraVideoRef}
              id="cameraVideo"
              playsInline
              autoPlay
            />
          </MovableDiv>
          {!isAps && (
          <MovableDiv
            hidden={!isPresentationOn}
            width={400}
            height={225}
            top={270}
            left={20}
            getBoundingClientRect={getVideoBoundingRect}
          >
            <video
              style={{ backgroundColor: 'blue' }}
              ref={presentationVideoRef}
              id="presentationVideo"
              playsInline
              autoPlay
            />
          </MovableDiv>
          )}
          {isAps && (
          <MovableDiv
            hidden={!isPresentationOn}
            fullScreen
            width={400}
            height={225}
            top={270}
            left={20}
            getBoundingClientRect={getVideoBoundingRect}
          >
            <video
              style={{ backgroundColor: 'blue', width: '100%' }}
              ref={presentationVideoRef}
              id="presentationVideo"
              playsInline
              autoPlay
            />
          </MovableDiv>
          )}
          <MovableDiv
            hidden={!isScreenshareOn}
            width={400}
            height={225}
            top={20}
            left={456}
            getBoundingClientRect={getVideoBoundingRect}
            onMovableDivCloseClick={onCloseScreenshareClick}
          >
            <video
              ref={screenshareVideoRef}
              id="screenshareVideo"
              playsInline
              autoPlay
            />
          </MovableDiv>
          {screenshotImgSrc && (
          <MovablePanel
            title="Screenshot"
            width={520}
            height={363}
            top={405}
            left={1}
            expandable
            reloadable
            hasDivider
            isClosable
            onReload={makeScreenshot}
            onClose={closeScreenshot}
          >
            <div className="vl-screenshot">
              <img
                id="video-screenshot"
                alt="Video screenshot"
                src={screenshotImgSrc}
              />
            </div>
          </MovablePanel>
          )}
          <video
            id="main-video"
            onDoubleClick={onVideoDoubleClick}
            onMouseDown={onVideoMouseDown}
            onMouseUp={onVideoMouseUp}
            onContextMenu={(e) => e.preventDefault()}
            hidden={connecting}
            ref={videoRef}
            autoPlay
            playsInline
          />
        </div>
      </div>
      <canvas ref={canvasRef} className="vl-screenshot-canvas" />
    </Col>
  </Row>
);

export default VideoView;
