import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CaregilityServiceAdapter } from '@adapters';
import { useIsMount } from '@hooks';
import {
  Speaker,
  Mic,
  Bell,
  Brightness,
  Speed,
  Focus,
} from '@atoms/CareDeviceData';
import { AdapterAtom } from '@atoms/Adapter';

/**
 * Hook for camera settings handlers
 */
export const useCameraSettingsHandler = () => {
  const isFirstMount = useIsMount();
  const [speaker, onSetCameraSpeaker] = useRecoilState(Speaker);
  const [mic, onSetCameraMic] = useRecoilState(Mic);
  const [bell, onSetCameraBell] = useRecoilState(Bell);
  const [brightness, onSetCameraBrightness] = useRecoilState(Brightness);
  const [speed, onSetCameraSpeed] = useRecoilState(Speed);
  const [focus, onSetCameraFocus] = useRecoilState(Focus);
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const cameraControl = adapter?.getCameraControlManager();

  const cameraMetadata = {
    default_speed: adapter?.getCameraMetadata().default_speed,
    default_output: adapter?.getCameraMetadata().default_output,
    default_input: adapter?.getCameraMetadata().default_input,
    default_bell: (adapter?.getCameraMetadata().default_bell ?? 0) * 20,
  };

  useEffect(() => {
    if (!isFirstMount) {
      cameraControl?.setCameraSpeaker(speaker.speakerValue);
    }
  }, [speaker.speakerValue]);

  useEffect(() => {
    if (!isFirstMount) {
      cameraControl?.setCameraMic(mic.micValue);
    }
  }, [mic.micValue]);

  useEffect(() => {
    if (!isFirstMount) {
      cameraControl?.setCameraBell(bell.bellValue);
    }
  }, [bell.bellValue]);

  useEffect(() => {
    if (!isFirstMount) {
      cameraControl?.setCameraBrightness(brightness.brightnessValue);
    }
  }, [brightness.brightnessValue]);

  useEffect(() => {
    if (!isFirstMount) {
      cameraControl?.setCameraSpeed(speed.speedValue);
    }
  }, [speed.speedValue]);

  useEffect(() => {
    if (!isFirstMount) {
      cameraControl?.setCameraFocus(focus.focusValue);
    }
  }, [focus.focusValue]);

  return {
    speaker,
    onSetCameraSpeaker,
    mic,
    onSetCameraMic,
    bell,
    onSetCameraBell,
    brightness,
    onSetCameraBrightness,
    speed,
    onSetCameraSpeed,
    focus,
    onSetCameraFocus,
    cameraMetadata,
    isCameraFocusAllowed: cameraControl?.isCameraFocusAllowed(),
    isAdjustBrightnessAllowed: cameraControl?.isAdjustBrightnessAllowed(),
  };
};
