import { CameraBookmarkModel, CameraPan, CameraZoom } from '@types';
import { InputRef, Modal } from 'antd';
import React, {
  ChangeEvent, FC, useEffect, useRef, useState,
} from 'react';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import showNotificationDialog, { MessageType } from '@components/common/notificationDialog';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AdapterAtom } from '@atoms/Adapter';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { CameraControlsOn } from '@atoms/VideoButtons';
import { CameraControlsView } from './CameraControlsView';

/**
 * Controller class bridge between user interactions and the manager
 *
 * @returns CameraControlsComponent
 */
export const CameraControlsComponent: FC = () => {
  const [pinInputValue, setPinInputValue] = useState('');
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const cameraControl = adapter?.getCameraControlManager();
  const [bookmarks, setBookmarks] = useState<CameraBookmarkModel[]>([]);
  const [expectsNavStop, setExpectsNavStop] = useState(false);
  const [expectsZoomStop, setExpectsZoomStop] = useState(false);
  const navImgRef = useRef<HTMLImageElement>();
  const inputRef = useRef<InputRef>();
  const [cameraControlsOn, switchCameraControlsOn] = useRecoilState(CameraControlsOn);

  // onMount
  useEffect(() => {
    const bookmarksChangeListener = (bms: CameraBookmarkModel[]) => {
      setBookmarks(bms);
    };
    if (cameraControl) {
      setBookmarks(adapter?.getCameraBookmarks() || []);
      adapter?.on(EventNames.onBookmarksChange, bookmarksChangeListener);
    }

    return () => {
      adapter?.off(EventNames.onBookmarksChange, bookmarksChangeListener);
    };
  }, [cameraControl]);

  /**
   * Sends camera's bookmark update to the manager
   *
   * @param bm clicked bookmark
   */
  const onBookmarkClick = (bm: CameraBookmarkModel) => {
    cameraControl?.cameraGoToBookmark(bm);
  };

  /**
   * Renders confirmation message and sends delete command on confirm
   *
   * @param bm bookmark we try to delete
   */
  const onBookmarkDelete = (bm: CameraBookmarkModel) => {
    Modal.confirm({
      title: `Are you sure you want to delete bookmark "${bm.name}"?`,
      icon: <QuestionCircleOutlined />,
      centered: true,
      onOk: () => {
        cameraControl?.deleteCameraBookmark(bm);
      },
    });
  };

  /**
   * renders confirmation message and sends update command on confirm
   * @param bm bookmark we try to update
   */
  const onBookmarkUpdate = (bm: CameraBookmarkModel) => {
    Modal.confirm({
      title: `Are you sure you want to update bookmark "${bm.name}"?`,
      icon: <QuestionCircleOutlined />,
      centered: true,
      onOk: () => {
        cameraControl?.updateBookmark(bm);
      },
    });
  };

  /**
   * triggers camera go home action
   */
  const onCameraHomeClick = () => {
    cameraControl?.cameraGoToHome();
  };

  /**
   * triggers camera movement in certain direction
   * @param event mouse event
   */
  const onNavigationMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const rect = navImgRef.current?.getBoundingClientRect();
    if (rect != null) {
      const x1 = event.clientX - rect.left;
      const y1 = event.clientY - rect.top;
      const x2 = rect.width / 2;
      const y2 = rect.height / 2;
      const angle = 180 + Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI);
      let direction: CameraPan = CameraPan.RIGHT;
      if (angle < 30) {
        direction = CameraPan.LEFT;
      } else if (angle < 60) {
        direction = CameraPan.UPLEFT;
      } else if (angle < 120) {
        direction = CameraPan.UP;
      } else if (angle < 150) {
        direction = CameraPan.UPRIGHT;
      } else if (angle < 210) {
        direction = CameraPan.RIGHT;
      } else if (angle < 240) {
        direction = CameraPan.DOWNRIGHT;
      } else if (angle < 300) {
        direction = CameraPan.DOWN;
      } else if (angle < 330) {
        direction = CameraPan.DOWNLEFT;
      } else if (angle <= 360) {
        direction = CameraPan.LEFT;
      }
      cameraControl?.moveCamera(direction);
      setExpectsNavStop(true);
    }
  };

  /**
   * stops the last sent camera movement event
   * @param event mouse event
   */
  const onNavigationMouseLeave = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (expectsNavStop) {
      setExpectsNavStop(false);
      cameraControl?.moveCamera(CameraPan.STOP);
    }
  };

  /**
   * handler for user pin input
   * @param event change event
   */
  const onPinInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPinInputValue(event.target.value);
  };

  /**
   * focuses the pin input
   */
  const onPinInputClick = () => {
    inputRef.current?.focus();
  };

  /**
   * triggers when user hits 'Enter' key while pin input is focused
   * sends add bookmark command to the manager and resets the input
   */
  const onPinInputEnterKey = () => {
    cameraControl?.addCameraBookmark(pinInputValue);
    setPinInputValue('');
  };

  /**
   * renders confirmation message and reboots the camera on confirm
   */
  const onRebootClick = () => {
    Modal.confirm({
      title: 'Soft Reboot',
      content: 'Your call will end and the system will be unavailable while it reboots.',
      icon: <QuestionCircleOutlined />,
      centered: true,
      okText: 'Reboot Now',
      onOk: () => {
        cameraControl?.rebootCamera();
      },
      cancelText: 'Cancel',
    });
  };

  /**
   * refreshes the camera
   */
  const onRefreshClick = () => {
    cameraControl?.refreshCamera();
  };

  /**
   * renders confirmation message and changes camera's home location on confirm
   */
  const onSetHomeClick = () => {
    Modal.confirm({
      title: 'Set Home Location',
      content: 'Are you sure you want change the Home location to this position!',
      icon: <ExclamationCircleOutlined />,
      okText: 'Cancel',
      cancelText: 'Set as Home',
      okButtonProps: {
        type: 'default',
      },
      cancelButtonProps: {
        type: 'primary',
      },
      centered: true,
      onCancel: () => {
        cameraControl?.updateCameraHome().then(() => {
          showNotificationDialog('New Home position is set.', null, MessageType.Success, 2);
        });
      },
    });
  };

  /**
   * sends zoom in command when the zoom in button is clicked
   * @param event mouse event
   */
  const onZoomInMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    cameraControl?.setCameraZoom(CameraZoom.TELE);
    setExpectsZoomStop(true);
  };

  /**
   * suspends the zoom in command
   * @param event mouse event
   */
  const onZoomInMouseLeave = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (expectsZoomStop) {
      cameraControl?.setCameraZoom(CameraZoom.STOP);
      setExpectsZoomStop(false);
    }
  };

  /**
   * sends zoom out command when the zoom out button is clicked
   * @param event mouse event
   */
  const onZoomOutMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    cameraControl?.setCameraZoom(CameraZoom.WIDE);
    setExpectsZoomStop(true);
  };

  /**
   * suspends the zoom out command
   * @param event mouse event
   */
  const onZoomOutMouseLeave = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (expectsZoomStop) {
      cameraControl?.setCameraZoom(CameraZoom.STOP);
      setExpectsZoomStop(false);
    }
  };

  if (!cameraControl) {
    return null;
  }

  return (
    <CameraControlsView
      visible={cameraControlsOn}
      onClose={() => switchCameraControlsOn(false)}
      loading={false}
      setNavigationImgRef={(img: HTMLImageElement) => { navImgRef.current = img; }}
      onBookmarkClick={onBookmarkClick}
      onBookmarkDelete={onBookmarkDelete}
      onBookmarkUpdate={onBookmarkUpdate}
      onCameraHomeClick={onCameraHomeClick}
      onNavigationMouseDown={onNavigationMouseDown}
      onNavigationMouseLeave={onNavigationMouseLeave}
      onPinInputChange={onPinInputChange}
      onPinInputClick={onPinInputClick}
      onPinInputEnterKey={onPinInputEnterKey}
      onRebootClick={onRebootClick}
      onRefreshClick={onRefreshClick}
      onSetHomeClick={onSetHomeClick}
      onZoomInMouseDown={onZoomInMouseDown}
      onZoomInMouseLeave={onZoomInMouseLeave}
      onZoomOutMouseDown={onZoomOutMouseDown}
      onZoomOutMouseLeave={onZoomOutMouseLeave}
      setPinInputRef={(ir: InputRef) => { inputRef.current = ir; }}
      showReboot={cameraControl?.isCameraRebootAllowed()}
      pinInputValue={pinInputValue}
      bookmarks={bookmarks}
    />
  );
};
