import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { PatientOnHold } from '@atoms/VideoButtons';
import { EventManager } from '@managers';

/**
 * Listen for event session start and switch patient on hold
 *
 */
export const useStartNewSession = (): void => {
  const setPatientOnHold = useSetRecoilState(PatientOnHold);

  useEffect(() => {
    /**
     * Handler for new session listener, switches patient on hold state to true
     */
    const handleOnNewSession = (): void => {
      setPatientOnHold(true);
    };

    EventManager.subscribe('onStartNewSession', handleOnNewSession);

    return () => {
      EventManager.unsubscribe('onStartNewSession', handleOnNewSession);
    };
  }, [setPatientOnHold]);
};
