// import { CaregilityServiceAdapter } from './CaregilityServiceAdapter';
//
// export class MsTeamsServiceAdapter extends CaregilityServiceAdapter {
//
// }

// TODO: Implement
export class MsTeamsServiceAdapter {

}
