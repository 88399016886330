import React, { FC, useState, useEffect } from 'react';
import {
  Dropdown, Menu, Modal, Tooltip,
} from 'antd';
import lockRoomOn from '@assets/images/header/lock_room_on.svg';
import lockRoomOff from '@assets/images/header/lock_room.svg';
import nightViewOnImg from '@assets/images/header/night_view_on.svg';
import nightViewOffImg from '@assets/images/header/night_view.svg';
import logoSvg from '@assets/images/header/logo_header.svg';
import { useRecoilValue } from 'recoil';
import { AdapterAtom } from '@atoms/Adapter';
import { Button } from 'antd/lib/radio';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Session, CaregilitySession, FavoriteModel, ListData,
} from '@types';
import { EventNames } from '@adapters';
import { FavoriteStar } from '@components/Invite/FavoriteStar';
import { headerFavoriteStar, headerFavoriteStarFilled } from '@assets/styles/antd-custom-styles';
import { useTimer } from './useTimer';

/**
 * Header - component for controls and settings
 */
export const Header: FC = () => {
  const adapter = useRecoilValue(AdapterAtom);
  const [session, setSession] = useState<Session | undefined>();
  const [nightViewOn, setNightViewOn] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [favoriteData, setFavoriteData] = useState<FavoriteModel | null | undefined>(null);
  const time = useTimer();

  useEffect(() => {
    if (adapter) {
      adapter.getFavoritesManager().getCurrentDeviceFavoriteStatus();
      setSession(adapter.getSession());

      const sessionListener = (newSession: Session) => {
        setSession(newSession);
      };
      const favoriteStatusListener = () => {
        setFavoriteData(adapter?.currentDeviceFavoriteStatus);
        setIsFavorite(!!adapter.currentDeviceFavoriteStatus);
      };

      adapter.on(EventNames.onSessionChange, sessionListener);
      adapter?.on(EventNames.onCurrentDeviceFavoriteChange, favoriteStatusListener);
      return () => {
        adapter.off(EventNames.onSessionChange, sessionListener);
        adapter?.off(EventNames.onCurrentDeviceFavoriteChange, favoriteStatusListener);
      };
    }
    return () => {};
  }, [adapter]);

  const sessionData = adapter?.config.sessionInfo;
  const deviceData: ListData = {
    name: sessionData?.device_name as string,
    id: favoriteData?.id as number,
    device_type: sessionData?.device_details?.device_type,
  };
  const metadata = `${sessionData?.customer_id}/${sessionData?.facility_id}/${sessionData?.unit_id}/${sessionData?.device_id}`;

  if (adapter) {
    const isHost = adapter.getSelfParticipant().sessionManager;
    /**
     * invokes adapter.endCall method
     */
    const endCall = () => {
      adapter.endCall();
    };
    /**
     * a popup to confirm end session for all users
     */
    const renderConfirmEndSessionForAll = () => {
      Modal.confirm({
        title: 'This is a notification message',
        content: 'Are you sure you would like to end session for ALL participants?',
        icon: <QuestionCircleOutlined />,
        centered: true,
        okText: 'Yes',
        onOk: () => {
          endCall();
        },
        cancelText: 'No',
      });
    };

    /**
     * Extracts information to be displayed from session
     * @param caregilitySession the session
     * @returns label containing info about the session
     */
    const getPatientInfo = (caregilitySession: CaregilitySession | undefined) => {
      if (!caregilitySession) {
        return 'No camera info available';
      }
      try {
        switch (caregilitySession.device_details?.device_type) {
          case 'CARE_DEVICE':
            return (`${caregilitySession.facility}: ${caregilitySession.unit} - ${caregilitySession.device_name}`);
          case 'GUEST':
            return caregilitySession.device_id;
          default:
            return caregilitySession.display_name;
        }
      } catch (error) {
        return 'No camera info available';
      }
    };

    const cameraControlManager = adapter?.getCameraControlManager();

    return (
      <div className="vl-header">
        <div className="header-section-left">
          <img alt="" className="vl-header-logo" src={logoSvg} />
        </div>
        <div className="header-section-center">
          <span className="vl-incall-info">{getPatientInfo(adapter.config.sessionInfo)}</span>
          <FavoriteStar
            isFavorite={isFavorite}
            favorite={favoriteData}
            roomData={deviceData}
            metadata={metadata}
            filledStyle={headerFavoriteStarFilled}
            outlinedStyle={headerFavoriteStar}
          />
          <Tooltip title={session?.isLocked ? 'Unlock conference call' : 'Lock conference call'} placement="bottom">
            <img
              alt=""
              className="vl-center-img vl-action-image"
              src={session?.isLocked ? lockRoomOn : lockRoomOff}
              onClick={() => {
                adapter.lockSession(!session?.isLocked);
              }}
            />
          </Tooltip>
          {cameraControlManager && (
            <Tooltip title={nightViewOn ? 'Switch off night mode' : 'Switch on night mode'} placement="bottom">
              <img
                alt=""
                className="vl-center-img vl-action-image"
                src={nightViewOn ? nightViewOnImg : nightViewOffImg}
                onClick={() => {
                  setNightViewOn(!nightViewOn);
                  cameraControlManager.setCameraNightView(!nightViewOn);
                }}
              />
            </Tooltip>
          )}
          <div className="vl-timer">
            <span>{time}</span>
          </div>
        </div>
        <div
          className="header-section-right"
          style={
            {
              paddingRight: '50px',
            }
          }
        >
          <Dropdown
            overlay={(
              <Menu className="vl-header-menu">
                {isHost ? (
                  <Menu.Item key="0" onClick={() => renderConfirmEndSessionForAll()}>
                    End Session for ALL
                  </Menu.Item>
                ) : (
                  <Menu.Item key="1" onClick={() => endCall()}>
                    Leave Session
                  </Menu.Item>
                )}
              </Menu>
            )}
            placement="bottomLeft"
          >
            <Button
              className="vl-btn vl-btn-red"
              style={{ margin: '5px 0 0 40px' }}
            >
              End Session
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }
  return null;
};
