import { atom } from 'recoil';

export const VideoOn = atom({
  key: 'VideoButtons.VideoOn',
  default: true,
});

export const AudioOn = atom({
  key: 'VideoButtons.AudioOn',
  default: true,
});

export const ScreenshareOn = atom({
  key: 'VideoButtons.ScreenshareOn',
  default: false,
});

export const SelfViewOn = atom({
  key: 'VideoButtons.SelfViewOn',
  default: false,
});

export const DoorbellOn = atom({
  key: 'VideoButtons.DoorbellOn',
  default: false,
});

export const ScreenshotOn = atom({
  key: 'VideoButtons.ScreenshotOn',
  default: false,
});

export const SettingsOn = atom({
  key: 'VideoButtons.SettingsOn',
  default: false,
});

export const InviteOn = atom({
  key: 'VideoButtons.InviteOn',
  default: false,
});

export const ChatOn = atom({
  key: 'VideoButtons.ChatOn',
  default: false,
});

export const EndSessionOn = atom({
  key: 'VideoButtons.EndSessionOn',
  default: false,
});

export const CameraControlsOn = atom({
  key: 'VideoButtons.CameraControlsOn',
  default: false,
});

export const PatientOnHold = atom({
  key: 'VideoButtons.PatientOnHold',
  default: false,
});

export const HomeOn = atom({
  key: 'VideoButtons.HomeOn',
  default: false,
});

export const ParticipantsOn = atom({
  key: 'VideoButtons.ParticipantsOn',
  default: false,
});
