import { ZoomMtg } from '@zoomus/websdk';
import { Participant } from '@types';
import { SessionControlManager } from './SessionControlManager';

export class ZoomSessionControlManager extends SessionControlManager {
  /**
   * Attempts to find a participant in zoom list using SessionControl username
   *
   * @param userName - username
   */
  findParticipantInZoom(userName: string): Promise<{ userId: number }> {
    return new Promise((resolve, reject) => {
      ZoomMtg.getAttendeeslist({
        success: (res: any) => {
          const participantFound = res
            .result
            .attendeesList
            .find((entry: { userName: string; }) => entry.userName === userName);

          if (participantFound) {
            resolve({ userId: Number(participantFound.userId) });
          }

          reject(new Error('UserNotFound'));
        },
        error: (err: any) => {
          reject(err);
        },
      });
    });
  }

  /**
   * Remove participant override using zoom specific function
   *
   * @param participant - participant
   */
  async removeParticipant(participant: Participant): Promise<void> {
    try {
      const { userId } = await this.findParticipantInZoom(participant.displayName || '');

      ZoomMtg.expel({
        userId,
        success: () => super.removeParticipant(participant),
        error: (e: any) => {
          console.log('[ZoomSessionControlManager] ZoomMtg.expel', e, participant);
        },
      });
    } catch (e: any) {
      console.log('[ZoomSessionControlManager] removeParticipant', e, participant);
    }
  }

  /**
   * Mute participant override using zoom specific function
   *
   * @param participant - participant
   */
  async muteParticipant(participant: Participant): Promise<void> {
    try {
      const { userId } = await this.findParticipantInZoom(participant.displayName || '');

      ZoomMtg.mute({
        userId,
        mute: !participant.isMuted,
        success: () => super.muteParticipant(participant),
        error: (e: any) => {
          console.log('[ZoomSessionControlManager] ZoomMtg.mute', e, participant);
        },
      });
    } catch (e) {
      console.log('[ZoomSessionControlManager] muteParticipant', e, participant);
    }
  }
}
