import {
  CameraControlManager, EventManager,
  SessionControlManager, FavoritesManager,
  CareDevicesManager, SearchManager,
} from '@managers';
import { defaultPagination } from '@utils/helpers';
import {
  ChatRoom, Participant, Session, Listener, CaregilityFavorites,
  CaregilitVideoUIConfig, CameraBookmarkModel, CameraMetadata, FavoriteData,
  CareOptionsData, CliniciansData, DevicesData, RoomData, VideoSDK,
  FavoriteModel, ServiceParticipant, DeviceType,
} from '@types';
import { ZoomSessionControlManager } from '@managers/ZoomSessionControlManager';

export type LocalStream = MediaStream | undefined;

export enum EventNames {
  onConnect = 'onConnect',
  onSelfScreenshareConnected = 'onSelfScreenshareConnected',
  onSelfScreenshareDisconnected = 'onSelfScreenshareDisconnected',
  onScreenShare = 'onScreenShare',
  onScreenShareConnected = 'onScreenShareConnected',
  onScreenShareDisconnected = 'onScreenShareDisconnected',
  onChatRoomsUpdated = 'onChatRoomsUpdated',
  onParticipantsChange = 'onParticipantsChange',
  onSessionChange = 'onSessionChange',
  onBookmarksChange = 'onBookmarksChange',
  onFavoriteCliniciansChange = 'onFavoriteCliniciansChange',
  onFavoriteDevicesChange = 'onFavoriteDevicesChange',
  onCurrentDeviceFavoriteChange = 'onCurrentDeviceFavoriteChange',
  onCliniciansChange = 'onCliniciansChange',
  onDevicesChange = 'onDevicesChange',
  onCustomersChange = 'onCustomersChange',
  onFacilitiesChange = 'onFacilitiesChange',
  onUnitsChange = 'onUnitsChange',
  onRoomDevicesChange = 'onRoomDevicesChange',
}

export abstract class CaregilityServiceAdapter {
  config!: CaregilitVideoUIConfig;

  #sessionControlManager!: SessionControlManager;

  #cameraControlManager!: CameraControlManager;

  #favoritesManager!: FavoritesManager;

  #careDevicesManager!: CareDevicesManager;

  #searchManager!: SearchManager;

  participants: Map<string | number, Participant> = new Map();

  session: Session = { isLocked: false };

  rooms: { [participantId: string]: ChatRoom } = {};

  favorites: CaregilityFavorites = {
    clinicians: {
      list: {},
      pagination: { ...defaultPagination },
      loading: false,
    },
    devices: {
      list: {},
      pagination: { ...defaultPagination },
      loading: false,
    },
  };

  currentDeviceFavoriteStatus: FavoriteModel | null = null;

  clinicians: CliniciansData = {
    list: [],
    pagination: { ...defaultPagination },
    loading: false,
  };

  devices: DevicesData = {
    list: [],
    loading: false,
  };

  cameraMetadata: CameraMetadata = {
    id: 0,
    name: '',
    default_speed: 10,
    default_output: 10,
    default_input: 10,
    default_bell: 10,
    device_type: '',
    bookmarks: [],
    can_focus: false,
    can_brightness: false,
    unique_id: '',
    stratus_billing_code: null,
    created_at: 0,
    updated_at: 0,
    dial_out_type: undefined,
  };

  customers: CareOptionsData = {
    options: [],
    page: { ...defaultPagination },
  };

  facilities: CareOptionsData = {
    options: [],
    page: { ...defaultPagination },
  };

  units: CareOptionsData = {
    options: [],
    page: { ...defaultPagination },
  };

  roomDevices: CareOptionsData = {
    options: [],
    page: { ...defaultPagination },
  };

  roomFullData: RoomData = {
    list: [],
    page: { ...defaultPagination },
  };

  listeners: Listener = {};

  protected constructor(config: CaregilitVideoUIConfig) {
    this.config = config;

    if (this.config.sessionInfo) {
      this.session.isLocked = !!this.config.sessionInfo.locked;
    }
    if (this.config.accessToken
      && this.config.endpoints
      && this.config.sessionInfo
    ) {
      if (this.config.sdk === VideoSDK.zoom) {
        this.#sessionControlManager = new ZoomSessionControlManager(this);
      } else {
        this.#sessionControlManager = new SessionControlManager(this);
      }
      this.#cameraControlManager = new CameraControlManager(this);
      this.#favoritesManager = new FavoritesManager(this);
      this.#careDevicesManager = new CareDevicesManager(this);
      this.#searchManager = new SearchManager(this);
    } else if (this.config.joinCallToken
      && this.config.endpoints
      && this.config.sessionInfo
      && !this.config.accessToken
    ) {
      this.#sessionControlManager = new SessionControlManager(this);
    }
  }

  /**
   * Returns SessionControlManager
   */
  getSessionControlManager(): SessionControlManager {
    return this.#sessionControlManager;
  }

  /**
   * Returns CameraControlManager
   */
  getCameraControlManager(): CameraControlManager {
    return this.#cameraControlManager;
  }

  /**
   * Returns FavoritesManager
   */
  getFavoritesManager(): FavoritesManager {
    return this.#favoritesManager;
  }

  /**
   * Returns CareDevicesManager
   */
  getCareDevicesManager(): CareDevicesManager {
    return this.#careDevicesManager;
  }

  /**
   * Returns SearchManager
   */
  getSearchManager(): SearchManager {
    return this.#searchManager;
  }

  /**
   * Returns the current user id
   */
  getMyId(): string {
    return this.config?.sessionInfo?.participant?.id || '';
  }

  /**
   * Returns true if the host is APS
   */
  isAPS(): boolean {
    return this.config?.sessionInfo?.participant?.aps || false;
  }

  /**
   * Listen for the events and handle them
   *
   * @param event - event
   * @param listener - listener function
   */
  on(event: string, listener: (payload: any) => void): void {
    if (this.listeners[event]) {
      this.listeners[event].push(listener);
    } else {
      this.listeners[event] = [listener];
    }
  }

  /**
   * Remove the listener
   *
   * @param event - event
   * @param listener - listener function
   */
  off(event: string, listener: (payload: any) => void): void {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter((currentListener) => currentListener !== listener);
    }
  }

  /**
   * Returns all participants
   */
  getAllParticipants(): Participant[] {
    return [...this.participants.values()];
  }

  /**
   * Returns current user participant
   */
  getSelfParticipant(): Participant {
    return this.participants.get(this.getMyId()) || {};
  }

  /**
   * Gets the session
   */
  getSession(): Session {
    return this.session;
  }

  /**
   * Returns the chat rooms
   */
  getChatRooms(): { [participantId: string]: ChatRoom } {
    return this.rooms;
  }

  /**
   * Returns the camera bookmarks
   */
  getCameraBookmarks(): CameraBookmarkModel[] {
    return this.cameraMetadata.bookmarks;
  }

  /**
   * Returns the camera metadata
   */
  getCameraMetadata(): CameraMetadata {
    return this.cameraMetadata;
  }

  /**
   * Returns favorite clinicians
   */
  getFavoritesClinicians(): FavoriteData {
    return this.favorites.clinicians;
  }

  /**
   * Returns favorite devices
   */
  getFavoritesDevices(): FavoriteData {
    return this.favorites.devices;
  }

  /**
   * Returns current device favorite status
   */
  getCurrentDeviceFavoriteStatus(): FavoriteModel | null {
    return this.currentDeviceFavoriteStatus;
  }

  /**
   * Returns the Customers data
   */
  getCustomers(): CareOptionsData {
    return this.customers;
  }

  /**
   * Returns the Facilities data
   */
  getFacilities(): CareOptionsData {
    return this.facilities;
  }

  /**
   * Returns the Units data
   */
  getUnits(): CareOptionsData {
    return this.units;
  }

  /**
   * Returns the Room devices data
   */
  getRoomDevices(): CareOptionsData {
    return this.roomDevices;
  }

  /**
   * Returns Clinicians list (from search)
   */
  getClinicians(): CliniciansData {
    return this.clinicians;
  }

  /**
   * Returns Devices list (from search)
   */
  getDevices(): DevicesData {
    return this.devices;
  }

  /**
   * Mutes/Unmute self video
   *
   * @param isMute - mute/unmute flag
   */
  muteSelfVideo(isMute: boolean): void {
    EventManager.dispatchEvent('onMuteSelfVideo', { isMute });
  }

  /**
   * Mutes participant video
   *
   * @param participant - participant
   */
  muteParticipantVideo(participant: Participant): void {
    EventManager.dispatchEvent('onMuteParticipantVideo', participant);
  }

  endCall(): Promise<void> | void {
    if (this.getSessionControlManager()) {
      this.getSessionControlManager()
        .endSession()
        .then(() => {
          EventManager.dispatchEvent('onCallEnd', !!this.getSelfParticipant().sessionManager);
        })
        .catch((e) => {
          console.log('[] endCall => endSession Error: ', e);
          EventManager.dispatchEvent('onCallEnd', !!this.getSelfParticipant().sessionManager);
        });
    } else {
      EventManager.dispatchEvent('onCallEnd', !!this.getSelfParticipant().sessionManager);
    }
  }

  /**
   * Lock/Unlock session request
   *
   * @param action - action
   */
  lockSession(action: boolean): void {
    this.#sessionControlManager.lockSession(action);
  }

  /**
   * Starts new session
   *
   * @param deviceId - device id
   * @param deviceType - deviceType
   */
  startNewSession(deviceId?: number, deviceType?: DeviceType): void {
    EventManager.dispatchEvent('onStartNewSession', {});
    this.config?.onStartNewSession?.(deviceId, deviceType);
  }

  /**
   * Handler for specific service participant create event
   *
   * @param participant - participant
   */
  onServiceParticipantCreate(participant: ServiceParticipant): void {
    EventManager.dispatchEvent('onServiceParticipantCreate', participant);
  }

  /**
   * Handler for specific service participant update event
   *
   * @param participant - participant
   */
  onServiceParticipantUpdate(participant: ServiceParticipant): void {
    EventManager.dispatchEvent('onServiceParticipantUpdate', participant);
  }

  /**
   * Handler for specific service participant delete event
   *
   * @param participant - participant
   */
  onServiceParticipantDelete(participant: ServiceParticipant): void {
    EventManager.dispatchEvent('onServiceParticipantDelete', participant);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onJoinSuccess(cb: () => void): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onUnmute(cb: () => void): void {}
  abstract init(): Promise<void> | void;
  abstract cleanup(): Promise<void> | void;
  abstract startCall(): Promise<void> | void;
  abstract handleScreenShare(setting?: string): void;
  abstract getLocalStream(): LocalStream;
  abstract getMpToken(): string;
}
