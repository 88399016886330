import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const LocalSettings = atom({
  key: 'LocalSettingsModel',
  default: {
    autoAnswer: false,
    defaultAudioId: '',
    defaultSpeakerId: '',
    defaultVideoId: '',
    speakerVolume: '10',
  },
  effects_UNSTABLE: [persistAtom],
});
