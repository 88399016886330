import React, {
  CSSProperties, FC,
} from 'react';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useMovable } from '@hooks';

export interface MovableDivProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  fullScreen?: boolean;
  hidden?: boolean;
  getBoundingClientRect: () => DOMRect;
  width: number;
  height: number;
  top?: number;
  left?: number;
  onMovableDivCloseClick?: () => void;
}

// TODO: Check if it's possible to transfer this to the sass file
const fullscreenIconCss: CSSProperties = {
  fontSize: '30px',
  color: '#23bfd8',
  position: 'absolute',
  zIndex: 3,
  bottom: 5,
  right: 5,
};

// TODO: Check if it's possible to transfer this to the sass file
const fullscreenCloseIconCss: CSSProperties = {
  fontSize: '30px',
  color: '#23bfd8',
  position: 'absolute',
  zIndex: 3,
  top: 5,
  right: 5,
  cursor: 'pointer',
};

/**
 * MovableDiv Component
 *
 * @param props - MovableDivProps
 * @constructor
 */
export const MovableDiv: FC<MovableDivProps> = (props) => {
  const {
    fullScreen, top, left, width, height, hidden, children,
    getBoundingClientRect, onMovableDivCloseClick,
  } = props;
  const {
    oldY, oldX, draggable, container, state, setState,
  } = useMovable({
    top: top || 0,
    left: left || 0,
    fullscreen: fullScreen || false,
  });

  /**
   * Executes on mouse move, calculates the position of the element
   *
   * @param event mouse event
   */
  const onMouseMove = (event: React.MouseEvent): void => {
    if (!state.fullscreen) {
      event.preventDefault();

      if (draggable.current) {
        const deltaX = oldX.current - event.pageX;
        const deltaY = oldY.current - event.pageY;
        oldX.current = event.pageX;
        oldY.current = event.pageY;
        let newTop = state.top - deltaY;
        let newLeft = state.left - deltaX;

        const parentRect = getBoundingClientRect();
        const currentRect = container.current?.getBoundingClientRect() ?? new DOMRect();
        const widthLimit = parentRect.width - currentRect.width;
        const heightLimit = parentRect.height - currentRect.height;

        if (newLeft < 0) {
          newLeft = 0;
        }

        if (newLeft > widthLimit) {
          newLeft = widthLimit;
        }

        if (newTop < 0) {
          newTop = 0;
        }

        if (newTop > heightLimit) {
          newTop = heightLimit;
        }

        setState({
          ...state,
          top: newTop,
          left: newLeft,
        });
      }
    }
  };

  /**
   * Executes on mouse down
   *
   * @param event - mouse event
   */
  const onMouseDown = (event: React.MouseEvent): void => {
    if (!state.fullscreen) {
      event.preventDefault();
      oldX.current = event.pageX;
      oldY.current = event.pageY;
      draggable.current = true;
    }
  };

  /**
   * Executes on mouse up event
   *
   * @param event - mouse event
   */
  const onMouseUp = (event: React.MouseEvent): void => {
    if (!state.fullscreen) {
      event.preventDefault();
      draggable.current = false;
    }
  };

  /**
   * Executes on double click
   *
   * @param event - mouse event
   */
  const onDoubleClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    setState((prevState) => ({
      ...prevState,
      fullscreen: !prevState.fullscreen,
    }));
  };

  if (hidden) {
    return null;
  }

  return (
    <div
      className="vl-movableDiv"
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onDoubleClick={onDoubleClick}
      ref={container}
      style={{
        position: 'absolute',
        zIndex: 2,
        cursor: 'move',
        width: state.fullscreen ? '100%' : width,
        height: state.fullscreen ? '100%' : height,
        top: state.fullscreen ? 0 : state.top,
        left: state.fullscreen ? 0 : state.left,
      }}
      role="button"
      tabIndex={0}
    >
      {children}
      {!state.fullscreen && (
        <span onClick={onDoubleClick}>
          <FullscreenOutlined style={fullscreenIconCss} />
        </span>
      )}
      {(state.fullscreen && !fullScreen) && (
        <span onClick={onDoubleClick}>
          <FullscreenExitOutlined style={fullscreenIconCss} />
        </span>
      )}
      {onMovableDivCloseClick && (
        <span onClick={onMovableDivCloseClick}>
          <CloseOutlined style={fullscreenCloseIconCss} />
        </span>
      )}
    </div>
  );
};

MovableDiv.defaultProps = {
  fullScreen: false,
  hidden: false,
  top: 0,
  left: 0,
  onMovableDivCloseClick: undefined,
};
