import React, { FC, useEffect, useState } from 'react';
import {
  ButtonTypes,
} from '@types';
import { useVideoButtonsHandler } from '@hooks';
import { CaregilityServiceAdapter } from '@adapters';
import { useSetRecoilState } from 'recoil';
import { AdapterAtom } from '@atoms/Adapter';
import { LeaveSessionButton } from '@components/Header/LeaveSessionButton';
import {
  Header, Footer,
} from '../index';
import { VideoComponent } from '../Video/VideoComponent';
import { useVideoHandlers } from '../Video/hooks/useVideoHandlers';
import 'antd/dist/antd.css';

export interface CaregilityVideoSceneProps {
  adapter: CaregilityServiceAdapter;
  configFooterButtons?: ButtonTypes[];
  cameraId: string;
  showHeader?: boolean,
  joinCallToken?: string;
}

/**
 * CaregilityVideoScene - Main component for Caregility Video Scene
 *
 * @param adapter - CaregilityServiceAdapter
 * @param showHeader - boolean
 * @param configFooterButtons - ButtonTypes[]
 * @param cameraId - cameraId
 * @param joinCallToken - string
 * @constructor
 */

const CaregilityVideoScene: FC<CaregilityVideoSceneProps> = ({
  adapter,
  configFooterButtons,
  cameraId,
  showHeader,
  joinCallToken,
}) => {
  const [isAdapterInit, setAdapterInit] = useState<boolean>(false);
  const videoButtonsHandler = useVideoButtonsHandler(adapter);
  const videoHandlers = useVideoHandlers(
    adapter,
    10,
    undefined,
  );
  const setAdapter = useSetRecoilState(AdapterAtom);
  const isAps = adapter.isAPS();

  useEffect(() => {
    if (adapter) {
      Promise.resolve(adapter.init()).then(() => {
        setAdapterInit(true);
        setAdapter(adapter);
      }).catch((error) => {
        console.error('[CaregilityVideoScene] Error: Pexip failed to init!', error);
      });
    }
  }, [adapter]);

  useEffect(() => {
    if (isAdapterInit) {
      videoHandlers.makeCall();
    }
  }, [isAdapterInit]);

  useEffect(() => () => {
    Promise.resolve(adapter.cleanup())
      .then()
      .catch(((error) => {
        console.error(error);
      }));
  }, []);

  return (
    <>
      {joinCallToken && (
      <LeaveSessionButton />
      )}
      {showHeader && (<Header />)}
      <VideoComponent {...videoHandlers.componentProps} isAps={isAps} />
      {((Array.isArray(configFooterButtons) && configFooterButtons.length > 0) || (!configFooterButtons))
        && (
          <Footer
            onShareContentClick={() => {
              videoButtonsHandler.switchScreenshareOn(!videoButtonsHandler.screenshareOn);
            }}
            configFooterButtons={configFooterButtons}
            cameraId={cameraId}
          />
        )}
    </>
  );
};

CaregilityVideoScene.defaultProps = {
  configFooterButtons: undefined,
  showHeader: false,
  joinCallToken: '',
};

export default CaregilityVideoScene;
