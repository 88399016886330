import { useState, useRef } from 'react';

export interface MovableElementState {
  top: number;
  left: number;
  fullscreen: boolean;
}

/**
 * Wraps up common logic in a hook
 *
 * @param initialState - the initial state
 */
export const useMovable = (initialState: MovableElementState) => {
  const oldX = useRef<number>(0);
  const oldY = useRef<number>(0);
  const draggable = useRef<boolean>(false);
  const container = useRef<HTMLDivElement>(null);
  const [state, setState] = useState(initialState);

  return {
    oldX, oldY, draggable, container, state, setState,
  };
};
