import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { Participant } from '@types';
import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Attaches DOM listeners that simulate user interactions to pin certain participant
 * @param adapter CaregilityServiceAdapter
 * @returns enableDblClickEvent flag to notify the parent to let certain dbl click events pass
 */
export const useZoomPin = (
  adapter: CaregilityServiceAdapter,
): MutableRefObject<boolean> => {
  const enableDblClickEvent = useRef<boolean>(false);
  const pinnedParticipant = useRef<Participant>();
  const dblClickObserver = useRef<MutationObserver>();
  const multiViewObserver = useRef<MutationObserver>();

  /**
   * Attaches modification listeners to the participants avatars bar.
   * And waits until certain avatar is added i.e. the cb returns "true".
   * @param name participant's display name
   * @param cb the dbl click function which is defined later
   */
  const attachDblClickObserver = (name: string, cb: (str: string) => boolean) => {
    if (dblClickObserver.current) {
      dblClickObserver.current.disconnect();
      dblClickObserver.current = undefined;
    }
    const activeContainerArray = document.getElementsByClassName('speaker-bar-container__horizontal-view-wrap');
    if (activeContainerArray && activeContainerArray.length) {
      dblClickObserver.current = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            if (cb(name)) {
              dblClickObserver.current?.disconnect();
              dblClickObserver.current = undefined;
            }
          }
        });
      });
      const observerConfig = { childList: true };
      dblClickObserver.current.observe(activeContainerArray[0], observerConfig);
    }
  };

  /**
   * Searches for participant's avatar and dbl clicks it
   * @param name participant's display name
   */
  const dblClickPart = (name: string) => {
    const xpath = `//*/div[contains(@class, "speaker-bar-container__horizontal-view-wrap") and .//span[contains(text(), "${name}")]]/*/div[contains(@class, "video-avatar__avatar") and .//span[contains(text(), "${name}")]]`;
    const avatar = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null,
    ).singleNodeValue;
    if (avatar) {
      enableDblClickEvent.current = true;
      const event = new MouseEvent('dblclick', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      avatar.dispatchEvent(event);
      enableDblClickEvent.current = false;
    }
    return !!avatar;
  };

  /**
   * Searches for "Remove Pin" button
   */
  const getRemovePinButton = () => {
    const xpath = '//*/button[contains(text(), "Remove Pin")]';
    const button = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null,
    ).singleNodeValue;
    return button as HTMLButtonElement;
  };

  /**
   * Pins participant if not already pinned
   * @param participant Participant or undefined if noone should be pinned
   */
  const pinParticipant = (participant: Participant | undefined) => {
    if (document.getElementsByClassName('speaker-active-container__wrap').length > 0) {
      const removePinButton = getRemovePinButton();
      if (participant === undefined) { // no participant should be pinned
        if (removePinButton) {
          removePinButton.click();
        }
      } else {
        if (removePinButton && pinnedParticipant.current?.id === participant.id) { // participant already pinned
          return;
        }
        removePinButton?.click();
        const partName = participant.displayName || participant.email || '';

        if (!dblClickPart(partName)) { // can't find avatar
          attachDblClickObserver(partName, dblClickPart);
        } else if (dblClickObserver.current) { // all good. clear listeners if any
          dblClickObserver.current.disconnect();
          dblClickObserver.current = undefined;
        }
      }
    }
    pinnedParticipant.current = participant;
  };

  useEffect(() => {
    if (adapter) {
      const deviceName = adapter.config?.sessionInfo?.device_name;
      /**
       * Anytime the participants are updated we check for proper pin target.
       * Avoid pinning the same target is handled in pinParticipant func.
       * @param participants all participants
       */
      const participantsListener = (participants: Participant[]) => {
        let participant = participants.find((current: Participant) => current.isPinned);
        participant = participant || participants.find((current: Participant) => current.type === 'CARE_DEVICE');
        participant = participant || participants.find((current: Participant) => current.displayName === deviceName);
        pinParticipant(participant);
      };
      adapter.on(EventNames.onParticipantsChange, participantsListener);
      // waits until the zoom DOM structure is built
      const multiViewSeeker = setInterval(() => {
        const elements = document.getElementsByClassName('multi-view');
        if (elements && elements.length > 0) {
          const ele = elements[0];
          // anytime the user 'blurs' the web page zoom destroys the DOM content
          // reattach all listeners when focused back
          multiViewObserver.current = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                if (pinnedParticipant.current) {
                  pinParticipant(pinnedParticipant.current);
                }
              }
            });
          });
          const observerConfig = { childList: true };
          multiViewObserver.current.observe(ele, observerConfig);
          clearInterval(multiViewSeeker);
        }
      }, 500);
      return () => {
        adapter.off(EventNames.onParticipantsChange, participantsListener);
        if (multiViewObserver.current) {
          multiViewObserver.current.disconnect();
        }
      };
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapter]);

  return enableDblClickEvent;
};
