import showNotificationDialog, { MessageType } from '../components/common/notificationDialog';

/**
 * Renders camera error and displays appropriate message
 *
 * @param {string} title
 * @param {string} error
 */
export function renderCameraError(title: string, error: Error): void {
  let msg = '';
  if (error.name === 'ConstraintNotSatisfiedError') {
    msg = 'The resolution is not supported by your device.';
  } else if (error.name === 'PermissionDeniedError') {
    msg = 'Permissions have not been granted to use your camera and '
          + 'microphone, you need to allow the page access to your devices in '
          + 'order for the video call to work.';
  } else if (error.name == 'OverconstrainedError') {
    const oe = error as OverconstrainedError;
    msg = `${title}: ${oe.name ?? ''}:  ${oe.constraint ?? ''}`;
  } else {
    msg = `${title}: ${error.name ?? ''} ${error.message ?? ''}`;
  }
  showNotificationDialog('Camera error', msg, MessageType.Error);
}
