import { RequestHeader } from '@types';

export class RestManager {
  /**
   * Returns request header
   *
   */
  static getHeader(token: string): RequestHeader {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }

  static async request(input: RequestInfo | URL, init?: RequestInit): Promise<any> {
    let jsonRes;
    try {
      jsonRes = await fetch(input, init);
      return await jsonRes.json();
    } catch (e) {
      if ((jsonRes?.status || 500) < 400) {
        return Promise.resolve({ success: true });
      }

      console.error('[RestManager] request Error: ', e, input, init);
      return Promise.reject(e);
    }
  }
}
