import React, { FC } from 'react';
import {
  Col, Collapse, Dropdown, Row,
} from 'antd';
import * as styles from '@assets/styles/antd-custom-styles';
import {
  ParticipantContextMenu,
  ParticipantContextMenuProps,
} from './ParticipantContextMenu';
import {
  ParticipantButtons,
  ParticipantButtonsProps,
} from './ParticipantButtons';

export interface ProfileProps extends ParticipantContextMenuProps, ParticipantButtonsProps {
  displayName?: string;
  pictureUrl?: string;
  initials?: string;
  email?: string;
  phoneNumber?: string;
  organization?: string;
  facility?: string;
}

/**
 * Profile Component
 *
 * @param props - props
 * @constructor
 */
export const Profile: FC<ProfileProps> = (props) => {
  const {
    displayName,
    pictureUrl,
    initials,
    email,
    phoneNumber,
    organization,
    facility,
  } = props;
  return (
    <Dropdown
      overlay={<ParticipantContextMenu {...props} />}
      trigger={['contextMenu']}
    >
      <div className="vl-profiles-list-item">
        <div className="vl-avatar vl-ml15">
          {pictureUrl != null && (
          <img
            alt=""
            className="vl-circle-inner1"
            src={`data:image/png;base64,${pictureUrl}`}
          />
          )}
          {pictureUrl == null && initials && (
          <div className="vl-circle-inner1">{initials}</div>
          )}
        </div>

        <Collapse bordered={false} expandIconPosition="right">
          <Collapse.Panel
            className="vl-profile-collapse-panel"
            header={(
              <Row>
                <Col span={17} className="vl-part-text">
                  <span style={styles.f18}>
                    {displayName}
                  </span>
                </Col>
                <ParticipantButtons
                  {...props}
                />
              </Row>
     )}
            key="1"
          >
            <div className="vl-collapse vl-f-14">
              {phoneNumber && (
              <Row>
                <Col className="vl-property">Phone:</Col>
                <Col className="vl-value">{phoneNumber}</Col>
              </Row>
              )}
              {email && (
              <Row>
                <Col className="vl-property">Email:</Col>
                <Col className="vl-value">{email}</Col>
              </Row>
              )}
              {organization && (
              <Row>
                <Col className="vl-property">
                  Organization:
                </Col>
                <Col className="vl-value">{organization}</Col>
              </Row>
              )}
              {facility && (
              <Row>
                <Col className="vl-property">Facility:</Col>
                <Col className="vl-value">{facility}</Col>
              </Row>
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </Dropdown>
  );
};

Profile.defaultProps = {
  displayName: '',
  pictureUrl: '',
  initials: '',
  email: '',
  phoneNumber: '',
  organization: '',
  facility: '',
};
