import React, { FC, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Button, Form, Input, Radio, RadioChangeEvent,
} from 'antd';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CaregilityServiceAdapter } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { DeviceType, GuestType, InvitationType } from '@types';
import { inviteBtn, inviteGreenBtn } from '@assets/styles/antd-custom-styles';
import showNotificationDialog, { MessageType } from '@components/common/notificationDialog';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import '@assets/styles/custom-antd.scss';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

/**
 * Guest - component for inviting a guest
 */
export const Guest: FC = () => {
  const inCall = false;
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const [type, setType] = useState(GuestType.PSTN);
  const [value, setValue] = useState('');
  const [status, setStatus] = useState('' as ValidateStatus);
  const [validateMessage, setValidateMessage] = useState('');
  const [isInviteAvailable, setIsInviteAvailable] = useState(false);

  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regexSIP = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/;
  const regexH323 = /[A-Z0-9a-z._%+-]+##[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/;

  /**
   * Gets and sets the guest invite type
   * @param e - event
   */
  const onRadioSelect = (e: RadioChangeEvent) => {
    let newValue;
    switch (e.target.value) {
      case 'Call':
        newValue = GuestType.PSTN;
        break;
      case 'SMS/Text':
        newValue = GuestType.SMS;
        break;
      case 'Email':
        newValue = GuestType.EMAIL;
        break;
      case 'SIP':
        newValue = GuestType.SIP;
        break;
      case 'H.323':
        newValue = GuestType.H323;
        break;
      default:
        newValue = GuestType.SMS;
        break;
    }
    setType(newValue);
    setValue('');
  };

  /**
   * Gets the correct placeholder for the selected input field
   */
  const getPlaceholder = () => {
    switch (type) {
      case GuestType.SMS:
      case GuestType.PSTN:
        return 'Enter Phone Number';
      case GuestType.EMAIL:
        return 'Enter Email';
      case GuestType.SIP:
        return 'name@domain';
      case GuestType.H323:
        return 'domain##name';
      default:
        return '';
    }
  };

  /**
   * Gets the new input value and updates the state
   * @param e event
   */
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const prevValue = value?.trim() ?? '';
    const nextValue = e.target.value?.trim() ?? '';

    if (prevValue === nextValue) return;

    setValue(nextValue);
    setStatus('');
    setIsInviteAvailable(true);
  };

  /**
   * validates and parses the entered phone number into PhoneNumber object
   * @param phoneValue phoneValue
   * @returns isValid and inputValue
   */
  const validateAndParsePhone = (phoneValue: string) => {
    let isValid = false;
    let inputValue = phoneValue;

    const regionCode = 'US';

    try {
      const phoneNumberValue = phoneNumberUtil.parse(inputValue, regionCode);

      if (inputValue === '0000000000' || inputValue.length === 7) {
        return { isValid: false, inputValue };
      }

      if (phoneNumberUtil.isPossibleNumber(phoneNumberValue)) {
        isValid = true;
        inputValue = String(phoneNumberValue.getNationalNumber());
        return { isValid, inputValue };
      }
    } catch (e) {
      return { isValid: false, inputValue };
    }

    return { isValid, inputValue };
  };

  const validateEmail = (emailValue: string) => emailValue.length > 0 && regexEmail.test(emailValue);
  const validateSIP = (sipValue: string) => sipValue.length > 0 && regexSIP.test(sipValue);
  const validateH323 = (h323Value: string) => h323Value.length > 0 && regexH323.test(h323Value);

  /**
   * validates the entered input value
   * @returns isValid and inputValue
   */
  const validateInput = () => {
    switch (type) {
      case GuestType.PSTN: {
        return validateAndParsePhone(value);
      }
      case GuestType.SMS:
        return validateAndParsePhone(value);
      case GuestType.EMAIL:
        return {
          isValid: validateEmail(value),
          inputValue: value,
        };
      case GuestType.SIP:
        return {
          isValid: validateSIP(value),
          inputValue: value,
        };
      case GuestType.H323:
        return {
          isValid: validateH323(value),
          inputValue: value,
        };
      default:
        return { isValid: false, inputValue: '' };
    }
  };

  /**
   * returns validation message which will be displayed
   */
  const getValidationMessage = () => {
    if (validateMessage) {
      return <div className="vl-help">{validateMessage}</div>;
    }
    return null;
  };

  const onFocus = () => {
    setStatus('validating');
    setValidateMessage('');
  };

  /**
   * returns the needed invite params
   */
  const getInviteParams = () => {
    switch (type) {
      case GuestType.PSTN:
        return {
          inviteType: InvitationType.byPhoneNumber,
          protocol: 'PSTN',
          deviceId: `tel:${value}`,
          deviceType: DeviceType.Guest,
        };
      case GuestType.SIP:
        return {
          inviteType: InvitationType.byStandardsBased,
          protocol: 'SIP',
        };
      case GuestType.H323:
        return {
          inviteType: InvitationType.byStandardsBased,
          protocol: 'H.323',
        };
      case GuestType.EMAIL:
        return {
          inviteType: InvitationType.byEmail,
        };
      case GuestType.SMS:
        return {
          inviteType: InvitationType.bySms,
        };
      default:
        return {
          inviteType: InvitationType.byPhoneNumber,
          protocol: 'PSTN',
        };
    }
  };

  /**
   * passes the request data to the adapter and shows the notification message
   */
  const doAnInvite = () => {
    setIsInviteAvailable(false);

    if (!value || value === '') {
      return;
    }

    const {
      inviteType, protocol, deviceId, deviceType,
    } = getInviteParams();

    adapter?.getSessionControlManager()?.onInviteGuest({
      type: inviteType,
      value,
      protocol,
      device_id: deviceId,
      device_type: deviceType,
    });

    if (type === GuestType.EMAIL) {
      showNotificationDialog('The guest invitation email is sent.', null, MessageType.Success, 3);
    } else if (type === GuestType.SMS) {
      showNotificationDialog('The guest invitation sms is sent.', null, MessageType.Success, 3);
    }
  };

  /**
   * Determines whether to do a call or an invitation
   */
  const callOrInvite = () => {
    if (!isInviteAvailable) {
      return;
    }

    doAnInvite();
  };

  /**
   * triggered after submitting the guest invite form
   */
  // eslint-disable-next-line consistent-return
  const onFinish = () => {
    try {
      const { isValid } = validateInput();
      if (isValid) {
        return callOrInvite();
      }
      setStatus('error');
      setValidateMessage('Invalid input');
    } catch (e: any) {
      console.log(`ERROR: ${e.message}`);
      setIsInviteAvailable(true);
      setStatus('error');
      setValidateMessage('Invalid input');
      showNotificationDialog(e.message, null, MessageType.Error);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item>
        <div className="vl-radio-group">
          <Radio.Group defaultValue="Call" onChange={onRadioSelect}>
            <Radio value="Call">Call</Radio>
            <Radio value="SMS/Text">SMS/Text</Radio>
            <Radio value="Email">Email</Radio>
            <Radio value="SIP">SIP</Radio>
            <Radio value="H.323">H.323</Radio>
          </Radio.Group>
        </div>
      </Form.Item>
      <Form.Item
        className="vl-input-guest"
        help={getValidationMessage()}
        validateStatus={status}
      >
        <div>
          <Input
            size="large"
            className="vl-select-field"
            value={value}
            placeholder={getPlaceholder()}
            onChange={onInputChange}
            onFocus={onFocus}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </div>
      </Form.Item>
      <>
        {type !== GuestType.PSTN && (
          <Form.Item>
            <Button
              disabled={!isInviteAvailable}
              className="vl-btn vl-btn-green"
              style={inviteGreenBtn}
              htmlType="submit"
              size="large"
              type="primary"
              block
            >
              Invite
            </Button>
          </Form.Item>
        )}

        {type === GuestType.PSTN && (
          <>
            {!inCall && (
              <Form.Item>
                <Button
                  disabled={!isInviteAvailable}
                  style={inviteGreenBtn}
                  className="vl-btn vl-btn-green"
                  htmlType="submit"
                  size="large"
                  type="primary"
                  block
                >
                  Call
                </Button>
              </Form.Item>
            )}
            {inCall && (
              <Form.Item>
                <Button
                  htmlType="reset"
                  size="large"
                  className="vl-btn vl-btn-red"
                  style={inviteBtn}
                  block
                >
                  End Call
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </>
    </Form>
  );
};
