import { useState, useEffect } from 'react';
/**
 * A hook to count passed time as soon as it is created.
 * @returns the time passed in hh:mm:ss string format
 */
export const useTimer = () => {
  const [initialTime] = useState<number>(Date.now());
  const [passedTime, setPassedTime] = useState<string>('');

  /**
   * Converts ms to string in hh:mm:ss format
   * @param ms - time in milliseconds
   * @returns - time in hh:mm:ss string format
   */
  const millisToTime = (ms: number) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 3600) % 24);

    return `${(hours < 10) ? `0${hours}` : hours}:${(minutes < 10) ? `0${minutes}` : minutes}:${(seconds < 10) ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPassedTime(millisToTime(Date.now() - initialTime));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return passedTime;
};
