import React, { FC, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CaregilityServiceAdapter, EventNames } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { ParticipantsOn, ChatOn } from '@atoms/VideoButtons';
import { SelectedRoomAtom } from '@atoms/SelectedRoom';
import { Participant, Session } from '@types';
import { ParticipantsPanelView } from './ParticipantsPanelView';

export const ParticipantsPanel: FC = () => {
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const [participantsOn, setParticipantsOn] = useRecoilState(ParticipantsOn);
  const setChatOn = useSetRecoilState(ChatOn);
  const setSelectedRoom = useSetRecoilState(SelectedRoomAtom);
  const [me, setMe] = useState<Participant | undefined>();
  const [session, setSession] = useState<Session | undefined>();
  const [allParts, setAllParts] = useState<Participant[]>([]);

  useEffect(() => {
    if (adapter) {
      setMe(adapter.getSelfParticipant());
      setSession(adapter.getSession());
      setAllParts(adapter.getAllParticipants());
      const participantsListener = (participants: Participant[]) => {
        setAllParts(participants);
        setMe(adapter.getSelfParticipant());
      };
      const sessionListener = (newSession: Session) => {
        setSession(newSession);
      };

      adapter.on(EventNames.onParticipantsChange, participantsListener);
      adapter.on(EventNames.onSessionChange, sessionListener);

      return () => {
        adapter.off(EventNames.onParticipantsChange, participantsListener);
        adapter.off(EventNames.onSessionChange, sessionListener);
      };
    }

    return () => {};
  }, [adapter]);

  if (me && session) {
    return (
      <ParticipantsPanelView
        visible={participantsOn}
        onClose={() => setParticipantsOn(false)}
        isSessionLocked={!!session.isLocked}
        onAddFavorite={(part: Participant) => {
          adapter?.getFavoritesManager()?.addFavorite({
            type: 'PROFILE',
            name: `${part.displayName}`,
            metadata: `${part.id}`,
          });
        }}
        onAdmit={(part) => {
          adapter?.getSessionControlManager()?.admitParticipant(part);
        }}
        onChat={(part: Participant) => {
          if (part.id) {
            setChatOn(true);
            setSelectedRoom(part?.id?.toString());
          }
        }}
        onDeny={(part) => {
          adapter?.getSessionControlManager()?.denyParticipant(part);
        }}
        onMute={(part: Participant) => {
          adapter?.getSessionControlManager()?.muteParticipant(part);
        }}
        onRemove={(part) => {
          adapter?.getSessionControlManager()?.removeParticipant(part);
        }}
        onRemoveFavorite={(part: Participant) => {
          if (part.favorite?.id) {
            adapter?.getFavoritesManager()?.removeFavorite(part.favorite.id.toString());
          }
        }}
        onSpotlight={(part: Participant) => {
          adapter?.getSessionControlManager()?.pinParticipant(part);
        }}
        hasChatToken={(part: Participant) => {
          if (adapter?.getSessionControlManager().hasChatToken) {
            return adapter?.getSessionControlManager().hasChatToken(part);
          }

          return false;
        }}
        participants={allParts}
        self={me}
      />
    );
  }

  return null;
};
