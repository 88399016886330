import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { CaregilityServiceAdapter } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { calculatePoint, getZoomClientViewButtonLabel } from '@utils/helpers';
import { useDoorbell, useZoomPin } from '@hooks';
import { useZoomButton, usePOH } from '@hooks/useZoomButton';
import { AudioOn, VideoOn } from '@atoms/VideoButtons';
import { Footer } from '@components/Footer/Footer';
import ScreenShotComponent from '@components/ScreenShot/ScreenShotComponent';
import { ButtonTypes } from '@types';
import { useSetRecoilState } from 'recoil';
import '@assets/styles/zoom.scss';
import { CameraControlManager } from '@managers';
import { LeaveSessionButton } from '@components/Header/LeaveSessionButton';
import { Header } from '@components/Header/Header';
import { Accordeon } from '@components/common/Accordeon';

const bootstrapCss = document.createElement('link');
const reactSelectCss = document.createElement('link');

bootstrapCss.type = 'text/css';
bootstrapCss.rel = 'stylesheet';
bootstrapCss.href = 'https://source.zoom.us/2.9.5/css/bootstrap.css';

reactSelectCss.type = 'text/css';
reactSelectCss.rel = 'stylesheet';
reactSelectCss.href = 'https://source.zoom.us/2.9.5/css/react-select.css';

document.getElementsByTagName('head')[0].appendChild(bootstrapCss);
document.getElementsByTagName('head')[0].appendChild(reactSelectCss);
export interface ZoomMeetingClientViewVideoSceneProps {
  adapter: CaregilityServiceAdapter;
  // configFooterButtons prop should be empty array if no footbar is needed.
  // If undefined a footer with default buttons set will be rendered.
  configFooterButtons?: ButtonTypes[];
  showHeader?: boolean;
  cameraId: string;
  joinCallToken?: string;
}

const ZoomMeetingClientViewVideoScene: FC<ZoomMeetingClientViewVideoSceneProps> = (props) => {
  const {
    adapter, configFooterButtons, cameraId, showHeader, joinCallToken,
  } = props;
  const buttonCheckInterval = useRef<NodeJS.Timer | null>(null);
  const micButton = useZoomButton(AudioOn, 'mute');
  const cameraButton = useZoomButton(VideoOn, 'stop video');
  const [zoomFooter, setZoomFooter] = useState<HTMLElement>();
  const setAdapter = useSetRecoilState(AdapterAtom);
  const pohButton = usePOH(micButton, cameraButton, adapter);
  const enableDblClickEvent = useZoomPin(adapter);

  useDoorbell(adapter);

  /**
   * Clicks the "Join Audio" right after the popup appears and waits until it disappears
   * @param cb - a callback to be executed when successfully connects the audio
   */
  const handleAudioJoin = (cb: () => void) => {
    let intervalCoutner = 10;
    let interval = setInterval(() => {
      if (intervalCoutner-- > 0) {
        let joinButtonParent = document.getElementById('voip-tab');
        if (joinButtonParent) {
          let joinButtons = joinButtonParent.getElementsByTagName('button');
          if (joinButtons && joinButtons.length === 1) {
            clearInterval(interval);
            intervalCoutner = 10;
            interval = setInterval(() => {
              if (intervalCoutner-- > 0) {
                joinButtonParent = document.getElementById('voip-tab');
                if (joinButtonParent) {
                  joinButtons = joinButtonParent.getElementsByTagName('button');
                  if (joinButtons && joinButtons.length === 1) {
                    joinButtons[0].click();
                  }
                } else {
                  clearInterval(interval);
                  cb();
                }
              } else {
                clearInterval(interval);
                throw new Error('Couldn\'t join audio');
              }
            }, 1000);
          }
        }
      } else {
        clearInterval(interval);
        cb();
      }
    }, 1000);
  };

  /**
   * On screen share click searches for zoom's screenshare button and clicks it
   */
  const clickScreenshare = () => {
    const footer = document.getElementById('foot-bar');
    if (footer) {
      const buttons = footer.getElementsByTagName('button');
      if (buttons && buttons.length > 10) {
        for (let i = 0; i < buttons.length; i += 1) {
          const button = buttons[i];
          const label = getZoomClientViewButtonLabel(button);
          if (label === 'share screen') {
            button.click();
            break;
          }
        }
      }
    }
  };

  /**
   * Waits for zoom buttons to appear and wraps them in custom hooks to keep refs to them.
   * Triggers join audio if necessary.
   */
  const handleButtons = () => {
    const footerParent = document.getElementById('wc-footer');
    const footer = document.getElementById('foot-bar');
    if (footer && footerParent) {
      footer.style.setProperty('visibility', 'hidden', 'important');
      footer.style.setProperty('position', 'absolute', 'important');
      if (configFooterButtons && configFooterButtons.length > 0) {
        setZoomFooter(footerParent);
      }
      const buttons = footer.getElementsByTagName('button');
      if (buttons && buttons.length > 0) {
        const result: {
          micButton?: {
            label: string,
            button: HTMLButtonElement,
          },
          cameraButton?: {
            label: string,
            button: HTMLButtonElement,
          },
        } = {};
        for (let i = 0; i < buttons.length; i += 1) {
          const button = buttons[i];
          const label = getZoomClientViewButtonLabel(button);
          if (['mute', 'unmute', 'join audio'].includes(label)) {
            result.micButton = { label, button };
          } else if (['start video', 'stop video'].includes(label)) {
            result.cameraButton = { label, button };
            break;
          }
        }
        if (result.micButton && result.cameraButton) {
          if (buttonCheckInterval.current) {
            clearInterval(buttonCheckInterval.current);
            buttonCheckInterval.current = null;
          }
          const cb = () => {
            micButton.setButton(result.micButton!.button);
            cameraButton.setButton(result.cameraButton!.button);
          };
          if (result.micButton.label === 'join audio') {
            handleAudioJoin(cb);
          } else {
            cb();
          }
        }
      }
    }
  };

  /**
   * Determines if its active video frame event.
   * @param target the target from the mouse event
   * @returns boolean
   */
  const isCameraControlTarget = (target: HTMLElement) => {
    if (target.classList?.contains('video-avatar__avatar')) {
      const parent = target.parentElement;
      return parent && parent.classList?.contains('speaker-active-container__video-frame');
    }
    return false;
  };

  /**
   * Adds mouse events to zoom root that trigger camera events
   * @param root zoom root
   * @param manager CameraControlManager
   * @returns cleaner function to clear all events listeneners
   */
  const addCameraControlClickHandlers = (root: HTMLElement, manager: CameraControlManager) => {
    /**
     * Captures all double click events and prevent them. If on right target triggers cameraGoToPoint event.
     * TODO: if we want to enable multiple active frames we have to change the stopPropagation cases.
     * @param event double click event
     */
    const dblClickHandler = (event: MouseEvent) => {
      if (!enableDblClickEvent.current) {
        event.stopPropagation();
      }
      const target = event.target as HTMLElement;
      if (isCameraControlTarget(target)) {
        const point = calculatePoint(event.target as HTMLElement, event.pageX, event.pageY, event.clientX, event.clientY);
        if (point !== null) {
          manager.cameraGoToPoint(point.x, point.y, 'false');
        }
      }
    };

    /**
     * Listener for all mouse down effects. If on right targetSchedules 1 sec timer to triggers
     * either goToPoint or zoomOut events depending on which button is hold.
     * @param event mousedown event
     */
    const mouseDownHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isCameraControlTarget(target)) {
        let timeout: NodeJS.Timeout;
        const mouseLeaveHandler = () => {
          if (timeout) {
            clearTimeout(timeout);
          }
          target.removeEventListener('mouseleave', mouseLeaveHandler);
          target.removeEventListener('mouseup', mouseLeaveHandler);
        };
        target.addEventListener('mouseleave', mouseLeaveHandler);
        target.addEventListener('mouseup', mouseLeaveHandler);
        timeout = setTimeout(() => {
          const { button } = event;
          if (button < 1) {
            const point = calculatePoint(event.target as HTMLElement, event.pageX, event.pageY, event.clientX, event.clientY);
            if (point !== null) {
              manager.cameraGoToPoint(point.x, point.y, 'true');
            }
          } else {
            manager.cameraZoomOut();
          }
          target.removeEventListener('mouseleave', mouseLeaveHandler);
          target.removeEventListener('mouseup', mouseLeaveHandler);
        }, 1000);
      }
    };

    /**
     * Disables context menu.
     * @param event context menu event
     */
    const contextMenuHandler = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };
    root.addEventListener('dblclick', dblClickHandler, true);
    root.addEventListener('mousedown', mouseDownHandler, true);
    root.addEventListener('contextmenu', contextMenuHandler, true);
    return () => {
      root.removeEventListener('dblclick', dblClickHandler, true);
      root.removeEventListener('mousedown', mouseDownHandler, true);
      root.removeEventListener('contextmenu', contextMenuHandler, true);
    };
  };

  useEffect(() => {
    if (adapter) {
      const zoomRoot = document.getElementById('zmmtg-root');
      let clickHandlersCleaner: null | (() => void) = null;
      if (zoomRoot) {
        zoomRoot.style.display = 'block';
        if (adapter.getCameraControlManager()?.isCameraMouseControllersAllowed()) {
          clickHandlersCleaner = addCameraControlClickHandlers(zoomRoot, adapter.getCameraControlManager());
        }
      }
      adapter.onJoinSuccess(() => {
        buttonCheckInterval.current = setInterval(handleButtons, 1000);
      });
      Promise.resolve(adapter.init()).then(() => {
        setAdapter(adapter);
      }).catch((error) => {
        console.error('[ZoomMeetingClientViewVideoScene] Error: Zoom failed to init!', error);
      });
      return () => {
        if (buttonCheckInterval.current) {
          clearInterval(buttonCheckInterval.current);
          buttonCheckInterval.current = null;
        }
        if (clickHandlersCleaner) {
          clickHandlersCleaner();
        }
      };
    }
    return () => {};
  }, [adapter]);

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (adapter) {
      adapter.onUnmute(() => {
        let checksCount = 4;
        if (intervalId) {
          clearInterval(intervalId);
        }
        intervalId = setInterval(() => {
          const buttons = Array.from(
            document.querySelectorAll('.dialog-unmute-container .zm-modal-footer .zm-modal-footer-default-actions button'),
            (button) => button as HTMLButtonElement,
          );
          const unmuteButton = Array.from(buttons).find((button: HTMLButtonElement) => button.textContent === 'Unmute');
          if (unmuteButton) {
            unmuteButton.click();
            clearInterval(intervalId);
          }
          checksCount--;
          if (checksCount <= 0) {
            clearInterval(intervalId);
          }
        }, 500);
      });
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [adapter]);

  return (
    <>
      {joinCallToken && (
        <LeaveSessionButton />
      )}
      {showHeader && (
        <Accordeon>
          <Header />
        </Accordeon>
      )}
      <ScreenShotComponent />
      {zoomFooter && ReactDOM.createPortal(
        (
          <Footer
            cameraId={cameraId}
            onMicClick={micButton.click}
            onVideoClick={cameraButton.click}
            onPatientOnHoldClick={pohButton.click}
            onShareContentClick={clickScreenshare}
            style={{ padding: '0px' }}
            configFooterButtons={configFooterButtons}
          />
        ),
        zoomFooter,
      )}
    </>
  );
};

ZoomMeetingClientViewVideoScene.defaultProps = {
  configFooterButtons: undefined,
  showHeader: false,
  joinCallToken: '',
};

export default ZoomMeetingClientViewVideoScene;
