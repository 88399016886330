import React, {
  FC, useMemo, useState, useRef, useCallback, useEffect,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormInstance } from 'antd';
import { CaregilityServiceAdapter } from '@adapters';
import { AdapterAtom } from '@atoms/Adapter';
import { ChatRoomsAtom } from '@atoms/Chat';
import { ChatOn } from '@atoms/VideoButtons';
import { SelectedRoomAtom } from '@atoms/SelectedRoom';
import { MovablePanel } from '@components/MovablePanel/MovablePanel';
import ChatView from './ChatView';

/**
 * Controller component that contains the logic part of the ChatView
 */
const ChatComponent: FC = () => {
  const adapter = useRecoilValue<CaregilityServiceAdapter | null>(AdapterAtom);
  const [message, setMessage] = useState<string>('');
  const [chatOn, setChatOn] = useRecoilState(ChatOn);
  const [rooms, setRooms] = useRecoilState(ChatRoomsAtom);
  const [selectedRoom, setSelectedRoom] = useRecoilState(SelectedRoomAtom);

  const selfParticipant = adapter?.getSelfParticipant();
  const currentUserId = selfParticipant?.id ? selfParticipant.id.toString() : '';
  const sendMessage = adapter?.getSessionControlManager()?.sendChatMessage || (() => {});

  const formRef = useRef<FormInstance>();
  const setFormRef = useCallback((form: FormInstance) => {
    formRef.current = form;
  }, []);

  const roomsLabels = useMemo(() => {
    if (!rooms) {
      return [];
    }

    return Object.keys(rooms).map((roomId) => {
      const room = rooms[roomId];

      return {
        label: room.displayName,
        value: `${room.id}`,
      };
    });
  }, [rooms]);

  useEffect(() => {
    if (rooms) {
      const roomsIds = Object.keys(rooms);

      if (roomsIds && roomsIds.length > 0) {
        if (selectedRoom.length === 0) {
          setSelectedRoom(roomsIds[0]);
        } else if (chatOn) {
          let changeGlobal = false;
          const msgs = rooms[selectedRoom].messages;

          for (let i = 0; i < msgs.length; i++) {
            if (!msgs[i].isSeen) {
              msgs[i].isSeen = true;
              changeGlobal = true;
            }
          }

          if (changeGlobal) {
            setRooms({ ...rooms });
          }
        }
      }
    }
  }, [rooms, selectedRoom, chatOn]);

  // wrapper method to reset the input values.
  const mySendMessage = () => {
    sendMessage(currentUserId, message, selectedRoom);
    setMessage('');
    formRef.current?.setFieldsValue({
      msg: '',
    });
  };

  return rooms && rooms[selectedRoom] ? (
    <MovablePanel
      hidden={!chatOn}
      title="Chat"
      width={500}
      height={650}
      top={120}
      left={1001}
      hasDivider
      onClose={() => setChatOn?.(false)}
    >
      <ChatView
        rooms={roomsLabels}
        isMobile={false}
        allMessages={rooms[selectedRoom].messages}
        currentUserId={currentUserId}
        message={message}
        onMessageChange={(msg) => setMessage(msg)}
        selectedRoom={`${selectedRoom}`}
        setSelectedRoom={setSelectedRoom}
        sendMessage={mySendMessage}
        setFormRef={setFormRef}
      />
    </MovablePanel>
  ) : null;
};

export default ChatComponent;
