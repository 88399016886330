import { ChangeEvent, useState, useCallback } from 'react';
import { CaregilityEndpoints, CaregilitySession } from '@types';

export const isLocalhost = (): boolean => window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1');

export const useConfig = (defaultEnvironment: string) => {
  const [accessToken, setToken] = useState<string>('');
  const [deviceID, setDeviceID] = useState<string>('');
  const [environment, setEnvironment] = useState<string>(defaultEnvironment);
  const [sessionId, setSessionId] = useState<number>(0);
  const [caregilitySession, setCaregilitySession] = useState<CaregilitySession | null>(null);

  const handleTokenChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setToken(event.target.value);
  }, []);

  const handleEnvironmentChange = useCallback((event: ChangeEvent<HTMLSelectElement>): void => {
    setEnvironment(event.target.value);
  }, []);

  const handledeviceIDChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setDeviceID(event.target.value);
  }, []);

  const getPexipServerConfig = useCallback(() => {
    let node = '';

    switch (environment) {
      case 'env2':
        node = 'proxycentral.caregility.net';
        break;
      case 'env3':
        node = 'nacentraledge02.caregility.net';
        break;
      case 'env7':
        node = 'nacentraledge06.caregility.net';
        break;
      default:
        break;
    }

    return {
      nodeAddress: `https://${node}`,
      node,
      bandwidth: 1720,
    };
  }, [environment]);

  const getEndpointsConfig = useCallback((): CaregilityEndpoints => {
    let envPrefix = '';
    let cameraControlUrl = '';
    let camerasUrl = '';
    let careDevicesUrl = '';
    let brokerUrl = '';
    let mobileBackendUrl = '';
    let adminBackendUrl = '';
    let sessionControlUrl = `/${environment}`;
    const isLocal = isLocalhost();

    switch (environment) {
      case 'env2':
        envPrefix = 'env2_';
        sessionControlUrl = isLocal ? sessionControlUrl : 'https://env2-sessioncontrol.caregility.net';
        cameraControlUrl = isLocal ? '/admin2/iconsult/api/v1/rooms/control' : 'https://env2-adminbe.caregility.net/iconsult/api/v1/rooms/control';
        camerasUrl = isLocal ? '/admin2/iconsult/api/v2/cameras' : 'https://env2-adminbe.caregility.net/iconsult/api/v2/cameras';
        careDevicesUrl = isLocal ? '/admin2/iconsult/api/v2/cameras' : 'https://env2-adminbe.caregility.net/iconsult/api/v2/cameras';
        brokerUrl = 'wss://env2-sessioncontrol.caregility.net/stomp';
        mobileBackendUrl = 'https://env2-mobile.caregility.net';
        adminBackendUrl = isLocal ? '/admin2' : 'https://env2-adminbe.caregility.net';
        break;
      case 'env3':
        envPrefix = 'env3_';
        sessionControlUrl = isLocal ? sessionControlUrl : 'https://env3-sessioncontrol.caregility.net';
        cameraControlUrl = isLocal ? '/admin3/iconsult/api/v1/rooms/control' : 'https://env3-adminbe.caregility.net/iconsult/api/v1/rooms/control';
        camerasUrl = isLocal ? '/admin3/iconsult/api/v2/cameras' : 'https://env3-adminbe.caregility.net/iconsult/api/v2/cameras';
        careDevicesUrl = isLocal ? '/admin3/iconsult/api/v2/cameras' : 'https://env3-adminbe.caregility.net/iconsult/api/v2/cameras';
        brokerUrl = 'wss://env3-sessioncontrol.caregility.net/stomp';
        mobileBackendUrl = 'https://env3-mobile.caregility.net';
        adminBackendUrl = isLocal ? '/admin3' : 'https://env3-adminbe.caregility.net';
        break;
      case 'env7':
        envPrefix = 'env7_';
        sessionControlUrl = isLocal ? sessionControlUrl : 'https://env7-sessioncontrol.caregility.net';
        cameraControlUrl = isLocal ? '/admin7/iconsult/api/v1/rooms/control' : 'https://env7-adminbe.caregility.net/iconsult/api/v1/rooms/control';
        camerasUrl = isLocal ? '/admin7/iconsult/api/v2/cameras' : 'https://env7-adminbe.caregility.net/iconsult/api/v2/cameras';
        careDevicesUrl = isLocal ? '/admin7/iconsult/api/v2/cameras' : 'https://env7-adminbe.caregility.net/iconsult/api/v2/cameras';
        brokerUrl = 'wss://env7-sessioncontrol.caregility.net/stomp';
        mobileBackendUrl = 'https://env7-mobile.caregility.net';
        adminBackendUrl = isLocal ? '/admin7' : 'https://env7-adminbe.caregility.net';
        break;
      default:
        break;
    }

    return {
      cameraControlUrl,
      camerasUrl,
      careDevicesUrl,
      stompBrokerUrl: brokerUrl,
      sessionControlUrl,
      envPrefix,
      mobileBackendUrl,
      adminBackendUrl,
    };
  }, [environment]);

  return {
    caregilitySession,
    setCaregilitySession,
    getPexipServerConfig,
    getEndpointsConfig,
    accessToken,
    setToken,
    sessionId,
    setSessionId,
    deviceID,
    setDeviceID,
    environment,
    setEnvironment,
    handleTokenChange,
    handledeviceIDChange,
    handleEnvironmentChange,
  };
};
